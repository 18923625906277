// ** React Import
import { useEffect } from 'react'

// ** Third Party Components
import { Controller, useForm } from 'react-hook-form'
import Select from 'react-select'

// ** Reactstrap Imports
import { Button, Form, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'

// ** Store & Actions
import '@styles/react/libs/flatpickr/flatpickr.scss'
import { useDispatch, useSelector } from 'react-redux'
import { addCompany, toggleSidebar } from '../store'


const SidebarNewUsers = () => {
  // ** States
  const user = useSelector(state => state.auth.userData)
  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state.company)
  // ** Vars
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm()
  // ** Function to handle form submit
  const onSubmit = data => {
    dispatch(
      addCompany({
        branchOffice: data.branchOffice,
        companyGroupId: data.companyGroupId ? data.companyGroupId.value : null,
        newCompanyGroupName: data.newCompanyGroupName,
        companyPassword: data.companyPassword,
        name: data.name,
        registirationNumber: data.registirationNumber,
        systemPassword: data.systemPassword,
        suffix: data.suffix,
        userName: data.userName
      })
    )
  }

  useEffect(() => {
    reset()
  }, [store.toggleSideBar])

  const popupClose = () => {
    dispatch(toggleSidebar(false))
    reset()
  }

  return (
    <>
      <Modal isOpen={store.toggleSideBar} toggle={popupClose} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={popupClose}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>Firma</h1>
            <p>Yeni Firma ekleme ekranı</p>
          </div>

          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-1'>
              <Label className='form-label' for='name'>
                Kod Ad / Şube <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='name'
                control={control}
                render={({ field }) => (
                  <Input id='name' placeholder='' invalid={errors.name && true} {...field} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='companyGroupId'>
                Grup <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='companyGroupId'
                control={control}
                render={({ field }) => (
                  <Select
                    isClearable={false}
                    options={store.companyGroupSelectData}
                    className='react-select'
                    classNamePrefix='select'
                    invalid={errors.companyGroupId && true}
                    {...field}
                  />
                )}
              />
            </div>
         {user.role === "ConsultantAdmin" &&
            <div className='mb-1'>

            <Controller
              name='newCompanyGroupName'
              control={control}
              render={({ field }) => (
                <Input id='newCompanyGroupName' placeholder=' Yeni Grup Oluştur' invalid={errors.newCompanyGroupName && true} {...field} />
              )}
            />
          </div>}
            <div className='mb-1'>
              <Label className='form-label' for='userName'>
                SGK Kullanıcı Adı
              </Label>
              <Controller
                name='userName'
                control={control}
                render={({ field }) => (
                  <Input id='userName' placeholder='' invalid={errors.userName && true} {...field} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='suffix'>
                İşyeri Kodu
              </Label>
              <Controller
                name='suffix'
                control={control}
                render={({ field }) => (
                  <Input id='suffix' placeholder='' invalid={errors.suffix && true} {...field} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='systemPassword'>
                Sistem Şifresi
              </Label>
              <Controller
                name='systemPassword'
                control={control}
                render={({ field }) => (
                  <Input id='systemPassword' placeholder='' invalid={errors.systemPassword && true} {...field} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='companyPassword'>
                İşyeri Şifresi
              </Label>
              <Controller
                name='companyPassword'
                control={control}
                render={({ field }) => (
                  <Input id='companyPassword' placeholder='' invalid={errors.companyPassword && true} {...field} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='registirationNumber'>
                İşyeri Sicil No
              </Label>
              <Controller
                  name='registirationNumber'
                  control={control}
                  render={({ field }) => (
                      <Input id='registirationNumber' placeholder='' invalid={errors.registirationNumber && true} {...field} />
                  )}
              />
            </div>
            <Button type='submit' className='me-1' color='primary' disabled={store.disabledSendButton}>
              Kaydet
            </Button>
            <Button type='reset' color='secondary' outline onClick={popupClose} >
              İptal
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default SidebarNewUsers
