// ** React Imports
import React, { useState } from 'react'

// ** Reactstrap Imports
import {Button, Col, Modal, ModalBody, ModalHeader, Row, Form, Label} from 'reactstrap'

// ** Flatpickr Import
import Flatpickr from 'react-flatpickr'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import 'flatpickr/dist/plugins/monthSelect/style.css'

// ** Plugin Import
import monthSelectPlugin from 'flatpickr/dist/plugins/monthSelect'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { getAllXmlData, setAllXmlExportPopup } from '../store'

// ** Flatpickr Localization
import { Turkish } from 'flatpickr/dist/l10n/tr.js'
import Select from "react-select"

const XmlExportPopup = () => {
    // ** Store Vars
    const dispatch = useDispatch()
    const store = useSelector(state => state.iskur)
    const authStore = useSelector(state => state.auth)

    // ** Local State for Form Inputs
    const [selectedDate, setSelectedDate] = useState(new Date())
    const [selectedStores, setSelectedStores] = useState([])

    // ** Close Modal Handler
    const popupClose = () => {
        dispatch(setAllXmlExportPopup({ show: false }))
    }

    const handleSelectChange = (selectedOption) => {
        setSelectedStores(selectedOption)
    }

    // ** Form Submit Handler
    const handleSubmit = (e) => {
        e.preventDefault()
        const year = selectedDate.getFullYear()
        const month = selectedDate.getMonth() + 1
        console.log('Selected Year:', year)
        console.log('Selected Month:', month)
        dispatch(setAllXmlExportPopup({ show: false }))
        dispatch(getAllXmlData({ companyGroupId: authStore.selectedCompanyGroup, year, month, selectedStores }))
    }

    return (
        <Modal isOpen={store.xmlAllExportPopupShow} toggle={popupClose} className='modal-dialog-centered modal-lg'>
            <ModalHeader className='bg-transparent' toggle={popupClose}></ModalHeader>
            <ModalBody className='px-sm-5 mx-50 pb-5'>
                <div className='text-center mb-2'>
                    <h2 className='mb-1'>Tüm işyerleri için XML hazırla</h2>
                </div>
                <Row>
                    <Col md="12" className="justify-content-center"> {/* Center the input */}
                        <Form onSubmit={handleSubmit}>
                            <div className="mb-1">
                                <label htmlFor="yearMonthPicker" className="form-label">Periyot seçiniz</label>
                                <Flatpickr
                                    id="yearMonthPicker"
                                    className="form-control"
                                    options={{
                                        plugins: [
                                            monthSelectPlugin({
                                                shorthand: true, // Use short month names
                                                dateFormat: 'Y-m', // Display Year-Month format
                                                altFormat: 'F Y'  // Display Month Name and Year
                                            })
                                        ],
                                        defaultDate: selectedDate, // Default date is the current selected date
                                        locale: Turkish,
                                        onChange: ([selected]) => {
                                            setSelectedDate(selected)
                                        }
                                    }}
                                    style={{
                                        width: '100%', // Set a smaller width for the input
                                        textAlign: 'center' // Ensure text is centered inside the input
                                    }}
                                />
                            </div>
                            <div className='mb-1'>
                                <Label className='form-label' for='company'>
                                    Sicil <span className='text-danger'>*</span>
                                </Label>
                                <Select
                                    isClearable={false}
                                    options={store.data.map(store => ({
                                        value: store.id,
                                        label: store.name
                                    }))}
                                    className='react-select'
                                    classNamePrefix='select'
                                    isMulti
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <Button color="primary" type="submit" block>
                                İndir
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default XmlExportPopup
