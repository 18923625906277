// ** Redux Imports
import {createAsyncThunk, createSlice} from "@reduxjs/toolkit"

// ** Axios Imports
import axios from "axios"


export const getData = createAsyncThunk("companies/getData", async params => {
    const url = `/api/companies`
    const response = await axios.get(url, {params})
    return {
        params,
        data: response.data.companies,
        totalPages: response.data.pageCount
    }
})


export const setSendButtonDisabled = createAsyncThunk("api/setSendButtonDisabled", async (status) => {
    return status
})


export const getCompanyGroupData = createAsyncThunk("companies/getCompanyGroupData", async () => {
    const url = `/api/companygroup?PageSize=1000&page=1`
    const response = await axios.get(url)
    const selectData = []
    selectData.push({label: "Seçiniz", value: "0"})
    response.data.companyGroups.map((item) => {
        selectData.push({label: item.name, value: item.id})
    })

    return selectData
})

export const getResultData = createAsyncThunk("companies/getResultData", async (data) => {
    const url = `/api/DebtQuestioning/GetDebtQuestioningResults?CompanyGroupId=${data}`
    const response = await axios.get(url)
    console.log(response)
    return response.data
})

export const startBulkDebtQuestioning = createAsyncThunk("api/startBulkDebtQuestioning", async (data, {dispatch}) => {
    const url = `/api/DebtQuestioning/StartBulkDebtQuestioning`
    const response = await axios.post(url, {companyGroupId: data})
    console.log(response)
    dispatch(getResultData(data))
    return response.data
})

export const startDebtQuestioning = createAsyncThunk("api/startDebtQuestioning", async (type, {dispatch, getState}) => {
    const data = getState().auth.selectedCompanyGroup
    const popup = getState().debtQuestioning.PopupShow
    let request = {companyGroupId: data, type}
    let url = `/api/DebtQuestioning/StartBulkDebtQuestioning`
    if (!popup.isAll) {
        url = `/api/DebtQuestioning/StartDebtQuestioning`
        request = {companyId: popup.id, type}
    }

    const response = await axios.post(url, request)
    dispatch(getResultData(data))
    return response.data
})

export const setCompanyGroupId = createAsyncThunk("api/deleteCompanies", async (id) => {
    return id
})
export const modalShow = createAsyncThunk("api/modalShow", async (id) => {
    return id
})


export const debtQuestioningSlice = createSlice({
    name: "debtQuestioning",
    initialState: {
        data: [],
        total: 1,
        params: {},
        companyGroupSelectData: [],
        selectedCompany: null,
        companyGroupId: 0,
        disabledSendButton: false,
        resultData: [],
        PopupShow: {show: false, id: 0, isAll: false}
    },
    reducers: {
        TypeShowPopup: (state, action) => {
            state.PopupShow = action.payload
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(getCompanyGroupData.fulfilled, (state, action) => {
                state.companyGroupSelectData = action.payload
            })
            .addCase(setCompanyGroupId.fulfilled, (state, action) => {
                state.companyGroupId = action.payload
            })
            .addCase(modalShow.fulfilled, (state, action) => {
                state.passwordChangeCompanyId = action.payload
                state.addPasswordShow = true
            })
            .addCase(setSendButtonDisabled.fulfilled, (state, action) => {
                state.disabledSendButton = action.payload
            })
            .addCase(getResultData.fulfilled, (state, action) => {
                state.resultData = action.payload
            })


    }
})

export const {TypeShowPopup} = debtQuestioningSlice.actions

export default debtQuestioningSlice.reducer
