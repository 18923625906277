// ** React Imports
import { Fragment, useEffect, useState } from 'react'

// ** Table Columns
import { columns } from './columns'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { getData, exportExcel, ToggleVisitHtmlImagePopup } from '../store'

// ** Third Party Components
import DataTable from 'react-data-table-component'
import { ChevronDown, Copy, File, FileText, Grid, Printer, Share } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import ReactPaginate from 'react-paginate'

import ApprovedVisitDetailPopup from '../view/ApprovedVisitDetailPopup'
import VisitHtmlImagePopup from '../view/VisitHtmlImagePopup'

import flatpickrConfig from "@configs/flatpickrConfig"

// ** Reactstrap Imports
import {
  Button,
  Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input,
  Label, Row, Spinner, UncontrolledDropdown
} from 'reactstrap'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import { clearFilters, exportVisitsZip } from '../../visit/store'
import { LuFilterX } from 'react-icons/lu'
import { FaRegFileZipper } from 'react-icons/fa6'

// ** Table Header
const CustomHeader = ({ store, handlePerPage, rowsPerPage, handleFilter, searchTerm, dispatch, hasFilters}) => {
  const isExportZipLoading = useSelector(state => state.visit.isExportZipLoading)
  const isExportExcelLoading = useSelector(state => state.approvedVisits.isExportExcelLoading)

  // ** Clear filters when clicked
  const handleClearFilters = () => {
    dispatch(clearFilters())
    handleFilter("")  // Eğer bir arama filtresi de varsa onu da temizle
}

  return (
    <div className='invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75'>
      <Row>
        <Col xl='6' className='d-flex align-items-center p-0'>
          <div className='d-flex align-items-center w-100'>
            <label htmlFor='rows-per-page'>Toplam</label>
            <Input
              className='mx-50'
              type='select'
              id='rows-per-page'
              value={rowsPerPage}
              onChange={handlePerPage}
              style={{ width: '5rem' }}
            >
              <option value='10'>10</option>
              <option value='25'>25</option>
              <option value='50'>50</option>
            </Input>
            <label htmlFor='rows-per-page'>Kayıt Göster</label>
          </div>
        </Col>
        <Col
          xl='6'
          className='d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1'
        >
          <div className='d-flex align-items-center mb-sm-0 mb-1 me-1'>
            <label className='mb-0' htmlFor='search-invoice'>
              Ara:
            </label>
            <Input
              id='search-invoice'
              className='ms-50 w-100'
              type='text'
              value={searchTerm}
              onChange={e => handleFilter(e.target.value)}
            />
          </div>

          <div className='d-flex align-items-center table-header-actions'>
          {hasFilters && (
                        <div>
                        <Button
                        className='btn-icon me-1 btn-gray d-flex align-items-center'
                        style={{ backgroundColor: 'transparent', width: "160px"}}
                            outline
                            onClick={handleClearFilters} // Clear filters on click
                        >
                            <LuFilterX size={16} className={"me-50"}/>
                            <span>Filtreleri Kaldır</span>
                        </Button>
                        </div>
                    )}
            <Button
                onClick={() => dispatch(exportExcel(store.data))}
                className='btn-icon me-1 btn-gray d-flex align-items-center'
                outline
                disabled={isExportExcelLoading}
                >
                {isExportExcelLoading ? (
                <Spinner size="sm" color="primary" className={"me-50"} />
                ) : (
                    <Grid className='font-small-4 me-50' />
                )}
                {isExportExcelLoading ? 'Hazırlanıyor...' : 'Excele Aktar'}
            </Button>

            <Button
                        onClick={() => dispatch(exportVisitsZip({data:store.data, visitType: 0}))}
                        className='btn-icon me-1 btn-gray d-flex align-items-center'
                        style={{ width: "140px" }}
                        outline
                    >
                        {isExportZipLoading ? (
                            <Spinner size="sm" color="primary" className={"me-50"} />
                        ) : (
                            <FaRegFileZipper className='font-small-4 me-50' />
                        )}
                        {isExportZipLoading ? 'Hazırlanıyor...' : 'Zipe Aktar'}
                    </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const ApprovedVisitList = () => {
  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state.approvedVisits)
  const authStore = useSelector(state => state.auth)
  // ** States
  const [sort, setSort] = useState('desc')
  const [searchTerm, setSearchTerm] = useState('')
  const [searchIdentificationNumber, setSearchIdentificationNumber] = useState('')
  const [searchName, setSearchName] = useState('')
  const [searchSurname, setSearchSurname] = useState('')
  const [searchEmployedDate, setSearchEmployedDate] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortColumn, setSortColumn] = useState('id')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [handleClearSelectedRows, setHandleClearSelectedRows] = useState(false)
  const toggleVisitHtmlImagePopup = () => dispatch(ToggleVisitHtmlImagePopup({ show: false, image: null }))
  const [isIsyeriDropdownOpen, setIsIsyeriDropdownOpen] = useState(false)
  const [isReportStartDateDropdownOpen, setIsReportStartDateDropdownOpen] = useState(false)
  const [isReportEndDateDropdownOpen, setIsReportEndDateDropdownOpen] = useState(false)
  const selectedCompanyIds = useSelector(state => state.visit.selectedCompanyIds)
  const selectedReportStartDateYears = useSelector(state => state.visit.selectedReportStartDateYears)
  const selectedReportStartDateMonths = useSelector(state => state.visit.selectedReportStartDateMonths)
  const selectedReportEndDateYears = useSelector(state => state.visit.selectedReportEndDateYears)
  const selectedReportEndDateMonths = useSelector(state => state.visit.selectedReportEndDateMonths)
  const hasFilters = selectedCompanyIds.length > 0 || selectedReportStartDateYears.length > 0 || selectedReportStartDateMonths.length > 0 || selectedReportEndDateYears.length > 0 || selectedReportEndDateMonths.length > 0

  const toggleIsyeriDropdown = () => setIsIsyeriDropdownOpen(!isIsyeriDropdownOpen)
  const toggleReportStartDateDropdown = () => setIsReportStartDateDropdownOpen(!isReportStartDateDropdownOpen)
  const toggleReportEndDateDropdown = () => setIsReportEndDateDropdownOpen(!isReportEndDateDropdownOpen)

  const getDataParameter = () => {
    return {
      sort,
      sortColumn,
      q: searchTerm,
      page: currentPage,
      pageSize: rowsPerPage,
      identificationNumber: searchIdentificationNumber,
      name: searchName,
      surname: searchSurname,
      employedDate: searchEmployedDate
    }
  }

  // ** Get data on mount
  useEffect(() => {
    dispatch(
      getData(getDataParameter())
    )
  }, [dispatch, store.data.length, authStore.selectedCompanyGroup, sort, sortColumn, currentPage, selectedCompanyIds, selectedReportStartDateYears, selectedReportStartDateMonths, selectedReportEndDateYears, selectedReportEndDateMonths])

  useEffect(() => {
    if (currentPage > 1) setCurrentPage(1)
    else dispatch(getData(getDataParameter()))
  }, [searchTerm, selectedCompanyIds, selectedReportStartDateYears, selectedReportStartDateMonths, selectedReportEndDateYears, selectedReportEndDateMonths])


  // ** Function in get data on page change
  const handlePagination = page => {
    setCurrentPage(page.selected + 1)
    setHandleClearSelectedRows(!handleClearSelectedRows)
  }

  // ** Function in get data on rows per page
  const handlePerPage = e => {
    const value = parseInt(e.currentTarget.value)
    dispatch(
      getData(getDataParameter())
    )
    setRowsPerPage(value)
  }

  // ** Function in get data on search query change
  const handleFilter = val => {
    setSearchTerm(val)
    // dispatch(
    //   getData(getDataParameter())
    // )
  }

  useEffect(() => {
    // unmount oldugunda filtreleri temizle
    return () => {
      dispatch(clearFilters())
    }
}, [])

  // ** Custom Pagination
  const CustomPagination = () => {
    //const count = Number(Math.ceil(store.total / rowsPerPage))

    return (
      <ReactPaginate
        previousLabel={''}
        nextLabel={''}
        pageCount={store.total || 1}
        activeClassName='active'
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={page => handlePagination(page)}
        pageClassName={'page-item'}
        nextLinkClassName={'page-link'}
        nextClassName={'page-item next'}
        previousClassName={'page-item prev'}
        previousLinkClassName={'page-link'}
        pageLinkClassName={'page-link'}
        containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
      />
    )
  }

  // ** Table data to render
  const dataToRender = () => {
    const filters = {
      q: searchTerm
    }

    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })

    if (store.data.length > 0) {
      return store.data
    } else if (store.data.length === 0 && isFiltered) {
      return []
    } else {
      return store.allData.slice(0, rowsPerPage)
    }
  }

  const handleSort = (column, sortDirection) => {
    setSort(sortDirection)
    setSortColumn(column.sortField)
    // dispatch(
    //   getData(getDataParameter())
    // )
  }

  return (
    <Fragment>
      <Card hidden='true'>
        <CardHeader>
          <CardTitle tag='h4'>Filtreler</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md='3'>
              <Label for='role-select'>Kimlik No</Label>
              <Input name='text' id='username' rows='3' placeholder=''
                className='react-select'
                onChange={data => {
                  setSearchIdentificationNumber(data.target.value)
                }} />
            </Col>
            <Col className='my-md-0 my-1' md='3'>
              <Label for='plan-select'>İsim</Label>
              <Input name='text' id='username' rows='3' placeholder=''
                className='react-select'
                onChange={data => {
                  setSearchName(data.target.value)
                }} />
            </Col>
            <Col md='3'>
              <Label for='status-select'>Soyisim</Label>
              <Input name='text' id='username' rows='3' placeholder=''
                className='react-select'
                onChange={data => {
                  setSearchSurname(data.target.value)
                }} />
            </Col>
            <Col md='3'>
              <Label for='status-select'>Giriş Tarihi</Label>
              <Flatpickr className='form-control' id='employmentDate' options={flatpickrConfig} onChange={data => {
                const strDate = `${data[0].getFullYear()}-${(`0${(data[0].getMonth() + 1)}`).slice(-2)}-${(`0${data[0].getDate()}`).slice(-2)}`
                setSearchEmployedDate(strDate)
              }} />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className='overflow-hidden'>
        <div className='react-dataTable'>
          <DataTable
            noHeader
            subHeader
            sortServer
            pagination
            responsive
            paginationServer
            columns={columns(isIsyeriDropdownOpen, toggleIsyeriDropdown, isReportStartDateDropdownOpen, toggleReportStartDateDropdown, isReportEndDateDropdownOpen, toggleReportEndDateDropdown)}
            onSort={handleSort}
            sortIcon={<ChevronDown />}
            className='react-dataTable'
            paginationComponent={CustomPagination}
            data={dataToRender()}
            subHeaderComponent={
              <CustomHeader
                store={store}
                searchTerm={searchTerm}
                rowsPerPage={rowsPerPage}
                handleFilter={handleFilter}
                handlePerPage={handlePerPage}
                dispatch={dispatch}
                hasFilters={hasFilters}
              />
            }
          />
        </div>
      </Card>
      <ApprovedVisitDetailPopup open={store.detailPopupStatus} />
      <VisitHtmlImagePopup open={store.toggleVisitHtmlImagePopup?.show} toggleSidebar={toggleVisitHtmlImagePopup} />
    </Fragment>
  )
}

export default ApprovedVisitList
