/* eslint-disable no-unused-vars */
// ** React Imports
import {Fragment, useEffect, useState} from 'react'

// ** Table Columns
import {allWorkerColumns} from './allWorkerColumns'
import {activeWorkerColumns} from './activeWorkerColumns'

// ** Store & Actions
import {useDispatch, useSelector} from 'react-redux'
import {ToggleHtmlImagePopup, ToggleOutHtmlImagePopup, getDataActiveWorker, getDataAllWorker, exportExcel, exportZip, requestDailyPersonnelList, getRequestDailyPersonnelListProgress} from '../store'

// ** Third Party Components
import DataTable from 'react-data-table-component'
import {ChevronDown, Filter, File, Grid, Share, Columns} from 'react-feather'
import ReactPaginate from 'react-paginate'

import EntyDeclarationHtmlPopup from '../view/EntyDeclarationHtmlPopup'
import OutDeclarationHtmlPopup from '../view/OutDeclarationHtmlPopup'


// ** Reactstrap Imports
import {
    Card,
    Col,
    DropdownItem,
    DropdownMenu,
    DropdownToggle,
    Input,
    Row,
    UncontrolledDropdown,
    Nav,
    NavItem,
    NavLink,
    TabContent,
    TabPane,
    Button, Label,
    Spinner
} from 'reactstrap'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'

// ** Table Header
const ColumnSelectionMenu = ({ columns, type, handleColumnChange, resetColumns }) => {
    const getColumnName = (col) => {
        if (typeof col.name === 'object' && col.name.props) {
            return col.name.props.name || 'Unknown'
        }
        return col.name
    }

    // Filter out first 3 columns from being selectable
    const selectableColumns = columns.slice(3)

    return (
        <UncontrolledDropdown className='column-selection-dropdown me-1'>
            <DropdownToggle color='secondary' outline className='btn-icon d-flex align-items-center' style={{marginLeft: 10, backgroundColor: 'transparent'}}>
                <Columns className='font-small-4 me-50' />
                <span>Kolonlar</span>
            </DropdownToggle>
            <DropdownMenu container="body" style={{
                minWidth: '250px',
                maxHeight: '400px',
                overflowY: 'auto'
            }}>
                <div className="px-2 py-1">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <h6 className="m-0">Görünür Kolonlar</h6>
                        <Button.Ripple
                            color="flat-secondary"
                            size="sm"
                            onClick={() => resetColumns(type)}
                        >
                            Varsayılana Dön
                        </Button.Ripple>
                    </div>
                    {selectableColumns.map((col, index) => (
                        <div key={index} className="d-flex align-items-center py-1">
                            <Input
                                type="checkbox"
                                checked={!col.omit}
                                onChange={() => {
                                    const newColumns = [...columns]
                                    // Add 3 to index to account for the excluded columns
                                    newColumns[index + 3] = { ...col, omit: !col.omit }
                                    handleColumnChange(columns, newColumns)
                                }}
                            />
                            <span className="ms-1">{getColumnName(col)}</span>
                        </div>
                    ))}
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

const CustomHeader = ({filter, setFilterHandler, handlePerPage, rowsPerPage, searchTerm, dispatch, activeTab, active, activeColumns, allColumns, handleColumnChange, resetColumns}) => {
    const [showFilter, setShowFilter] = useState(false)
    const isExportZipLoading = useSelector(state => state.workerlist.isExportZipLoading)
    const isExportExcelLoading = useSelector(state => state.workerlist.isExportExcelLoading)
    const canWorkerListBeRequested = useSelector(state => state.workerlist.canWorkerListBeRequested)
    const authStore = useSelector(state => state.auth)
    const selectedCompanyGroup = authStore.selectedCompanyGroup
    const moduleList = authStore.userData.authModuleList.find(module => module.companyGroupId === selectedCompanyGroup)?.moduleId
    const isWorkerListTriggerAuthorized = moduleList?.includes(23)

    const handleUpdateWorkerList = () => {
        dispatch(requestDailyPersonnelList(filter))
    }

    return (
        <div className='invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75'>
            <Row>
                <Col xl='6' className='d-flex align-items-center p-0'>
                    <div className='d-flex flex-wrap align-items-center w-100'>
                        <div className='d-flex align-items-center mb-sm-0 mb-1'>
                            <label htmlFor='rows-per-page'>Toplam</label>
                            <Input
                                className='mx-50'
                                type='select'
                                id='rows-per-page'
                                value={rowsPerPage}
                                onChange={handlePerPage}
                                style={{width: '5rem'}}
                            >
                                <option value='10'>10</option>
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                            </Input>
                            <label htmlFor='rows-per-page'>Kayıt Göster</label>
                        </div>
                    </div>
                </Col>
                <Col
                    xl='6'
                    className='d-flex flex-wrap align-items-sm-center justify-content-xl-end justify-content-start pe-xl-1 p-0 mt-xl-0 mt-1'
                >
                    <div className='d-flex flex-wrap gap-1'>


                        {isWorkerListTriggerAuthorized && (
                             <Button.Ripple onClick={handleUpdateWorkerList} disabled={!canWorkerListBeRequested} className='btn-icon btn-gray'
                             style={{backgroundColor: 'transparent'}} outline>
                         {
                         !canWorkerListBeRequested ? (
                             <>
                                 <Spinner size="sm" color="primary" className={"me-50"}/>
                                 Listeniz Güncelleniyor...
                             </>
                             ) : (
                         <span>Listeyi Güncelle</span>
                         )}
                 </Button.Ripple>
                        )}

                        <Button.Ripple onClick={() => setShowFilter(!showFilter)} className='btn-icon btn-gray'
                                    style={{backgroundColor: 'transparent'}} outline>
                            <Filter className='font-small-4 me-50'/> Filtrele
                        </Button.Ripple>

                        <ColumnSelectionMenu
                            columns={active === '1' ? activeColumns : allColumns}
                            type={active === '1' ? 'active' : 'all'}
                            handleColumnChange={handleColumnChange}
                            resetColumns={resetColumns}
                        />

                        <Button.Ripple
                            onClick={() => dispatch(exportExcel({filter, activeTab}))}
                            className='btn-icon btn-gray'
                            style={{backgroundColor: 'transparent'}}
                            outline
                            disabled={isExportExcelLoading}>
                            {isExportExcelLoading ? (
                                <Spinner size="sm" color="primary" className={"me-50"}/>
                            ) : (
                                <Grid className='font-small-4 me-50'/>
                            )}
                            {isExportExcelLoading ? 'Hazırlanıyor...' : 'Excele Aktar'}
                        </Button.Ripple>

                        <Button.Ripple
                            onClick={() => dispatch(exportZip({filter, activeTab}))}
                            className='btn-icon btn-gray'
                            style={{backgroundColor: 'transparent'}}
                            outline
                            disabled={isExportZipLoading}>
                            {isExportZipLoading ? (
                                <Spinner size="sm" color="primary" className={"me-50"}/>
                            ) : (
                                <Grid className='font-small-4 me-50'/>
                            )}
                            {isExportZipLoading ? 'Hazırlanıyor...' : 'Zipe Aktar'}
                        </Button.Ripple>

                        <div className='d-flex align-items-center'>
                            <Input
                                id='search-invoice'
                                type='text'
                                value={searchTerm}
                                placeholder={"Ara"}
                                onChange={e => setFilterHandler('q', e.target.value)}
                                style={{minWidth: '150px'}}
                            />
                        </div>
                    </div>
                </Col>
            </Row>
            <Row style={{marginTop: 20, display: showFilter ? "" : "none"}}>
                <Col md='3'>
                    <Label for='role-select'>Kimlik</Label>
                    <Input name='text' rows='3' placeholder=''
                           className='react-select'
                           value={filter.IdentificationNumber}
                           onChange={data => {
                               setFilterHandler('IdentificationNumber', data.target.value)
                           }}/>
                </Col>
                <Col md='3'>
                    <Label for='role-select'>İsim</Label>
                    <Input name='text' rows='3' placeholder=''
                           className='react-select'
                           value={filter.Name}
                           onChange={data => {
                               setFilterHandler('Name', data.target.value)
                           }}/>
                </Col>
                <Col md='3'>
                    <Label for='status-select'>Soyisim</Label>
                    <Input name='text' rows='3' placeholder=''
                           className='react-select'
                           value={filter.Surname}
                           onChange={data => {
                               setFilterHandler('Surname', data.target.value)
                           }}/>
                </Col>
                <Col md='3'>
                    <Label for='status-select'>Meslek Adı</Label>
                    <Input name='text' rows='3' placeholder=''
                           className='react-select'
                           value={filter.JobName}
                           onChange={data => {
                               setFilterHandler('JobName', data.target.value)
                           }}/>
                </Col>
            </Row>
            <Row style={{marginTop: 20, display: showFilter ? "" : "none"}}>
                <Col className='my-md-0 my-1' md='4'>
                    <Label for='plan-select'>Firma Adı</Label>
                    <Input name='text' rows='3' placeholder=''
                           className='react-select'
                           value={filter.CompanyName}
                           onChange={data => {
                               setFilterHandler('CompanyName', data.target.value)
                           }}/>
                </Col>
                <Col className='my-md-0 my-1' md='4'>
                    <Label for='plan-select'>Firma Sicil</Label>
                    <Input name='text' rows='3' placeholder=''
                           className='react-select'
                           value={filter.CompanyRegistrationNumber}
                           onChange={data => {
                               setFilterHandler('CompanyRegistrationNumber', data.target.value)
                           }}/>
                </Col>
                <Col md='4'>
                    <Label for='status-select'>Meslek Kodu</Label>
                    <Input name='text' rows='3' placeholder=''
                           className='react-select'
                           value={filter.JobCode}
                           onChange={data => {
                               setFilterHandler('JobCode', data.target.value)
                           }}/>
                </Col>
            </Row>
        </div>
    )
}

const STORAGE_KEY_ACTIVE = 'worker-list-active-columns'
const STORAGE_KEY_ALL = 'worker-list-all-columns'

const UsersList = () => {
    const dispatch = useDispatch()
    const authStore = useSelector(state => state.auth)
    const storeWorkerList = useSelector(state => state.workerlist)
    const [rowsPerPage, setRowsPerPage] = useState(10)

    // Initialize columns from localStorage or use defaults
    const [activeColumns, setActiveColumns] = useState(() => {
        try {
            const saved = localStorage.getItem(STORAGE_KEY_ACTIVE)
            if (!saved) return activeWorkerColumns

            const savedStates = JSON.parse(saved)
            // Apply saved visibility states to original columns
            return activeWorkerColumns.map(col => ({
                ...col,
                omit: savedStates[col.name] || false
            }))
        } catch (error) {
            console.error('Error loading active columns:', error)
            return activeWorkerColumns
        }
    })

    const [allColumns, setAllColumns] = useState(() => {
        try {
            const saved = localStorage.getItem(STORAGE_KEY_ALL)
            if (!saved) return allWorkerColumns

            const savedStates = JSON.parse(saved)
            // Apply saved visibility states to original columns
            return allWorkerColumns.map(col => ({
                ...col,
                omit: savedStates[col.name] || false
            }))
        } catch (error) {
            console.error('Error loading all columns:', error)
            return allWorkerColumns
        }
    })

    // Save column visibility states to localStorage
    useEffect(() => {
        try {
            const visibilityStates = activeColumns.reduce((acc, col) => ({
                ...acc,
                [col.name]: col.omit || false
            }), {})
            localStorage.setItem(STORAGE_KEY_ACTIVE, JSON.stringify(visibilityStates))
        } catch (error) {
            console.error('Error saving active columns:', error)
        }
    }, [activeColumns])

    useEffect(() => {
        try {
            const visibilityStates = allColumns.reduce((acc, col) => ({
                ...acc,
                [col.name]: col.omit || false
            }), {})
            localStorage.setItem(STORAGE_KEY_ALL, JSON.stringify(visibilityStates))
        } catch (error) {
            console.error('Error saving all columns:', error)
        }
    }, [allColumns])

    // Reset columns to default function
    const resetColumns = (type) => {
        if (type === 'active') {
            setActiveColumns(activeWorkerColumns)
            localStorage.removeItem(STORAGE_KEY_ACTIVE)
        } else {
            setAllColumns(allWorkerColumns)
            localStorage.removeItem(STORAGE_KEY_ALL)
        }
    }

    const handleColumnChange = (columns, newColumns) => {
        if (columns === activeColumns) {
            setActiveColumns(newColumns)
        } else {
            setAllColumns(newColumns)
        }
    }

    const [activeTable, setActiveTable] = useState({
        sort: '',
        sortColumn: '',
        q: '',
        page: 1,
        pageSize: 10,
        active: 1,
        companyGroupId: authStore.selectedCompanyGroup,
        IdentificationNumber: '',
        Name: '',
        Surname: '',
        CompanyName: '',
        CompanyRegistrationNumber: '',
        JobName: '',
        JobCode: ''
    })
    const [allTable, setAllTable] = useState({
        sort: '',
        sortColumn: '',
        q: '',
        page: 1,
        pageSize: 10,
        active: 2,
        companyGroupId: authStore.selectedCompanyGroup,
        IdentificationNumber: '',
        Name: '',
        Surname: '',
        CompanyName: '',
        CompanyRegistrationNumber: '',
        JobName: '',
        JobCode: ''
    })

    const toggleHtmlImagePopup = () => dispatch(ToggleHtmlImagePopup({show: false, html: null}))
    const toggleOutHtmlImagePopup = () => dispatch(ToggleOutHtmlImagePopup({show: false, html: null}))
    const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
    const [active, setActive] = useState(0)

    const setFilterHandler = (parameter, value) => {
        setActiveTable({...activeTable, [parameter]: value, ["page"]: 1})
        setAllTable({...allTable, [parameter]: value, ["page"]: 1})
    }
    const setTableParameter = (parameter, value) => {
        if (active === "1") { //Aktif Çalışan
            setActiveTable({...activeTable, [parameter]: value})
        } else if (active === "2") { // Tüm Çalışan
            setAllTable({...allTable, [parameter]: value})
        }
    }
    const getDataParameter = () => {
        if (active === "1") { //Aktif Çalışan
            return activeTable
        } else if (active === "2") { // Tüm Çalışan
            return allTable
        }
    }

    const toggleTab = tab => {
        setRowsPerPage(10)
        if (active !== tab) {
            setActive(tab)
            setActiveTable({...activeTable, ["IsActive"]: tab === 1, pageSize:10})
            setAllTable({...allTable, ["IsActive"]: tab === 2, pageSize:10})
        }
    }

    const handlePagination = page => {
        setTableParameter("page", page.selected + 1)
    }
    // ** Get data on mount
    useEffect(() => {
        if (active === '2') {
            dispatch(getDataAllWorker(getDataParameter()))
            dispatch(getRequestDailyPersonnelListProgress(getDataParameter()))
        }
    }, [storeWorkerList.data.length, allTable.sort, allTable.IdentificationNumber, allTable.Name, allTable.q, allTable.Surname, allTable.CompanyName, allTable.CompanyRegistrationNumber, allTable.JobName, allTable.JobCode, allTable.page, authStore.selectedCompanyGroup, active])

    const handleFilterAll = val => {
        setActiveTable({...activeTable, ["q"]: val})
        setAllTable({...allTable, ["q"]: val})
        dispatch(
            getDataAllWorker(getDataParameter())
        )
    }

    useEffect(() => {
        if (active === '1') {
            dispatch(getDataActiveWorker(getDataParameter()))
            dispatch(getRequestDailyPersonnelListProgress(getDataParameter()))
        }
    }, [storeWorkerList.data.length, activeTable.sort, activeTable.sort, activeTable.IdentificationNumber, activeTable.Name, activeTable.q, activeTable.Surname, activeTable.CompanyName, activeTable.CompanyRegistrationNumber, activeTable.JobName, activeTable.JobCode, activeTable.page, authStore.selectedCompanyGroup, active])

     const handlePerPageAll = e => {
        const value = parseInt(e.currentTarget.value)
        setRowsPerPage(value)
        setAllTable(prevState => ({
            ...prevState,
            pageSize: value,
            page: 1  // Sayfa başına satır sayısını değiştirirken sayfayı 1 olarak ayarlar
        }))
        dispatch(getDataAllWorker({
            ...allTable,
            pageSize: value,
            page: 1  // Sayfa başına satır sayısını değiştirirken sayfayı 1 olarak ayarlar
        }))
    }

    const handlePerPageActive = e => {
        const value = parseInt(e.currentTarget.value)
        setRowsPerPage(value)
        setActiveTable(prevState => ({
            ...prevState,
            pageSize: value,
            page: 1  // Sayfa başına satır sayısını değiştirirken sayfayı 1 olarak ayarlar
        }))
        dispatch(getDataActiveWorker({
            ...activeTable,
            pageSize: value,
            page: 1  // Sayfa başına satır sayısını değiştirirken sayfayı 1 olarak ayarlar
        }))
    }

    // ** Function in get data on search query change
    const handleFilterActive = val => {
        setActiveTable({...activeTable, ["q"]: val, ["page"]: 1})
        setAllTable({...allTable, ["q"]: val, ["page"]: 1})
        dispatch(
            getDataActiveWorker(getDataParameter())
        )
    }
    useEffect(() => {
        toggleTab('1')
    }, [])
    const CustomPaginationActive = () => {
        const total = storeWorkerList.totalActive || 1
        const currentPage = activeTable.page
        return (
            <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                pageCount={total || 1}
                activeClassName='active'
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                onPageChange={page => handlePagination(page)}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                nextClassName={'page-item next'}
                previousClassName={'page-item prev'}
                previousLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
            />
        )
    }
    const CustomPaginationAll = () => {
        const total = storeWorkerList.totalAll || 1
        const currentPage = allTable.page
        return (
            <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                pageCount={total || 1}
                activeClassName='active'
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                onPageChange={page => handlePagination(page)}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                nextClassName={'page-item next'}
                previousClassName={'page-item prev'}
                previousLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
            />
        )
    }
    const allDataToRender = () => {
        const filters = {
            q: allTable.q
        }
        const isFiltered = Object.keys(filters).some(function (k) {

            return filters[k].length > 0
        })

        if (storeWorkerList.dataAllWorker.length > 0) {
            return storeWorkerList.dataAllWorker
        } else if (storeWorkerList.dataAllWorker.length === 0 && isFiltered) {
            return []
        } else {
            return storeWorkerList.dataAllWorker.slice(0, allTable.pageSize)
        }
    }
    const activeDataToRender = () => {
        const filters = {
            q: activeTable.q

        }
        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (storeWorkerList.dataActiveWorker.length > 0) {
            return storeWorkerList.dataActiveWorker
        } else if (storeWorkerList.dataActiveWorker.length === 0 && isFiltered) {
            return []
        } else {
            return storeWorkerList.dataActiveWorker.slice(0, activeTable.pageSize)
        }
    }
    const handleSort = (column, sortDirection) => {
        if (active === "1") { //Aktif Çalışan
            setActiveTable({
                ...activeTable,
                sortColumn: column.sortField,
                sort: sortDirection
            })
        } else if (active === "2") { // Tüm Çalışan
            setAllTable({
                ...allTable,
                sortColumn: column.sortField,
                sort: sortDirection
            })
        }
    }

    return (
        <Fragment>
            <Nav pills>
                <NavItem>
                    <NavLink active={active === '1'} onClick={() => toggleTab('1')}>
                        <span className='fw-bold'>Aktif Personel Listesi</span>
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink active={active === '2'} onClick={() => toggleTab('2')}>
                        <span className='fw-bold'>Tüm Personel Listesi</span>
                    </NavLink>
                </NavItem>
            </Nav>
            <Card className="text-center" style={{ color: 'red' }}>
                07.06.2014 Tarihi Öncesinde İşten Ayrılan Personel Bilgileri Bu Listede Yer Almamaktadır!
            </Card>
            <TabContent activeTab={active}>
                <TabPane tabId='1'>
                    <Card className='overflow-hidden'>
                        <div className='react-dataTable'>
                            <DataTable
                                noHeader
                                subHeader
                                sortServer
                                pagination
                                responsive
                                paginationServer
                                columns={activeColumns}
                                onSort={handleSort}
                                sortIcon={<ChevronDown/>}
                                className='react-dataTable'
                                paginationComponent={CustomPaginationActive}
                                data={activeDataToRender()}
                                subHeaderComponent={
                                    <CustomHeader
                                        store={storeWorkerList}
                                        searchTerm={activeTable.q}
                                        rowsPerPage={rowsPerPage}
                                        handleFilter={handleFilterActive}
                                        handlePerPage={handlePerPageActive}
                                        toggleSidebar={toggleSidebar}
                                        filter={activeTable}
                                        setFilterHandler={setFilterHandler}
                                        dispatch={dispatch}
                                        activeTab={active}
                                        active={active}
                                        activeColumns={activeColumns}
                                        allColumns={allColumns}
                                        handleColumnChange={handleColumnChange}
                                        resetColumns={resetColumns}
                                    />
                                }
                            />
                        </div>
                    </Card>
                </TabPane>
            </TabContent>
            <TabContent activeTab={active}>
                <TabPane tabId='2'>
                    <Card className='overflow-hidden'>
                        <div className='react-dataTable'>
                            <DataTable
                                noHeader
                                subHeader
                                sortServer
                                pagination
                                responsive
                                paginationServer
                                columns={allColumns}
                                onSort={handleSort}
                                sortIcon={<ChevronDown/>}
                                className='react-dataTable'
                                paginationComponent={CustomPaginationAll}
                                data={allDataToRender()}
                                subHeaderComponent={
                                    <CustomHeader
                                        store={storeWorkerList}
                                        searchTerm={activeTable.q}
                                        rowsPerPage={rowsPerPage}
                                        handleFilter={handleFilterAll}
                                        handlePerPage={handlePerPageAll}
                                        toggleSidebar={toggleSidebar}
                                        filter={activeTable}
                                        setFilterHandler={setFilterHandler}
                                        dispatch={dispatch}
                                        activeTab={active}
                                        active={active}
                                        activeColumns={activeColumns}
                                        allColumns={allColumns}
                                        handleColumnChange={handleColumnChange}
                                        resetColumns={resetColumns}
                                    />
                                }
                            />
                        </div>
                    </Card>
                </TabPane>
            </TabContent>
            <EntyDeclarationHtmlPopup open={storeWorkerList.toggleHtmlImagePopup?.show}
                                      toggleSidebar={toggleHtmlImagePopup}/>
            <OutDeclarationHtmlPopup open={storeWorkerList.toggleOutHtmlImagePopup?.show}
                                      toggleSidebar={toggleOutHtmlImagePopup}/>
        </Fragment>
    )
}

export default UsersList
