// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'

// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const setFilePopupOpen = createAsyncThunk('user/setFilePopupOpen', (status) => {
    return status
})

export const setJobCodeFilePopupOpen = createAsyncThunk('user/setJobCodeFilePopupOpen', (status) => {
    return status
})

export const toggleSgkSingularEntryPopupClose = createAsyncThunk('user/closeEntryPopup', (status) => {
    return status
})

export const toggleJobCodePopup = createAsyncThunk('user/toggleJobCodePopup', (status) => {
    return status
})

export const toggleSgkSingularOutPopupClose = createAsyncThunk('user/closePopup', (status) => {
    return status
})

export const getCompanyList = createAsyncThunk('api/getCompanyList', async (data, {getState}) => {
    const response = await axios.get(`/api/companygroup/${getState().auth.selectedCompanyGroup}/companies`)
    return response.data
})

export const entrySgkWorkerDeclarationApi = createAsyncThunk('api/entrySgkWorkerDeclaration', async (data, {dispatch}) => {
    const response = await axios.post('/api/CompanyWorker/sgkSingularEntry', data)
    if (response && response.status === 200) {
        toast.success('İşleminiz Başlatıldı')
        await dispatch(toggleSgkSingularEntryPopupClose(false))
    }
    return response
})

export const jobCodeUpdateApi = createAsyncThunk('api/jobCodeUpdateApi', async (data, {dispatch}) => {
    const response = await axios.post('/api/CompanyWorker/jobCodeUpdate', data)
    if (response && response.status === 200) {
        toast.success('İşleminiz Başlatıldı')
        await dispatch(toggleJobCodePopup(false))
    }
    return response
})

export const addSgkWorkerFile = createAsyncThunk('api/sgkWorkerFile', async (data, {dispatch, getState}) => {
    let fileUrl = `entry`
    if (getState().sgkWorkerDeclaration.fileType === "3") fileUrl = `entryWithPoliceDeclaration`
    if (getState().sgkWorkerDeclaration.fileType === "2") fileUrl = `out`
    if (getState().sgkWorkerDeclaration.fileType === "1") fileUrl = `entry`
    const response = await axios.post(`/api/companyworker/file/${fileUrl}`, data)
    if (response && response.status === 200) {
        toast.success('İşleminiz Başlatıldı')
        await dispatch(setFilePopupOpen(false))
    }
    return workerlist
})

export const addJobCodeFile = createAsyncThunk('api/addJobCodeFile', async (data, {dispatch}) => {
    const response = await axios.post(`/api/companyworker/file/jobCode`, data)
    if (response && response.status === 200) {
        toast.success('İşleminiz Başlatıldı')
        await dispatch(setFilePopupOpen(false))
    }
    return workerlist
})

export const outSgkWorkerDeclarationApi = createAsyncThunk('api/outSgkWorkerDeclaration', async (data, {
    dispatch,
    getState
}) => {
    const response = await axios.post('/api/CompanyWorker/sgkSingularOut', data)
    if (response && response.status === 200) {
        toast.success('İşleminiz Başlatıldı.')
        await dispatch(toggleSgkSingularOutPopupClose(false))
        await dispatch(getData(getState().workerlist.params))
    }
    return response
})

export const setFileType = createAsyncThunk('api/FileType', async (type) => {
    return type
})

export const outSgkWorkerDeclaration = createAsyncThunk('api/outSgkWorkerDeclaration', (data) => {
    return data
})

export const getWorkerLogs = createAsyncThunk("api/getWorkerLogs", async params => {
    const url = `/api/WorkerDeclaration/GetLogs`
    const response = await axios.get(url, {params})
    return {
        params,
        data: response.data.scheduler,
        totalPages: response.data.pageCount
    }
})

export const downloadLogExcel = createAsyncThunk("api/downloadLogExcel", async params => {
    const response = await axios.get(`/api/WorkerDeclaration/ExportLogs`, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob',
        params: {id: params}
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'sonuc.xlsx')
    document.body.appendChild(link)
    link.click()
    return {
        data: response.data.scheduler
    }
})

export const downloadDeclaration = createAsyncThunk("api/downloadDeclaration", async params => {
    const response = await axios.get(`/api/WorkerDeclaration/Declaration`, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob',
        params: {id: params}
    })
    if (response && response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'sonuc.zip')
        document.body.appendChild(link)
        link.click()
    } else {
        toast.error('İndirilecek dosya bulunamadı!')
    }
})

export const getCompanyGroupData = createAsyncThunk('companies/getCompanyGroupData', async () => {
    const url = `/api/companygroup?PageSize=100&page=1`
    const response = await axios.get(url)
    const selectData = []
    response.data.companyGroups.map((item) => {
        selectData.push({label: item.name, value: item.id})
    })
    return selectData
})

export const getSgkEducationList = createAsyncThunk('api/sgkEducationTypes', async () => {
    const response = await axios.get('/api/CompanyWorker/sgkEducationTypes')
    const selectData = []
    response.data.educationTypes.map((item) => {
        selectData.push({label: item.label, value: item.value.toString()})
    })
    return selectData
})

export const getCSGBBusinessDepartmentList = createAsyncThunk('api/CSGBBusinessDepartments', async () => {
    const response = await axios.get('/api/CompanyWorker/CSGBBusinessDepartments')
    return response.data
})

export const getDutyCodeList = createAsyncThunk('api/DutyCodes', async () => {
    const response = await axios.get('/api/CompanyWorker/DutyCodes')
    return response.data
})

export const getInsuranceTypesList = createAsyncThunk('api/InsuranceTypes', async () => {
    const response = await axios.get('/api/CompanyWorker/InsuranceTypes')
    return response.data
})

export const getReasonForLeavingList = createAsyncThunk('api/ReasonForLeaving', async () => {
    const response = await axios.get('/api/CompanyWorker/ReasonForLeaving')
    return response.data
})

export const getWorkerOutDocumentTypesList = createAsyncThunk('api/WorkerOutDocumentTypes', async () => {
    const response = await axios.get('/api/CompanyWorker/WorkerOutDocumentTypes')
    return response.data
})

export const getMissingDayReasonList = createAsyncThunk('api/MissingDayReason', async () => {
    const response = await axios.get('/api/CompanyWorker/MissingDayReason')
    return response.data
})

export const toggleEntryOutDeclarationDeletePopup = createAsyncThunk('user/toggleEntryOutDeclarationDeletePopup', (status) => {
    return status
})

export const entryOutDeclarationDeleteApi = createAsyncThunk('api/entryOutDeclarationDeleteApi', async (data, {dispatch}) => {
    const url = data.type === 'entry' ? '/api/CompanyWorker/DeleteSingularSgkWorkerEntry' : '/api/CompanyWorker/DeleteSingularSgkWorkerOut'
    const response = await axios.post(url, {
        identificationNumber: data.identificationNumber,
        companyId: data.companyId
    })

    if (response && response.status === 200) {
        toast.success('İşleminiz Başlatıldı')
        await dispatch(toggleEntryOutDeclarationDeletePopup(false))
    }
    return response
})

export const appSgkWorkerDeclarationSlice = createSlice({
    name: 'appSgkWorkerDeclaration',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        companyGroupSelectData: [],
        companyPasswords: [],
        selectedCompany: null,
        fileType: "1",
        singularOutTckn: 0,
        singularOutNameSurname: "",
        singularPopupStatus: false,
        singularEntryPopupStatus: false,
        jobCodePopupStatus: false,
        entryOutDeclarationDeletePopupStatus: false,
        filePopupOpen: false,
        fileJobCodePopupOpen: false,
        companyList: [],
        workerLogsTotal: 1,
        workerLogs: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(toggleSgkSingularEntryPopupClose.fulfilled, (state, action) => {
                state.singularEntryPopupStatus = action.payload
            })
            .addCase(toggleJobCodePopup.fulfilled, (state, action) => {
                state.jobCodePopupStatus = action.payload
            })
            .addCase(toggleSgkSingularOutPopupClose.fulfilled, (state, action) => {
                state.singularPopupStatus = action.payload
            })
            .addCase(toggleEntryOutDeclarationDeletePopup.fulfilled, (state, action) => {
                state.entryOutDeclarationDeletePopupStatus = action.payload
            })
            .addCase(outSgkWorkerDeclaration.fulfilled, (state, action) => {
                state.singularOutTckn = action.payload.tckn
                state.singularOutNameSurname = action.payload.nameSurname
            })
            .addCase(setFileType.fulfilled, (state, action) => {
                state.fileType = action.payload
            })
            .addCase(getSgkEducationList.fulfilled, (state, action) => {
                state.sgkEducationData = action.payload
            })
            .addCase(getCSGBBusinessDepartmentList.fulfilled, (state, action) => {
                state.CSGBBusinessDepartmentsData = action.payload
            })
            .addCase(getDutyCodeList.fulfilled, (state, action) => {
                state.DutyCodesData = action.payload
            })
            .addCase(getInsuranceTypesList.fulfilled, (state, action) => {
                state.InsuranceTypesData = action.payload
            })
            .addCase(getReasonForLeavingList.fulfilled, (state, action) => {
                state.ReasonForLeavingData = action.payload
            })
            .addCase(getWorkerOutDocumentTypesList.fulfilled, (state, action) => {
                state.WorkerOutDocumentTypesData = action.payload
            })
            .addCase(getMissingDayReasonList.fulfilled, (state, action) => {
                state.MissingDayReasonData = action.payload
            })
            .addCase(getCompanyList.fulfilled, (state, action) => {
                state.companyList = action.payload
            })
            .addCase(setFilePopupOpen.fulfilled, (state, action) => {
                state.filePopupOpen = action.payload
            })
            .addCase(setJobCodeFilePopupOpen.fulfilled, (state, action) => {
                state.fileJobCodePopupOpen = action.payload
            })
            .addCase(getWorkerLogs.fulfilled, (state, action) => {
                state.workerLogs = action.payload.data
                state.workerLogsTotal = action.payload.count
            })
    }
})

export default appSgkWorkerDeclarationSlice.reducer