import { useEffect } from 'react'
import flatpickrConfig from "@configs/flatpickrConfig"
import '@styles/react/libs/flatpickr/flatpickr.scss'
import Cleave from 'cleave.js/react'
import Flatpickr from 'react-flatpickr'
import { Controller, useForm } from 'react-hook-form'
import { useDispatch, useSelector } from 'react-redux'
import Select from 'react-select'
import { Button, Form, FormText, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'
import { addWorkerDeclaration, getCompanyList } from './store'

// ** Utils
import { sendDateFormat } from "@utils"

const SidebarNewUsers = ({ open, toggleSidebar, gendarmerieCountryDatas, staffTypeDatas }) => {
  const optionsTcKimlikNo = { blocks: [11], numericOnly: true }
  const optionsSgkNumber = { blocks: [29], numericOnly: true }
  const optionsoDeservedFee = { numeral: true, numeralThousandsGroupStyle: 'thousand' }
  const dispatch = useDispatch()
  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({})
  const onSubmit = data => {
    dispatch(
      addWorkerDeclaration({
        identificationNumber: data.identificationNumber ?? "",
        residenceAddress: data.residenceAddress ?? "",
        accommodationAddress: data.accommodationAddress ?? "",
        workerTypeOfGendarmerie: data.workerTypeOfGendarmerie.value ?? "",
        phoneNumber: data.phoneNumber ?? "0",
        gendarmerieCountry: data.gendarmerieCountry.value ?? 0,
        employmentDate: sendDateFormat(data.employmentDate) ?? null,
        title: data.title ?? "",
        Type: 1,
        WorkerTypeId: 1,
        SgkNumber: data.sgkNumber ?? "0",
        CompanyId: data.companyId ? data.companyId.value : 0,
        GrossPrice: data.grossPrice ? data.grossPrice.replace(',', '') : 0,
        isPoliceHotelWorkerDeclarationEmploye: false
      })
    )

  }
  const store = useSelector(state => state.gendarmerie)
  const popupClose = () => {
    toggleSidebar()
    reset()
  }

  useEffect(() => {
    dispatch(getCompanyList(1))
  }, [])

  return (
    <>
      <Modal isOpen={open} toggle={popupClose} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={popupClose}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>Jandarma Çalışan Bildirimi</h1>
            <p>Jandarma Çalışan Bildirim Ekranı</p>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-1'>
              <Label className='form-label' for='company'>
                Sicil <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='companyId'
                control={control}
                render={({ field }) => (
                  <Select
                    isClearable={false}
                    options={store.companyList}
                    className='react-select'
                    classNamePrefix='select'
                    {...field}
                  />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='identificationNumber'>
                TC Kimlik Numarası <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='identificationNumber'
                control={control}
                render={({ field }) => (
                  <Cleave className='form-control' id='identificationNumber' placeholder='' invalid={errors.identificationNumber && true} {...field} options={optionsTcKimlikNo} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='residenceAddress'>
                Adres <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='residenceAddress'
                control={control}
                render={({ field }) => (
                  <Input type='textarea' name='text' id='residenceAddress' rows='3' placeholder='' invalid={errors.residenceAddress && true} {...field} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='accommodationAddress'>
                Konaklama Adresi <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='accommodationAddress'
                control={control}
                render={({ field }) => (
                  <Input type='textarea' name='text' id='accommodationAddress' rows='3' placeholder='' invalid={errors.accommodationAddress && true} {...field} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='sgkNumber'>
                SGK Numarası
              </Label>
              <Controller
                name='sgkNumber'
                control={control}
                render={({ field }) => (
                  <Cleave className='form-control' id='sgkNumber' placeholder='' invalid={errors.sgkNumber && true} {...field} options={optionsSgkNumber} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='grossPrice'>
                Brüt Maaş
              </Label>
              <Controller
                name='grossPrice'
                control={control}
                render={({ field }) => (
                  <Cleave className='form-control' id='grossPrice' placeholder='' invalid={errors.grossPrice && true} {...field} options={optionsoDeservedFee} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='workerTypeOfGendarmerie'>
                İş Türü <span className='text-danger'>*</span>
              </Label>
              <Controller
                  name='workerTypeOfGendarmerie'
                  control={control}
                  render={({ field }) => (
                      <Select
                          isClearable={false}
                          options={staffTypeDatas}
                          className='react-select'
                          classNamePrefix='select'
                          invalid={errors.workerTypeOfGendarmerie && true}
                          {...field}
                      />
                  )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='phoneNumber'>
                Telefon Numarası
              </Label>
              <Controller
                name='phoneNumber'
                control={control}
                render={({ field }) => (
                  <Cleave className='form-control' id='phoneNumber' placeholder='05*********' invalid={errors.phoneNumber && true} {...field} options={optionsTcKimlikNo} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='gendarmerieCountry'>
                Ülke <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='gendarmerieCountry'
                control={control}
                render={({ field }) => (
                  <Select
                    isClearable={false}
                    options={gendarmerieCountryDatas}
                    className='react-select'
                    classNamePrefix='select'
                    invalid={errors.companyGroupId && true}
                    {...field}
                  />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='employmentDate'>
                İşe Giriş Tarihi <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='employmentDate'
                control={control}
                render={({ field }) => (
                  <Flatpickr className='form-control' id='employmentDate' options={flatpickrConfig} invalid={errors.employmentDate && true} {...field} />
                )}
              />
            </div>
            <div className='mb-1'>
              <Label className='form-label' for='title'>
                Görevi <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='title'
                control={control}
                render={({ field }) => (
                  <Input id='title' placeholder='' invalid={errors.title && true} {...field} />
                )}
              />
            </div>
            <Button type='submit' className='me-1' color='primary'>
              Kaydet
            </Button>
            <Button type='reset' color='secondary' outline onClick={popupClose} >
              İptal
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default SidebarNewUsers
