// ** React Import
import { useEffect } from 'react'

// ** Third Party Components
import Flatpickr from 'react-flatpickr'
import { Controller, useForm } from 'react-hook-form'


// ** Reactstrap Imports
import { Button, Form, Input, Label, Modal, ModalBody, ModalHeader } from 'reactstrap'

// ** Store & Actions
import flatpickrConfig from "@configs/flatpickrConfig"
import '@styles/react/libs/flatpickr/flatpickr.scss'
import { useDispatch, useSelector } from 'react-redux'
import { removeWorkerDeclaration } from './store'
import { sendDateFormat } from "@utils"

const DissmisallPopup = ({ open, toggleDissmisallPopup }) => {
  // ** States

  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state.police)

  const {
    control,
    handleSubmit,
    reset,
    formState: { errors }
  } = useForm({ defaultValues: { identificationNumber: store.selectedUser?.identificationNumber } })
  const onSubmit = data => {
    debugger
    dispatch(
      removeWorkerDeclaration({
        id: store.selectedUser.id,
        identificationNumber: data.identificationNumber,
        dismissalDate: data.dissmisalDate ? sendDateFormat(data.dissmisalDate) : null
      })
    )
  }

  useEffect(() => {
    reset()
  }, [store.dissmisalPopup])

  const closePopup = () => {
    toggleDissmisallPopup()
  }

  return (
    <>
      <Modal isOpen={open} toggle={closePopup} className='modal-dialog-centered modal-lg'>
        <ModalHeader className='bg-transparent' toggle={closePopup}></ModalHeader>
        <ModalBody className='px-sm-5 mx-50 pb-5'>
          <div className='text-center mb-2'>
            <h1 className='mb-1'>Kullanıcı Çıkışı bildir</h1>
            <p>Emniyet Kullanıcı bildirim ekranı</p>
          </div>
          <Form onSubmit={handleSubmit(onSubmit)}>
            <div className='mb-1'>
              <Label className='form-label' for='dissmisalDate'>
                İşten Çıkış Tarihi <span className='text-danger'>*</span>
              </Label>
              <Controller
                name='dissmisalDate'
                control={control}
                render={({ field }) => (
                  <Flatpickr className='form-control' id='dissmisalDate' options={flatpickrConfig} invalid={errors.dissmisalDate && true} {...field} />
                )}
              />
            </div>
            {store.selectedUser?.isPoliceHotelWorkerDeclarationEmploye ? <div className="mb-1">
              <Label className="form-label" for="title">
                Güvenlik Kodu <span className="text-danger">*</span>
              </Label>
              <Controller
                name="securityKey"
                control={control}
                render={({ field }) => (
                  <Input id="securityKey" placeholder="" invalid={errors.securityKey && true} {...field} />
                )}
              />
            </div> : null}
            <Button type='submit' className='me-1' color='primary'>
              Kaydet
            </Button>
            <Button type='reset' color='secondary' outline onClick={closePopup}>
              İptal
            </Button>
          </Form>
        </ModalBody>
      </Modal>
    </>
  )
}

export default DissmisallPopup
