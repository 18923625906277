// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'
import {createTab} from "../../../../redux/tabManagement"

export const getData = createAsyncThunk('incentiveDefinationDelete/getData', async (params, {getState}) => {
    const url = `/api/incentiveDefinationDelete/0/?PageSize=${params.pageSize}&page=${params.page}&id=0&CompanyId=&CompanyGroupId=${getState().auth.selectedCompanyGroup}`
    const response = await axios.get(url)
    return {
        params,
        data: response.data.incentiveDefinationDeletes,
        totalPages: response.data.pageCount
    }
})

export const filePopupState = createAsyncThunk('incentiveDefinationDelete/filePopup', (status) => {
    return status
})

export const incentiveFilePopupState = createAsyncThunk('incentiveDefinationDelete/incentiveFilePopupState', (status) => {
    return status
})

export const secondPhasePopupState = createAsyncThunk('incentiveDefinationDelete/secondPhasePopupState', (status) => {
    return status
})

export const showProcessData = createAsyncThunk("incentive/showProcessData", (data, {dispatch}) => {
    dispatch(createTab(data))
})

export const IncentiveDeletionDetectedXml = createAsyncThunk('incentiveDefinationDelete/IncentiveDeletionDetectedXml', async (data) => {
    const toastId = toast.loading('XML yüklenirken lütfen bekleyin...')
    const response = await axios.post('/api/incentive/IncentiveDeletionDetectedXml', data)
    toast.dismiss(toastId)
    if (response && response.status === 200) {
        toast.success('İşleminiz Başarılı')
    }
})

export const IncentiveDeletionDetectedSecondPhase = createAsyncThunk('incentiveDefinationDeleteSecondPhase/IncentiveDeletionDetectedSecondPhase', async (data) => {
    const response = await axios.post('/api/incentive/IncentiveAlreadyRecognizedInCompany', data)
    if (response && response.status === 200) {
        toast.success('İşleminiz Başarılı')
    }
})

export const getIncentiveDefinationProcessWorkers = createAsyncThunk('incentiveDefinationDelete/getIncentiveDefinationProcessWorkers', async (params) => {
    const path = `/api/Incentive/GetIncentiveDefinationProcessWorkers`
    const response = await axios.post(path, params)
    return {
        params,
        data: response.data.processList,
        totalPages: response.data.pageCount
    }
})

export const IncentiveDeletionIdentityDetectedExcel = createAsyncThunk('incentiveDefinationDelete/IncentiveDeletionIdentityDetectedExcel', async (data) => {
    const toastId = toast.loading('XML yüklenirken lütfen bekleyin...')
    const response = await axios.post('/api/incentive/IncentiveDeletionIdentityDetectedExcel', data)
    toast.dismiss(toastId)
    if (response && response.status === 200) {
        toast.success('İşleminiz Başarılı')
    }
})

export const getSchedulerList = createAsyncThunk('incentiveDefinationDelete/getSchedulerList', async (data, {getState}) => {
    const companyGroupId = getState().auth.selectedCompanyGroup
    const response = await axios.get(`/api/Scheduler`, {
        params: {
            SchedulerType: data,
            companyGroupId
        }
    })
    return {
        data: response.data.scheduler
    }
})

export const getSchedulerListV2 = createAsyncThunk('incentiveDefinationDelete/getSchedulerListV2', async (data, {getState}) => {
    const companyGroupId = getState().auth.selectedCompanyGroup
    const response = await axios.get(`/api/Scheduler`, {
        params: {
            SchedulerType: data,
            companyGroupId
        }
    })
    return {
        data: response.data.scheduler
    }
})

export const getSchedulerListV3 = createAsyncThunk('incentiveDefinationDelete/getSchedulerListV3', async (data, {getState}) => {
    const companyGroupId = getState().auth.selectedCompanyGroup
    const response = await axios.get(`/api/Scheduler`, {
        params: {
            SchedulerType: data,
            companyGroupId
        }
    })
    return {
        data: response.data.scheduler
    }
})


export const incentiveDefinationDeleteControl = createAsyncThunk('incentiveDefinationDelete/control', async (status, {
    dispatch,
    getState
}) => {
    if (status === true) {
        const fileData = getState().incentiveDefinationDelete.fileData
        const periodData = getState().incentiveDefinationDelete.filePeriod
        if (periodData === 2) {
            toast.error('Bordro Dönemi Seçiniz..')
            return
        }
        if (fileData === null) {
            toast.error('Dosya Seçiniz..')
            return
        }
        const data = new FormData()
        if (fileData !== null) {
            data.append('file', fileData, fileData.name)
        }
        data.append('period', periodData)
        data.append('companyGroupId', getState().auth.selectedCompanyGroup)

        const response = await axios.post(`/api/incentiveDefinationDelete/control`, data, {
            responseType: 'arraybuffer',
            'Content-Type': 'blob'
        })
        if (response.status === 200) {
            await dispatch(filePopupState(false))
        }

        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'sonuc.xlsx')
        document.body.appendChild(link)

        await dispatch(getData(getState().incentiveDefinationDelete.params))

        return link
    }
})

export const downloadSchedulerExcel = createAsyncThunk('incentiveDefinationDelete/downloadSchedulerExcel', async (id) => {
    const response = await axios.post(`/api/Incentive/GetIncentiveDefinationProcessWorkersExport`, {
            SchedulerId: id
        },
        {
            responseType: 'arraybuffer',
            'Content-Type': 'blob'
        })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'sonuc.xlsx')
    document.body.appendChild(link)
    link.click()
    return {
        data: response.data.scheduler
    }
})

export const downloadComparisonExcel = createAsyncThunk('incentiveDefinationDelete/downloadComparisonExcel', async (id, {getState}) => {
    const companyGroupId  = getState().auth.selectedCompanyGroup
    const response = await axios.post(`/api/Incentive/CompareIncentiveDeletionDetected`, {
        CompanyGroupId :companyGroupId
        },
        {
            responseType: 'arraybuffer',
            'Content-Type': 'blob'
        })
        console.log("res", response)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'hero-karsilastirma.xlsx')
    document.body.appendChild(link)
    if (response.status === 200) {
        link.click()
    }
    return {
        data: response.data.scheduler
    }
})

export const downloadFinalResultExcel = createAsyncThunk('incentiveDefinationDelete/downloadFinalResultExcel', async (id, {getState}) => {
    const companyGroupId  = getState().auth.selectedCompanyGroup
    const response = await axios.post(`/api/Incentive/DeletionDetectedMinusComparisonExcel`, {
        CompanyGroupId :companyGroupId
        },
        {
            responseType: 'arraybuffer',
            'Content-Type': 'blob'
        })
        console.log("res", response)
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'final-result.xlsx')
    document.body.appendChild(link)
    if (response.status === 200) {
        link.click()
    }
    return {
        data: response.data.scheduler
    }
})

export const appIncentiveDefinationDeleteSlice = createSlice({
    name: 'incentiveDefinationDelete',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        schedulerData: [],
        schedulerDataV2: [],
        schedulerDataV3: [],
        workerProcessTotal: 1,
        workerProcessData: [],
        filePopupStatus: false,
        incentiveFilePopupStatus: false,
        fileData: null,
        filePeriod: 2,
        responseExcel: null,
        controlPending: true,
        secondPhasePopupStatus: false,
        finalResultExcelLoading: false
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
                state.controlPending = false
            })
            .addCase(filePopupState.fulfilled, (state, action) => {
                state.filePopupStatus = action.payload
            })
            .addCase(incentiveFilePopupState.fulfilled, (state, action) => {
                state.incentiveFilePopupStatus = action.payload
            })
            .addCase(getSchedulerList.fulfilled, (state, action) => {
                state.schedulerData = action.payload.data
            })
            .addCase(getSchedulerListV2.fulfilled, (state, action) => {
                state.schedulerDataV2 = action.payload.data
            })
            .addCase(getSchedulerListV3.fulfilled, (state, action) => {
                state.schedulerDataV3 = action.payload.data
            })
            .addCase(getIncentiveDefinationProcessWorkers.fulfilled, (state, action) => {
                state.workerProcessData = action.payload.data
                state.workerProcessTotal = action.payload.totalPages
            })
            .addCase(secondPhasePopupState.fulfilled, (state, action) => {
                state.secondPhasePopupStatus = action.payload
            })
            .addCase(downloadFinalResultExcel.pending, (state) => {
                state.finalResultExcelLoading = true
            })
            .addCase(downloadFinalResultExcel.fulfilled, (state) => {
                state.finalResultExcelLoading = false
            })
    }
})

export default appIncentiveDefinationDeleteSlice.reducer
