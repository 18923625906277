// ** React Imports
import React, {useState} from 'react'

// ** Reactstrap Imports
import {Button, Col, Modal, ModalBody, ModalHeader, Row, Form, Label} from 'reactstrap'
import {startDebtQuestioning, TypeShowPopup} from './store'

// ** Store & Actions
import {useDispatch, useSelector} from 'react-redux'
import Select from "react-select"

// Enum'dan gelen değerler
const enumOptions = [
    {value: 1, label: 'İhale konusu olmayan Türkiye geneli'},
    {value: 2, label: 'İhale konusu olmayan tek işyeri'},
    {value: 3, label: 'KOSGEB\'e verilmek üzere'},
    {value: 4, label: 'TÜBİTAK\'a verilmek üzere'},
    {value: 5, label: 'Hazine ve Maliye Bakanlığı\'na verilmek üzere'},
    {value: 6, label: 'Sanayi ve Teknoloji Bakanlığı\'na verilmek üzere'},
    {value: 7, label: 'Kredi Garanti Fonu\'na verilmek üzere'}
]

const TypePopup = () => {
    // ** Store Vars
    const dispatch = useDispatch()
    const store = useSelector(state => state.debtQuestioning)

    // ** Local State for Form Inputs
    const [selectedOption, setSelectedOption] = useState(null)

    // ** Close Modal Handler
    const popupClose = () => {
        dispatch(TypeShowPopup({show: false, id: 0, isAll: false }))
    }

    const handleSelectChange = (selectedOption) => {
        setSelectedOption(selectedOption)
    }

    // ** Form Submit Handler
    const handleSubmit = (e) => {
        e.preventDefault()
        dispatch(startDebtQuestioning(selectedOption.value))
        dispatch(TypeShowPopup({show: false, id: 0, isAll: false }))
    }

    return (
        <Modal isOpen={store.PopupShow.show} toggle={popupClose} className='modal-dialog-centered modal-lg'>
            <ModalHeader className='bg-transparent' toggle={popupClose}></ModalHeader>
            <ModalBody className='px-sm-5 mx-50 pb-5'>
                <div className='text-center mb-2'>
                    <h2 className='mb-1'>Borç Sorgula</h2>
                </div>
                <Row>
                    <Col md="12" className="justify-content-center"> {/* Center the input */}
                        <Form onSubmit={handleSubmit}>
                            <div className='mb-1'>
                                <Label className='form-label' for='company'>
                                    Başvuru Amacını Seçin <span className='text-danger'>*</span>
                                </Label>
                                <Select
                                    isClearable={false}
                                    options={enumOptions}
                                    className='react-select'
                                    classNamePrefix='select'
                                    onChange={handleSelectChange}
                                />
                            </div>
                            <Button color="primary" type="submit" block>
                                Sorgula
                            </Button>
                        </Form>
                    </Col>
                </Row>
            </ModalBody>
        </Modal>
    )
}

export default TypePopup
