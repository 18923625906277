// ** React Imports
import {Fragment, useEffect, useState} from 'react'

import CreatePopup from '../LoginPopup'
import HtmlPopup from '../HtmlPopup'
import VisitHtmlImagePopup from '../VisitHtmlImagePopup'

// ** Table Columns
import {columns} from './ProvisionListColumns'

// ** Store & Actions
import {useDispatch, useSelector} from 'react-redux'
import {getDataProvision, ToggleHtmlPopup, exportExcel, ToggleVisitHtmlImagePopup, getMailOptions, setMailOptions, clearFilters} from '../store'

// ** Third Party Components
import DataTable from 'react-data-table-component'
import {ChevronDown, Copy, File, FileText, Grid, Printer, Share, Settings} from 'react-feather'
import ReactPaginate from 'react-paginate'

// ** Reactstrap Imports
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input,
    Label, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane, UncontrolledDropdown
} from 'reactstrap'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import { LuFilterX } from 'react-icons/lu'

// ** Table Header
// eslint-disable-next-line no-unused-vars
const CustomHeader = ({toggleSidebar, handlePerPage, rowsPerPage, handleFilter, searchTerm, dispatch, hasFilters, getDataParameter}) => {
    const receiveAutomatedWorkAccidentMails = useSelector(state => state.workAccident.receiveAutomatedWorkAccidentMails)
    const isExportExcelLoading = useSelector(state => state.workAccident.isExportExcelLoading)
    const handleMailOptionChange = () => {
        dispatch(setMailOptions(!receiveAutomatedWorkAccidentMails))
    }

    // ** Clear filters when clicked
    const handleClearFilters = () => {
        dispatch(clearFilters())
        handleFilter("")  // Eğer bir arama filtresi de varsa onu da temizle
    }

    return (
        <div className='invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75'>
            <Row>
                <Col xl='6' className='d-flex align-items-center p-0'>
                    <div className='d-flex align-items-center w-100'>
                        <label htmlFor='rows-per-page'>Toplam</label>
                        <Input
                            className='mx-50'
                            type='select'
                            id='rows-per-page'
                            value={rowsPerPage}
                            onChange={handlePerPage}
                            style={{width: '5rem'}}
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                        </Input>
                        <label htmlFor='rows-per-page'>Kayıt Göster</label>
                    </div>
                </Col>

                <Col
                    xl='6'
                    className='d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1'
                >

                    <div className='d-flex align-items-center mb-sm-0 mb-1 me-1'>
                        <label className='mb-0' htmlFor='search-invoice'>
                            Ara:
                        </label>
                        <Input
                            id='search-invoice'
                            className='ms-50 w-100'
                            type='text'
                            value={searchTerm}
                            onChange={e => handleFilter(e.target.value)}
                        />
                    </div>

                    {hasFilters && (
                        <div>
                        <Button
                        className='btn-icon me-1 btn-gray d-flex align-items-center'
                        style={{ backgroundColor: 'transparent', width: "160px"}}
                            outline
                            onClick={handleClearFilters} // Clear filters on click
                        >
                            <LuFilterX size={16} className={"me-50"}/>
                            <span>Filtreleri Kaldır</span>
                        </Button>
                        </div>
                    )}

                    <UncontrolledDropdown className="me-1">
                            <DropdownToggle color="secondary" caret outline>
                            <Settings className="font-small-4 me-50"/>
                            <span className="align-middle">Otomasyon Ayarları</span>
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem onClick={(e) => { e.preventDefault() }}>
                                    <div className="d-flex align-items-center">
                                        <Input
                                            type="checkbox"
                                            id="receiveAutomatedWorkAccidentMails"
                                            checked={receiveAutomatedWorkAccidentMails}
                                            onChange={handleMailOptionChange}
                                        />
                                        <Label
                                            for="auto-approve"
                                            className="mb-0 ms-1"
                                        >
                                            Mail Bildirimi
                                        </Label>
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                    <div className='d-flex align-items-center table-header-actions'>

                    <Button
                        onClick={() => dispatch(exportExcel(getDataParameter()))}
                        className='btn-icon me-1 btn-gray d-flex align-items-center'
                        outline
                        disabled={isExportExcelLoading}
                        >
                            {isExportExcelLoading ? (
                        <Spinner size="sm" color="primary" className={"me-50"} />
                        ) : (
                            <Grid className='font-small-4 me-50' />
                        )}
                        {isExportExcelLoading ? 'Hazırlanıyor...' : 'Excele Aktar'}
                    </Button>

                        {/* <Button className='add-new-user me-1' color='primary' onClick={toggleSidebar}>
                            İş Kazası Sistemine Git
                        </Button> */}
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const UsersList = () => {
    // ** Store Vars
    const dispatch = useDispatch()
    const store = useSelector(state => state.workAccident)
    const authStore = useSelector(state => state.auth)

    // ** States
    const [sort, setSort] = useState('desc')
    const [searchTerm, setSearchTerm] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [sortColumn, setSortColumn] = useState('id')
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [isIsyeriDropdownOpen, setIsIsyeriDropdownOpen] = useState(false)
    const [isWorkAccidentDateDropdownOpen, setIsWorkAccidentDateDropdownOpen] = useState(false)
    const selectedCompanyIds = useSelector(state => state.workAccident.selectedCompanyIds)
    const selectedWorkAccidentDateYears = useSelector(state => state.workAccident.selectedWorkAccidentDateYears)
    const selectedWorkAccidentDateMonths = useSelector(state => state.workAccident.selectedWorkAccidentDateMonths)
    const hasFilters = selectedCompanyIds.length > 0 || selectedWorkAccidentDateYears.length > 0 || selectedWorkAccidentDateMonths.length > 0

    // ** Function to toggle sidebar
    const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
    const toggleHtmlPopup = () => dispatch(ToggleHtmlPopup({show: false, html: null}))
    const toggleVisitHtmlImagePopup = () => dispatch(ToggleVisitHtmlImagePopup({show: false, image: null}))
    const toggleIsyeriDropdown = () => setIsIsyeriDropdownOpen(!isIsyeriDropdownOpen)
    const toggleWorkAccidentDateDropdown = () => setIsWorkAccidentDateDropdownOpen(!isWorkAccidentDateDropdownOpen)

    const getDataParameter = () => {
        return {
            sort,
            sortColumn,
            agentNo: '1',
            q: searchTerm,
            page: currentPage,
            pageSize: rowsPerPage,
            companyGroupId: authStore.selectedCompanyGroup,
            workAccidentType: 1
        }
    }

    // ** Get data on mount
    useEffect(() => {
        dispatch(
            getDataProvision(getDataParameter())
        )
        dispatch(getMailOptions())
    }, [dispatch, store.data.length, authStore.selectedCompanyGroup, sort, sortColumn, rowsPerPage, currentPage, selectedCompanyIds, selectedWorkAccidentDateYears, selectedWorkAccidentDateMonths])

    useEffect(() => {
        if (currentPage > 1) setCurrentPage(1)
         dispatch(getDataProvision(getDataParameter()))
    }, [searchTerm, selectedCompanyIds, selectedWorkAccidentDateYears, selectedWorkAccidentDateMonths])

    // useEffect(() => {
    //     dispatch(
    //         getDataProvision(getDataParameter())
    //     )
    // }, [])

    // ** Function in get data on page change
    const handlePagination = page => {
        setCurrentPage(page.selected + 1)
    }

    // ** Function in get data on rows per page
    const handlePerPage = e => {
        const value = parseInt(e.currentTarget.value)
        // dispatch(
        //     getDataProvision(getDataParameter())
        // )
        setRowsPerPage(value)
    }

    // ** Function in get data on search query change
    const handleFilter = val => {
        setSearchTerm(val)
        // dispatch(
        //     getDataProvision(getDataParameter())
        // )
    }

    // ** Custom Pagination
    const CustomPagination = () => {
        //const count = Number(Math.ceil(store.provisionTotal / rowsPerPage))

        return (
            <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                pageCount={store.provisionTotal || 1}
                activeClassName='active'
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                onPageChange={page => handlePagination(page)}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                nextClassName={'page-item next'}
                previousClassName={'page-item prev'}
                previousLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
            />
        )
    }

    // ** Table data to render
    const dataToRender = () => {
        const filters = {
            q: searchTerm
        }

        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (store.provisionData.length > 0) {
            return store.provisionData
        } else if (store.provisionData.length === 0 && isFiltered) {
            return []
        } else {
            return store.allData.slice(0, rowsPerPage)
        }
    }

    const handleSort = (column, sortDirection) => {
        setSort(sortDirection)
        setSortColumn(column.sortField)
        // dispatch(
        //     getDataProvision(getDataParameter())
        // )
    }

    return (
        <Fragment>
            <Card hidden>
                <CardHeader>
                    <CardTitle tag='h4'>Filtreler</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md='3'>
                            <Label for='role-select'>Grup Adı</Label>
                            <Input name='text' id='username' rows='3' placeholder=''
                                   className='react-select'
                                   onChange={data => {
                                       setSearchTerm(data.target.value)
                                   }}/>
                        </Col>
                        <Col className='my-md-0 my-1' md='3'>
                            <Label for='plan-select'>Firma Adı</Label>
                            <Input name='text' id='username' rows='3' placeholder=''
                                   className='react-select'
                                   onChange={data => {
                                       setSearchTerm(data.target.value)
                                   }}/>
                        </Col>
                        <Col md='3'>
                            <Label for='status-select'>Sicil Numarası</Label>
                            <Input name='text' id='username' rows='3' placeholder=''
                                   className='react-select'
                                   onChange={data => {
                                       setSearchTerm(data.target.value)
                                   }}/>
                        </Col>
                        <Col md='3'>
                            <Label for='status-select'>Vergi Numarası</Label>
                            <Input name='text' id='username' rows='3' placeholder=''
                                   className='react-select'
                                   onChange={data => {
                                       setSearchTerm(data.target.value)
                                   }}/>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card className='overflow-hidden'>
                <div className='react-dataTable'>
                    <DataTable
                        noHeader
                        subHeader
                        sortServer
                        pagination
                        responsive
                        paginationServer
                        columns={columns(isIsyeriDropdownOpen, toggleIsyeriDropdown, isWorkAccidentDateDropdownOpen, toggleWorkAccidentDateDropdown)}
                        onSort={handleSort}
                        sortIcon={<ChevronDown/>}
                        className='react-dataTable'
                        paginationComponent={CustomPagination}
                        data={dataToRender()}
                        subHeaderComponent={
                            <CustomHeader
                                store={store}
                                searchTerm={searchTerm}
                                rowsPerPage={rowsPerPage}
                                handleFilter={handleFilter}
                                handlePerPage={handlePerPage}
                                toggleSidebar={toggleSidebar}
                                dispatch={dispatch}
                                hasFilters={hasFilters}
                                getDataParameter={getDataParameter}
                            />
                        }
                    />
                </div>
            </Card>
            <CreatePopup open={sidebarOpen} toggleSidebar={toggleSidebar}/>
            <HtmlPopup open={store.toggleHtmlPopup?.show} toggleSidebar={toggleHtmlPopup}/>
            <VisitHtmlImagePopup open={store.toggleVisitHtmlImagePopup?.show}
                                 toggleSidebar={toggleVisitHtmlImagePopup}/>
        </Fragment>
    )
}

export default UsersList
