// ** React Imports
import {Fragment, useEffect, useState} from 'react'

// ** Invoice List Sidebar
import Sidebar from './CreatePopup'
import DissmisallPopup from './DissmisallPopup'
import FilePopup from "./FilePopup"


// ** Table Columns
import {columns} from './columns'

// ** Store & Actions
import {useDispatch, useSelector} from 'react-redux'
import {
    getData,
    getGendarmerieCountryData,
    getStaffTypeData,
    togglePopup,
    DissmisalClose,
    setFilePopupOpen
} from './store'

// ** Third Party Components
import DataTable from 'react-data-table-component'
import {ChevronDown, Copy, File, FileText, Grid, Printer, Settings, Share} from 'react-feather'
import ReactPaginate from 'react-paginate'

// ** Reactstrap Imports
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input,
    Label, Nav, NavItem, NavLink, Row, TabPane, UncontrolledDropdown, TabContent
} from 'reactstrap'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import {SyncWorkerList, setAutoSyncOptions, getAutoSyncOptions} from "../Police/store"

// ** Table Header
const CustomHeader = ({
                          toggleSidebar,
                          handlePerPage,
                          rowsPerPage,
                          handleFilter,
                          searchTerm,
                          toggleFilePopup,
                          active,
                          dispatch
                      }) => {
    // ** Converts table to CSV
    const receiveAutomatedWorkerSync = useSelector(state => state.police.receiveAutomatedWorkerSync)
    const handleSyncOptionChange = () => {
        dispatch(setAutoSyncOptions({
            status: !receiveAutomatedWorkerSync,
            type: 1
        }))
    }

    return (
        <div className='invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75'>
            <Row>
                <Col xl='3' className='d-flex align-items-center p-0'>
                    <div className='d-flex align-items-center w-100'>
                        <label htmlFor='rows-per-page'>Toplam</label>
                        <Input
                            className='mx-50'
                            type='select'
                            id='rows-per-page'
                            value={rowsPerPage}
                            onChange={handlePerPage}
                            style={{width: '5rem'}}
                        >
                            <option value='10'>10</option>
                            <option value='25'>25</option>
                            <option value='50'>50</option>
                        </Input>
                        <label htmlFor='rows-per-page'>Kayıt Göster</label>
                    </div>
                </Col>
                <Col
                    xl='8'
                    className='d-flex align-items-sm-center justify-content-xl-end justify-content-start flex-xl-nowrap flex-wrap flex-sm-row flex-column pe-xl-1 p-0 mt-xl-0 mt-1'
                >
                    <div className='d-flex align-items-center mb-sm-0 mb-1 me-1'>
                        <label className='mb-0' htmlFor='search-invoice'>
                            Ara:
                        </label>
                        <Input
                            id='search-invoice'
                            className='ms-50 w-100'
                            type='text'
                            value={searchTerm}
                            onChange={e => handleFilter(e.target.value)}
                        />
                    </div>

                    {active === '1' ? (<div className='d-flex align-items-center table-header-actions'>
                        <UncontrolledDropdown className="me-1">
                            <DropdownToggle color="secondary" caret outline>
                                <Settings className="font-small-4 me-50"/>
                                <span className="align-middle">Otomasyon Ayarları</span>
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem onClick={(e) => {
                                    e.preventDefault()
                                }}>
                                    <div className="d-flex align-items-center">
                                        <Input
                                            type="checkbox"
                                            id="receiveAutomatedWorkerSync"
                                            checked={receiveAutomatedWorkerSync}
                                            onChange={handleSyncOptionChange}
                                        />
                                        <Label
                                            for="receiveAutomatedWorkerSync"
                                            className="mb-0 ms-1"
                                        >
                                            Otomatik Senkronize Et
                                        </Label>
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>
                        <Button className='add-new-user me-1' color='primary'
                                onClick={() => dispatch(SyncWorkerList(1))}>
                            Senkronize Et
                        </Button>
                        <Button className='add-new-user me-1' color='primary' onClick={toggleSidebar}>
                            Giriş bildirimi
                        </Button>
                        <Button className='add-new-user' color='primary' onClick={toggleFilePopup}>
                            Toplu bildirim
                        </Button>
                    </div>) : null}
                </Col>
            </Row>
        </div>
    )
}

const UsersList = () => {
    // ** Store Vars
    const dispatch = useDispatch()
    const store = useSelector(state => state.gendarmerie)
    const authStore = useSelector(state => state.auth)

    // ** States
    const [sort, setSort] = useState('desc')
    const [searchTerm, setSearchTerm] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [sortColumn, setSortColumn] = useState('id')
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [currentRole, setCurrentRole] = useState({value: '', label: 'Select Role'})
    // const [currentPlan, setCurrentPlan] = useState({ value: '', label: 'Select Plan' })
    // const [currentStatus, setCurrentStatus] = useState({ value: '', label: 'Select Status', number: 0 })

    const [active, setActive] = useState('1')
    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }

    // ** Function to toggle sidebar
    const toggleSidebar = () => dispatch(togglePopup(!store.sidebarOpen))
    const toggleFilePopup = () => dispatch(setFilePopupOpen(!store.filePopupOpen))

    const getDataParameter = () => {
        return {
            Sort: sort,
            SortColumn: sortColumn,
            Q: searchTerm,
            Page: currentPage,
            PageSize: rowsPerPage,
            IsActive: active === '1'
        }
    }
    const toggleDissmisallPopup = () => dispatch(DissmisalClose())


    // ** Get data on mount
    useEffect(() => {
        dispatch(
            getData(getDataParameter())
        )
    }, [dispatch, store.data.length, authStore.selectedCompanyGroup, sort, sortColumn, currentPage, active])

    useEffect(() => {
        dispatch(
            getGendarmerieCountryData()
        )
        dispatch(
            getStaffTypeData()
        )
        dispatch(getAutoSyncOptions(1))
    }, [])

    // ** Function in get data on page change
    const handlePagination = page => {
        setCurrentPage(page.selected + 1)
    }

    // ** Function in get data on rows per page
    const handlePerPage = e => {
        const value = parseInt(e.currentTarget.value)
        dispatch(
            getData(getDataParameter())
        )
        setRowsPerPage(value)
    }

    // ** Function in get data on search query change
    const handleFilter = val => {
        setSearchTerm(val)
        dispatch(
            getData(getDataParameter())
        )
    }

    // ** Custom Pagination
    const CustomPagination = () => {
        //const count = Number(Math.ceil(store.total / rowsPerPage))

        return (
            <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                pageCount={store.total || 1}
                activeClassName='active'
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                onPageChange={page => handlePagination(page)}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                nextClassName={'page-item next'}
                previousClassName={'page-item prev'}
                previousLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
            />
        )
    }

    // ** Table data to render
    const dataToRender = () => {
        const filters = {
            role: currentRole.value,
            // currentPlan: currentPlan.value,
            // status: currentStatus.value,
            q: searchTerm
        }

        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (store.data.length > 0) {
            return store.data
        } else if (store.data.length === 0 && isFiltered) {
            return []
        } else {
            return store.allData.slice(0, rowsPerPage)
        }
    }

    const handleSort = (column, sortDirection) => {
        setSort(sortDirection)
        setSortColumn(column.sortField)
        dispatch(
            getData({
                sort,
                sortColumn,
                q: searchTerm,
                page: currentPage,
                perPage: rowsPerPage,
                role: currentRole.value,
                status: currentStatus.value,
                currentPlan: currentPlan.value
            })
        )
    }

    return (
        <Fragment>

            <Nav pills>
                <NavItem>
                    <NavLink
                        active={active === '1'}
                        onClick={() => {
                            toggle('1')
                        }}
                    >
                        Aktif Personel Listesi
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '2'}
                        onClick={() => {
                            toggle('2')
                        }}
                    >
                        Çıkışı Yapılan Personel Listesi
                    </NavLink>
                </NavItem>
            </Nav>

            <TabContent className='py-50' activeTab={active}>
                <TabPane tabId='1'>

                    <Card hidden>
                        <CardHeader>
                            <CardTitle tag='h4'>Filtreler</CardTitle>
                        </CardHeader>
                        <CardBody>
                            <Row>
                                <Col md='3'>
                                    <Label for='role-select'>Kimlik No</Label>
                                    <Input name='text' id='username' rows='3' placeholder=''
                                           className='react-select'
                                           onChange={data => {
                                               setCurrentRole(data)
                                               dispatch(
                                                   getData({
                                                       sort,
                                                       sortColumn,
                                                       q: searchTerm,
                                                       role: data.value,
                                                       page: currentPage,
                                                       perPage: rowsPerPage,
                                                       status: currentStatus.value,
                                                       currentPlan: currentPlan.value
                                                   })
                                               )
                                           }}/>
                                </Col>
                                <Col className='my-md-0 my-1' md='3'>
                                    <Label for='plan-select'>İsim</Label>
                                    <Input name='text' id='username' rows='3' placeholder=''
                                           className='react-select'
                                           onChange={data => {
                                               setCurrentRole(data)
                                               dispatch(
                                                   getData({
                                                       sort,
                                                       sortColumn,
                                                       q: searchTerm,
                                                       role: data.value,
                                                       page: currentPage,
                                                       perPage: rowsPerPage,
                                                       status: currentStatus.value,
                                                       currentPlan: currentPlan.value
                                                   })
                                               )
                                           }}/>
                                </Col>
                                <Col md='3'>
                                    <Label for='status-select'>Soyisim</Label>
                                    <Input name='text' id='username' rows='3' placeholder=''
                                           className='react-select'
                                           onChange={data => {
                                               setCurrentRole(data)
                                               dispatch(
                                                   getData({
                                                       sort,
                                                       sortColumn,
                                                       q: searchTerm,
                                                       role: data.value,
                                                       page: currentPage,
                                                       perPage: rowsPerPage,
                                                       status: currentStatus.value,
                                                       currentPlan: currentPlan.value
                                                   })
                                               )
                                           }}/>
                                </Col>
                                <Col md='3'>
                                    <Label for='status-select'>Giriş Tarihi</Label>
                                    <Input name='text' id='username' rows='3' placeholder=''
                                           className='react-select'
                                           onChange={data => {
                                               setCurrentRole(data)
                                               dispatch(
                                                   getData({
                                                       sort,
                                                       sortColumn,
                                                       q: searchTerm,
                                                       role: data.value,
                                                       page: currentPage,
                                                       perPage: rowsPerPage,
                                                       status: currentStatus.value,
                                                       currentPlan: currentPlan.value
                                                   })
                                               )
                                           }}/>
                                </Col>
                            </Row>
                        </CardBody>
                    </Card>

                    <Card className='overflow-hidden'>
                        <div className='react-dataTable'>
                            <DataTable
                                noHeader
                                subHeader
                                sortServer
                                pagination
                                responsive
                                paginationServer
                                columns={columns}
                                onSort={handleSort}
                                sortIcon={<ChevronDown/>}
                                className='react-dataTable'
                                paginationComponent={CustomPagination}
                                data={dataToRender()}
                                subHeaderComponent={
                                    <CustomHeader
                                        store={store}
                                        searchTerm={searchTerm}
                                        rowsPerPage={rowsPerPage}
                                        handleFilter={handleFilter}
                                        handlePerPage={handlePerPage}
                                        toggleSidebar={toggleSidebar}
                                        toggleFilePopup={toggleFilePopup}
                                        active={active}
                                        dispatch={dispatch}
                                    />
                                }
                            />
                        </div>
                    </Card>

                    <Sidebar open={store.sidebarOpen} toggleSidebar={toggleSidebar}
                             gendarmerieCountryDatas={store.gendarmerieCountryData}
                             staffTypeDatas={store.staffTypeData}/>
                    <DissmisallPopup open={store.dissmisalPopup} toggleDissmisallPopup={toggleDissmisallPopup}/>
                    <FilePopup open={store.filePopupOpen} toggleFilePopup={toggleFilePopup}/>

                </TabPane>
                <TabPane tabId='2'>
                    <Card className='overflow-hidden'>
                        <div className='react-dataTable'>
                            <DataTable
                                noHeader
                                subHeader
                                sortServer
                                pagination
                                responsive
                                paginationServer
                                columns={active === '1' ? columns : columns.filter(x => x.name !== 'İşlemler')}
                                onSort={handleSort}
                                sortIcon={<ChevronDown/>}
                                className='react-dataTable'
                                paginationComponent={CustomPagination}
                                data={dataToRender()}
                                subHeaderComponent={
                                    <CustomHeader
                                        store={store}
                                        searchTerm={searchTerm}
                                        rowsPerPage={rowsPerPage}
                                        handleFilter={handleFilter}
                                        handlePerPage={handlePerPage}
                                        toggleSidebar={toggleSidebar}
                                        toggleFilePopup={toggleFilePopup}
                                        active={active}
                                        dispatch={dispatch}
                                    />
                                }
                            />
                        </div>
                    </Card>
                </TabPane>
            </TabContent>
        </Fragment>
    )
}

export default UsersList
