import {store} from "@store/store"
import {getCompanyDetails} from "../store"
import FilterComponent from "./FilterComponent"

export const columns = (isIsyeriDropdownOpen, toggleIsyeriDropdown, isWorkAccidentDateDropdownOpen, toggleWorkAccidentDateDropdown) => {

return [
    {
        name: <FilterComponent toggleDropdown={toggleIsyeriDropdown} isOpen={isIsyeriDropdownOpen} type={"isyeri"} name={"Kod"}/>,
        sortable: true,
        minWidth: "180px",
        sortField: "CompanyName",
        selector: row => row.companyName,
        cell: row => row.companyName,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },


    {
        name: "İŞYERİ",
        sortable: true,
        minWidth: "180px",
        sortField: "registrationNumber",
        selector: row => row.registrationNumber,
        when: row => row.notStaff === "Evet",
        cell: (row) => <button className="btn btn-danger"
                               onClick={() => store.dispatch(getCompanyDetails({
                                   id: row.companyId,
                                   name: "FirmaDetay1",
                                   text: row.companyName
                               }))}
                               id={row.registrationNumber}>{row.registrationNumber ? row.registrationNumber : ""}</button>,
        button: true,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#fffff"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "KİMLİK NO",
        sortable: true,
        minWidth: "200px",
        sortField: "identificationNo",
        selector: row => row.identificationNo,
        cell: row => row.identificationNo,
        button: true,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#fffff"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "ADI VE SOYADI",
        sortable: true,
        minWidth: "250px",
        sortField: "workerName",
        selector: row => row.workerName,
        cell: row => row.workerName,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: <FilterComponent toggleDropdown={toggleWorkAccidentDateDropdown} isOpen={isWorkAccidentDateDropdownOpen} type={"workAccidentDate"} name={"KAZA TARİHİ"}/>,
        sortable: true,
        minWidth: "180px",
        sortField: "accidentDate",
        selector: row => row.accidentDate,
        cell: row => row.accidentDate,
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "PROVİZYON TARİHİ",
        minWidth: "230px",
        sortable: false,
        sortField: "notificationDate",
        selector: row => row.notificationDate,
        cell: row => row.notificationDate,
        style: {backgroundColor: "inherit", color: "inherit"}
    }
]
}