import { LuFilter } from "react-icons/lu"
import { useDispatch, useSelector } from 'react-redux'
import { setSelectedCompanies, setSelectedPositionReportTypes, setSelectedReportEndDateMonths, setSelectedReportEndDateYears, setSelectedReportStartDateMonths, setSelectedReportStartDateYears } from '../store'
import { Dropdown, DropdownToggle, DropdownMenu, DropdownItem } from 'reactstrap'
import CustomScrollbar from './custom-scrollbar'

const FilterComponent = ({ toggleDropdown, isOpen, type, name }) => {
  const dispatch = useDispatch()
  const companyOptions = useSelector((state) => state.visit.companyOptions)
  const selectedCompanies = useSelector((state) => state.visit.selectedCompanyIds)
  const selectedReportStartDateYears = useSelector((state) => state.visit.selectedReportStartDateYears)
  const selectedReportStartDateMonths = useSelector((state) => state.visit.selectedReportStartDateMonths)
  const selectedReportEndDateYears = useSelector((state) => state.visit.selectedReportEndDateYears)
  const selectedReportEndDateMonths = useSelector((state) => state.visit.selectedReportEndDateMonths)
  const selectedPositionReportTypes = useSelector((state) => state.visit.selectedPositionReportTypes)

  // İşyeri checkbox işlemleri
  const handleCompanyCheckboxChange = (event, companyId) => {
    if (event.target.checked) {
      dispatch(setSelectedCompanies([...selectedCompanies, companyId]))
    } else {
      dispatch(setSelectedCompanies(selectedCompanies.filter((id) => id !== companyId)))
    }
  }

  const handlePositionReportTypeCheckboxChange = (event, positionReportType) => {
    if (event.target.checked) {
      dispatch(setSelectedPositionReportTypes([...selectedPositionReportTypes, positionReportType]))
    } else {
      dispatch(
        setSelectedPositionReportTypes(
          selectedPositionReportTypes.filter((selectedPositionReportType) => selectedPositionReportType !== positionReportType)
        )
      )
    }
  }

  // REPORT START DATE YEAR checkbox işlemleri
  const handleReportStartDateYearCheckboxChange = (event, year) => {
    if (event.target.checked) {
      dispatch(setSelectedReportStartDateYears([...selectedReportStartDateYears, year]))
    } else {
      dispatch(
        setSelectedReportStartDateYears(
          selectedReportStartDateYears.filter((selectedYear) => selectedYear !== year)
        )
      )
    }
  }

  // REPORT START DATE MONTH checkbox işlemleri
  const handleReportStartDateMonthCheckboxChange = (event, monthIndex) => {
    if (event.target.checked) {
      dispatch(setSelectedReportStartDateMonths([...selectedReportStartDateMonths, monthIndex]))
    } else {
      dispatch(
        setSelectedReportStartDateMonths(
          selectedReportStartDateMonths.filter((selectedMonth) => selectedMonth !== monthIndex)
        )
      )
    }
  }

  const handleReportEndDateYearCheckboxChange = (event, year) => {
    if (event.target.checked) {
      dispatch(setSelectedReportEndDateYears([...selectedReportEndDateYears, year]))
    } else {
      dispatch(
        setSelectedReportEndDateYears(
          selectedReportEndDateYears.filter((selectedYear) => selectedYear !== year)
        )
      )
    }
  }

  const handleReportEndDateMonthCheckboxChange = (event, monthIndex) => {
    if (event.target.checked) {
      dispatch(setSelectedReportEndDateMonths([...selectedReportEndDateMonths, monthIndex]))
    } else {
      dispatch(
        setSelectedReportEndDateMonths(
          selectedReportEndDateMonths.filter((selectedMonth) => selectedMonth !== monthIndex)
        )
      )
    }
  }

  // İşyeri checkbox listesini oluşturur
  const renderCompanyCheckboxList = () => {
    return companyOptions.map((company) => {
      const isSelected = selectedCompanies.includes(company.companyId)
      return (
        <div
          key={company.companyId}
          className="d-flex align-items-center"
          style={{
            marginBottom: '8px',
            padding: '4px',
            borderRadius: '4px',
            backgroundColor: isSelected ? '#dcd4fc' : 'transparent' // Mor seçili arka plan
          }}
        >
          <input
            type="checkbox"
            id={`company-${company.companyId}`}
            checked={isSelected}
            onChange={(e) => handleCompanyCheckboxChange(e, company.companyId)}
            style={{
              accentColor: '#724EFF' // Mor checkbox işareti
            }}
          />
          <label
            htmlFor={`company-${company.companyId}`}
            className="ms-2"
            style={{
              color: isSelected ? '#724EFF' : 'inherit' // Seçiliyse yazı rengi mor
            }}
          >
            {company.companyName}
          </label>
        </div>
      )
    })
  }

  const years = [2024, 2023, 2022, 2021, 2020, 2019, 2018, 2017, 2016, 2015, 2014, 2013, 2012, 2011, 2010, 2009, 2008, 2007, 2006, 2005, 2004, 2003, 2002, 2001, 2000]
  const months = [
    'Ocak',
    'Şubat',
    'Mart',
    'Nisan',
    'Mayıs',
    'Haziran',
    'Temmuz',
    'Ağustos',
    'Eylül',
    'Ekim',
    'Kasım',
    'Aralık'
  ]

  // renderReportStartDatePicker Fonksiyonu
  const renderReportStartDatePicker = () => {
    return (
      <div className="d-flex">
        {/* Yıllar Sütunu */}
        <CustomScrollbar sx={{ borderRight: '1px solid #ddd' }}>
          <p className="fw-bold">Yıllar</p>
          {years.map(year => (
            <div key={year} className="d-flex align-items-center mb-2">
              <input
                type="checkbox"
                id={`year-${year}`}
                style={{
                  accentColor: '#724EFF'
                }}
                onChange={(e) => handleReportStartDateYearCheckboxChange(e, year)}
                checked={selectedReportStartDateYears.includes(year)}
              />
              <label htmlFor={`year-${year}`} className="ms-2">
                {year}
              </label>
            </div>
          ))}
        </CustomScrollbar>

        {/* Aylar Sütunu */}
        <CustomScrollbar sx={{ borderRight: '1px solid #ddd' }}>
          <p className="fw-bold">Aylar</p>
          {months.map((month, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
              <input
                type="checkbox"
                id={`month-${index + 1}`}
                style={{
                  accentColor: '#724EFF'
                }}
                onChange={(e) => handleReportStartDateMonthCheckboxChange(e, index + 1)}
                checked={selectedReportStartDateMonths.includes(index + 1)}
              />
              <label htmlFor={`month-${index + 1}`} className="ms-2">
                {month}
              </label>
            </div>
          ))}
        </CustomScrollbar>
      </div>
    )
  }

  // renderReportStartDatePicker Fonksiyonu
  const renderReportEndDatePicker = () => {
    return (
      <div className="d-flex">
        {/* Yıllar Sütunu */}
        <CustomScrollbar sx={{ borderRight: '1px solid #ddd' }}>
          <p className="fw-bold">Yıllar</p>
          {years.map((year) => (
            <div key={year} className="d-flex align-items-center mb-2">
              <input
                type="checkbox"
                id={`year-${year}`}
                checked={selectedReportEndDateYears.includes(year)}
                onChange={(e) => handleReportEndDateYearCheckboxChange(e, year)}
                style={{
                  accentColor: '#724EFF' // Mor checkbox işareti
                }}
              />
              <label htmlFor={`year-${year}`} className="ms-2">
                {year}
              </label>
            </div>
          ))}
        </CustomScrollbar>

        {/* Aylar Sütunu */}
        <CustomScrollbar sx={{ borderRight: '1px solid #ddd' }}>
          <p className="fw-bold">Aylar</p>
          {months.map((month, index) => (
            <div key={index} className="d-flex align-items-center mb-2">
              <input
                type="checkbox"
                id={`month-${index + 1}`}
                checked={selectedReportEndDateMonths.includes(index + 1)}
                onChange={(e) => handleReportEndDateMonthCheckboxChange(e, index + 1)}
                style={{
                  accentColor: '#724EFF' // Mor checkbox işareti
                }}
              />
              <label htmlFor={`month-${index + 1}`} className="ms-2">
                {month}
              </label>
            </div>
          ))}
        </CustomScrollbar>
      </div>
    )
  }

  const renderPositionReportTypePicker = () => {
    return (
      <div className="d-flex flex-column">
        <div className="d-flex align-items-center mb-2">
          <input
            type="checkbox"
            id={`positionReportType-0`}
            style={{
              accentColor: '#724EFF'
            }}
            checked={selectedPositionReportTypes.includes(0)}
            onChange={(e) => handlePositionReportTypeCheckboxChange(e, 0)}
          />
          <label htmlFor={`positionReportType-0`} className="ms-2">
            Ayakta
          </label>
        </div>

        <div className="d-flex align-items-center mb-2">
          <input
            type="checkbox"
            id={`positionReportType-1`}
            style={{
              accentColor: '#724EFF'
            }}
            checked={selectedPositionReportTypes.includes(1)}
            onChange={(e) => handlePositionReportTypeCheckboxChange(e, 1)}
          />
          <label htmlFor={`positionReportType-1`} className="ms-2">
            Yatarak
          </label>
        </div>
      </div>
    )
  }

  // Dropdown içeriği
  const renderDropdownContent = () => {
    switch (type) {
      case 'isyeri':
        return (
          <div className="px-1" style={{ maxHeight: '200px', overflowY: 'auto' }}>
            <p>İşyeri Seçiniz</p>
            {renderCompanyCheckboxList()}
          </div>
        )
      case 'reportStartDate':
        return (
          <div className="px-1" style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <p>Yıl - Ay Seçiniz</p>
            {renderReportStartDatePicker()}
          </div>
        )
      case 'reportEndDate':
        return (
          <div className="px-1" style={{ maxHeight: '400px', overflowY: 'auto' }}>
            <p>Yıl - Ay Seçiniz</p>
            {renderReportEndDatePicker()}
          </div>
        )
      case 'positionReportType':
        return (
          <div className="px-1" style={{ maxHeight: '200px', overflowY: 'auto' }}>
            <p>Tip Seçiniz</p>
            {renderPositionReportTypePicker()}
          </div>
        )
      default:
        return <DropdownItem>Varsayılan Filtre</DropdownItem>
    }
  }

  return (
    <div className="d-flex align-items-center">
      <span>{name}</span>
      <Dropdown isOpen={isOpen} toggle={toggleDropdown} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
        <DropdownToggle color="outline" className="btn-icon">
          <LuFilter size={14} />
        </DropdownToggle>
          <DropdownMenu container="body" style={{ maxHeight: '300px', overflowY: 'auto' }} onClick={(e) => { e.preventDefault(); e.stopPropagation() }}>
          {renderDropdownContent()}
        </DropdownMenu>
      </Dropdown>
    </div>
  )
}

export default FilterComponent
