// ** React Import
import {useEffect, useState} from "react"

// ** Third Party Components
import {Controller} from 'react-hook-form'
import Select from 'react-select'

// ** Reactstrap Imports
import {Button, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row} from 'reactstrap'

// ** Store & Actions
import '@styles/react/libs/flatpickr/flatpickr.scss'
import {useDispatch, useSelector} from 'react-redux'
import {getCompanyPassword, showLoginPopup} from '../store'


const LoginPopup = () => {
    // ** States
    const [currentPage, setCurrentPage] = useState(4)
    const [refreshCount, setRefreshCount] = useState(0)
    const [htmlContent, setHtmlContent] = useState('')

    // ** Store Vars
    const dispatch = useDispatch()
    const store = useSelector(state => state.company)
    // ** Vars

    const getEmployerSystem = () => {
        const timestamp = new Date().getTime()
        const str = `<form id="kullaniciIlkKontrollerGiris" target="_blank" name="kullaniciIlkKontrollerGiris" action="https://uyg.sgk.gov.tr/IsverenSistemi/login/kullaniciIlkKontrollerGiris.action" method="post">
                    <h1>İşveren Sistemi Kullanıcı Girişi</h1>
                    <br>
                        <table>
                            <tr>
                                <td style="width: 35% !important; display: none"><p>Kullanıcı Adı ${refreshCount}</p></td>
                                <td style="width: 65% !important;display: none"><input type="text" name="username" size="11" maxLength="11" value="${store.companyPassword?.username}" id="kullaniciIlkKontrollerGiris_username" style="width:161px;display:inline;" autoComplete="off"/>-<input type="text" name="isyeri_kod" size="4" maxLength="4" value="${store.companyPassword?.suffix}" id="kullaniciIlkKontrollerGiris_isyeri_kod" style="width:50px;display:inline;" autoComplete="off"/></td>
                            </tr>
                            <tr>
                                <td style="width: 35% !important;display: none"><p>Sistem Şifresi</p></td>
                                <td style="width: 65% !important;display: none"><input type="password" name="password" maxLength="10" id="kullaniciIlkKontrollerGiris_password" value="${store.companyPassword?.systemPassword}" style="width:238px;" autoComplete="off"/></td>
                            </tr>
                            <tr>
                                <td style="width: 35% !important;display: none"><p>İşyeri Şifresi</p></td>
                                <td style="width: 65% !important;display: none"><input type="password" name="isyeri_sifre" maxLength="10" id="kullaniciIlkKontrollerGiris_isyeri_sifre"value="${store.companyPassword?.companyPassword}" style="width:238px;" autoComplete="off"/></td>
                            </tr>

                            <tr>
                                <td style="display: none" colSpan="2"><p>Lütfen Güvenlik Anahtarını yanında bulunan kutuya yazıp GİRİŞ tuşuna basınız.</p></td>
                            </tr>
                            <tr align="center">
                                <td colSpan="2">
                                    <table>
                                        <tr>
                                            <td align="right" style="width: 45%;"><img id="guvenlik_kod" src="https://uyg.sgk.gov.tr/IsverenSistemi/PG?${timestamp}" /></td>
                                            <td align="left"><input type="text" name="isyeri_guvenlik" placeholder="Güvenlik Kodu" class="react-select form-control" maxLength="10" value="" id="kullaniciIlkKontrollerGiris_isyeri_guvenlik" style="width: 100%;"/></td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <button style="width: 100%;" class="btn btn-outline-primary">Giriş Yap</button></form>`
        return str
    }

    const getEDeclaration = () => {
        const timestamp = new Date().getTime()
        const str = `<form id="kullaniciIlkKontrollerGiris" target="_blank" name="kullaniciIlkKontrollerGiris" action="https://ebildirge.sgk.gov.tr/EBildirgeV2/login/kullaniciIlkKontrollerGiris.action" method="post">
                    <h1>E-BildirgeV2 Kullanıcı Girişi</h1>
                    <br>
                        <table>
                            <tr>
                                <td style="width: 35% !important; display: none"><p>Kullanıcı Adı ${refreshCount}</p></td>
                                <td style="width: 65% !important;display: none"><input type="text" name="username" size="11" maxLength="11" value="${store.companyPassword?.username}" id="kullaniciIlkKontrollerGiris_username" style="width:161px;display:inline;" autoComplete="off"/>-<input type="text" name="isyeri_kod" size="4" maxLength="4" value="${store.companyPassword?.suffix}" id="kullaniciIlkKontrollerGiris_isyeri_kod" style="width:50px;display:inline;" autoComplete="off"/></td>
                            </tr>
                            <tr>
                                <td style="width: 35% !important;display: none"><p>Sistem Şifresi</p></td>
                                <td style="width: 65% !important;display: none"><input type="password" name="password" maxLength="10" id="kullaniciIlkKontrollerGiris_password" value="${store.companyPassword?.systemPassword}" style="width:238px;" autoComplete="off"/></td>
                            </tr>
                            <tr>
                                <td style="width: 35% !important;display: none"><p>İşyeri Şifresi</p></td>
                                <td style="width: 65% !important;display: none"><input type="password" name="isyeri_sifre" maxLength="10" id="kullaniciIlkKontrollerGiris_isyeri_sifre"value="${store.companyPassword?.companyPassword}" style="width:238px;" autoComplete="off"/></td>
                            </tr>

                            <tr>
                                <td style="display: none" colSpan="2"><p>Lütfen Güvenlik Anahtarını yanında bulunan kutuya yazıp GİRİŞ tuşuna basınız.</p></td>
                            </tr>
                            <tr align="center">
                                <td colSpan="2">
                                    <table>
                                        <tr>
                                        <input type="hidden" value="${timestamp}"/>
                                            <td align="right" style="width: 45%;"><img id="guvenlik_kod" src="https://ebildirge.sgk.gov.tr/EBildirgeV2/PG?${timestamp}" /></td>
                                            <td align="left"><input type="text" name="isyeri_guvenlik" placeholder="Güvenlik Kodu" class="react-select form-control" maxLength="10" value="" id="kullaniciIlkKontrollerGiris_isyeri_guvenlik" style="width: 100%;"/></td>
                                        </tr>
                                    </table>
                                </td>
                            </tr>
                        </table>
                        <button style="width: 100%;" class="btn btn-outline-primary">Giriş Yap</button></form>`
        return str
    }

    const getDisabilityPromotion = () => {
        const timestamp = new Date().getTime()
        const str = `<form id="kullaniciIlkKontrollerGiris" target="_blank" action="https://uyg.sgk.gov.tr/Sigortali_Tesvik_4a/ActionMultiplexer?aid=LoginControl" method="post"
name="form">
<table style="width: 100%;">
<tr >
<td>
 <input type="hidden" value="${timestamp}"/>
<div style="display: none; color: White; font-size: large;">Sisteme Giriş</div>
</td>
</tr>
<tr valign="middle">
<td>
<table style="width: 100%;">
<tr>
<td style="display: none;" align="center" rowspan="6"><img src="https://uyg.sgk.gov.tr/Sigortali_Tesvik_4a/resimler/login.png"
alt="Sisteme Giriş" /></td>
</tr>
<tr style="display: none;">
<td align="right">Kullanıcı Adı :</td>

<!--  onkeydown="karakterfiltre1(this);" onkeyup="karakterfiltre(this);" -->
<td align="left"><input name=j_username type="text"
maxlength="11"  class="Textboxtc" value="${store.companyPassword?.username}"
onfocus="this.className='Textboxtcfocus';"
onblur="this.className='Textboxtc';" style="width: 100px;"
AUTOCOMPLETE="off">-<input name="isyeri_kod" type="text"
 class="Textboxtc" maxlength="4" value="${store.companyPassword?.suffix}"
onfocus="this.className='Textboxtcfocus';"
onblur="this.className='Textboxtc';" style="width: 28px;"
AUTOCOMPLETE="off"></td>
</tr>
<tr style="display: none;">
<td align="right">Sistem Şifresi :</td>
<td align="left"><input name="j_password" type="password" value="${store.companyPassword?.systemPassword}"
class="keyboardInput" onfocus="this.className='Textboxtcfocus';"
onblur="this.className='Textboxtc';" border="0" value="56VpqV51"
style="width: 100px;" AUTOCOMPLETE="off"></td>
</tr>
<tr style="display: none;">
<td align="right">İşyeri Şifresi :</td>
<td align="left"><input name="isyeri_sifre" type="password" value="${store.companyPassword?.companyPassword}"
class="keyboardInput" onfocus="this.className='Textboxtcfocus';"
onblur="this.className='Textboxtc';" border="0" value="53OmdW51"
style="width: 100px;" AUTOCOMPLETE="off"></td>
</tr>

<tr>
<td>
<img src="https://uyg.sgk.gov.tr/Sigortali_Tesvik_4a/captcha?${timestamp}"><br />
</td>
<td>
<input class="react-select form-control" placeholder="Güvenlik Kodu" name="captcha_image" type="text"></td>
</tr>




<tr>
<td colspan="2" align="left"><input type="submit" name="Submit"
value="Giriş Yap" style="width: 100%;"
class="btn btn-outline-primary"></td>
</tr>

<tr>
<td align="center" colspan="3"><font color="red"
style="font-weight: bold;"></font></td>
</tr>
</table>
</td>
</tr>
<tr>
<td
style="background: #FAFAFA bottom no-repeat; height: 5px;">

</td>
</tr>
</table>
</form>`
        return str
    }

    const getEDeclarationV1 = () => {
        const timestamp = new Date().getTime()
        const str = `<form name=formA id="kullaniciIlkKontrollerGiris" target="_blank" method="post" action="https://ebildirge.sgk.gov.tr/WPEB/amp/loginldap">
                            <table style="width: 100%">
                                <tr style="display: none;" align=right>
                                    <td nowrap>Kullanıcı Adı</td>
                                    <td><input type=text name="j_username" maxlength="11" value="${store.companyPassword?.username}" autocomplete="off"></td>
                                    <td nowrap>-</td>
                                    <td><input type=text name="isyeri_kod" maxlength="4" size="5"  value="${store.companyPassword?.suffix}"
                                            onkeypress="javascript:return preventAlphabetic(event);"
                                            onchange="javascript:return checkNumeric(this);" autocomplete="off">
                                            </td>
                                </tr>
                                <tr style="display: none;" align=right>
                                    <td>Sistem Şifresi</td>
                                    <td><input type=password name="j_password" maxlength="10"  value="${store.companyPassword?.systemPassword}" autocomplete="off"
                                            onkeypress="checkKarakterSayisi(this);"></td>
                                    <td colspan="2" rowspan="2">&nbsp</td>
                                </tr>
                                <tr style="display: none;" align=right>
                                    <td>İşyeri Şifresi</td>
                                    <td><input type=password name="isyeri_sifre" maxlength="10"  value="${store.companyPassword?.companyPassword}" autocomplete="off"
                                            onkeypress="checkKarakterSayisi(this);"></td>

                                </tr>
                                <tr align=center>
                                    <td colspan=4 align=center>
                                        <table cellpadding=0 cellspacing=0 width=100%>
                                            <tr style="display: none;" align=center>
                                                <td colspan=5><u>
                                                        Lütfen <b>Güvenlik Anahtarını</b> yanında bulunan kutuya yazıp GİRİŞ tuşuna basınız.</u>
                                                </td>
                                            </tr>
                                            <tr align="center">
                                                <td><img src="https://ebildirge.sgk.gov.tr/WPEB/PG?${timestamp}"></td>
                                                <td><input class="react-select form-control" placeholder="Güvenlik Kodu" type=text name="isyeri_guvenlik" id="isyeri_guvenlik" maxlength="4"
                                                        autocomplete="off"></td>
                                            </tr>
                                            <tr>
                                                 <td style="display: none;" width="5" align="right"><img
                                                                    src="https://ebildirge.sgk.gov.tr/WPEB/image/interface/buttonFLeft.gif">
                                                            </td>
                                                            <td colspan="4">
                                                                <input class="btn btn-outline-primary" style="width: 100%; margin-top: 2%;" type="submit" id="buttonOK" name="btnSubmit"
                                                                    width="100%" value="Giriş Yap">
                                                            </td>
                                            </tr>
                                        </table>
                                    </td>
                                </tr>
                            </table>
                        </form>`
        return str
    }

    const removeElementById = (id) => {
        const element = document.getElementById(id)
        if (element && element.parentNode) {
            element.parentNode.removeChild(element)
        }
    }

    const getHtml = () => {
        return (<>
            <div dangerouslySetInnerHTML={{__html: htmlContent}} key={refreshCount}/>
            <Button style={{marginTop: 5, width: "100%"}} color={"warning"}
                    onClick={() => setRefreshCount(prevCount => prevCount + 1)}>Yenile</Button>
        </>)
    }

    useEffect(() => {
        if (currentPage === 1) {
            removeElementById("kullaniciIlkKontrollerGiris")
            setHtmlContent(getEmployerSystem())
        }
        if (currentPage === 2) {
            removeElementById("kullaniciIlkKontrollerGiris")
            setHtmlContent(getEDeclaration())
        }
        if (currentPage === 3) {
            removeElementById("kullaniciIlkKontrollerGiris")
            setHtmlContent(getDisabilityPromotion())
        }
        if (currentPage === 4) {
            removeElementById("kullaniciIlkKontrollerGiris")
            setHtmlContent(getEDeclarationV1())
        }
    }, [refreshCount, currentPage])

    useEffect(() => {
        dispatch(getCompanyPassword())
    }, [store.loginPopupCompanyId])

    const popupClose = () => {
        dispatch(showLoginPopup({id: 0, show: false}))
    }

    useEffect(() => {
        setRefreshCount(prevCount => prevCount + 1)
    }, [store.companyPassword])

    return (
        <>
            <Modal isOpen={store.loginPopupShow} toggle={popupClose} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={popupClose}></ModalHeader>
                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>SGK</h1>
                        {
                            currentPage === 1 ? <p>İŞVEREN SİSTEMİ Ekranlarına giriş yap</p> : currentPage === 2 ? <>
                                <p>E-BİLDİRGE V2 Ekranlarına giriş yap</p></> : currentPage === 3 ? <>
                                <p>ENGELLİ TEŞVİK TANIMI Ekranlarına giriş yap</p></> : currentPage === 4 ? <>
                                <p>E-BİLDİRGE V1 Ekranlarına giriş yap</p></> : null
                        }
                    </div>
                    <Row>
                        <Col md={"4"}>
                            {/* <Row>
                                <Button style={{marginBottom: 5}} onClick={() => setCurrentPage(1)}>İŞVEREN
                                    SİSTEMİ</Button>
                            </Row>
                            <Row>
                                <Button style={{marginBottom: 5}} onClick={() => setCurrentPage(2)}>E-BİLDİRGE
                                    V2</Button>
                            </Row>
                            <Row>
                                <Button style={{marginBottom: 5}} onClick={() => setCurrentPage(3)}>ENGELLİ TEŞVİK
                                    TANIMI</Button>
                            </Row> */}
                            <Row>
                                <Button style={{marginBottom: 5}} onClick={() => setCurrentPage(4)}>E-BİLDİRGE
                                    V1</Button>
                            </Row>
                        </Col>
                        <Col>
                            {getHtml()}
                        </Col>
                    </Row>

                </ModalBody>
            </Modal>
        </>
    )
}

export default LoginPopup
