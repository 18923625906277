// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import { createTab } from '../../../../redux/tabManagement'
import toast from 'react-hot-toast'

export const getQueueMetricsData = createAsyncThunk('queue/getQueueMetricsData', async (params) => {
  // const startDate = params.startDate ? params.startDate : new Date().toISOString().split('T')[0]
  const url = `/api/QueueMetric/?startDate=${params.startDate}&queueName=${params.queueName}&includeErrorQueues=${params.includeErrorQueues}&endDate=${params.endDate}`
  const response = await axios.get(url)
  return response
})

export const getQueueCompanyStatusData = createAsyncThunk('queue/getQueueCompanyStatusData', async (params) => {
  const url = `/api/QueueMetric/progress/?schedulerId=${params.schedulerId}`
  const response = await axios.get(url)
  return response
})


export const showQueueCompanyStatusData = createAsyncThunk("queue/showQueueCompanyStatusData", (data, {dispatch}) => {
  console.log("datafromredux", data)
  dispatch(createTab(data))
})

export const handleRepublishSubScheduler = createAsyncThunk("queue/handleRepublishSubScheduler", async (data) => {
  const url = `/api/Queue/RepublishSubSchedulerMessage`
  const response = await axios.post(url, {subSchedulerId: data})
  console.log(response.data)
  return response.data
})

export const queueSlice = createSlice({
    name: 'queue',
    initialState: {
      queueMetricsDataLoading: false,
      queueMetricsData: [],
      queueCompanyStatusDataLoading:false,
      queueCompanyStatusData: []
    },
    reducers: {},
    extraReducers: builder => {
      builder
        .addCase(getQueueMetricsData.pending, (state) => {
          state.queueMetricsDataLoading = true
        })
        .addCase(getQueueMetricsData.fulfilled, (state, action) => {
          state.queueMetricsData = action.payload.data
          state.queueMetricsDataLoading = false
        })
        .addCase(getQueueCompanyStatusData.pending, (state) => {
          state.queueCompanyStatusDataLoading = true
        })
        .addCase(getQueueCompanyStatusData.fulfilled, (state, action) => {
          state.queueCompanyStatusData = action.payload.data
          state.queueCompanyStatusDataLoading = false
        })
        .addCase(handleRepublishSubScheduler.fulfilled, (state, action) => {
          if (action.payload.isSuccessfullyRepublished === true) {
            toast.success("Başarıyla Tekrardan Kuyruğa Eklendi")
          } else if (action.payload.isSuccessfullyRepublished === false) {
            toast.error("Tekrar Kuyruğa Eklenemedi")
          }
        })
    }
  })

  export default queueSlice.reducer
