/* eslint-disable no-unused-vars */
// ** React Imports
import {Fragment, useEffect, useState} from "react"
// ** Table Columns
import {columns} from "./columns"
// ** Store & Actions
import {useDispatch, useSelector} from "react-redux"
import {
    getData,
    sendApprovedVisits,
    toggleManualVisitDeclarationPopupState,
    exportExcel,
    ToggleVisitHtmlImagePopup,
    setVisitList,
    approveVisitListClear,
    approveCountPlus,
    setApproveSelectedList,
    setVisitsAutoApproval,
    getMailOptions,
    setMailOptions,
    getCompanyOptions,
    clearFilters,
    exportUnapprovedZip,
    exportVisitsZip,
    getReportEndDayArrivedMailOptions,
    setReportEndDayArrivedMailOptions
} from "../store"
// ** Third Party Components
import DataTable from "react-data-table-component"
import {ChevronDown, Copy, File, FileText, Grid, Printer, Share, Settings, Archive, Lock, Unlock, Columns} from "react-feather"
import Flatpickr from "react-flatpickr"
import ReactPaginate from "react-paginate"
import ManualVisitDeclarationPopup from "../view/ManualVisitDeclarationPopup"
import VisitDetailPopup from "../view/VisitDetailPopup"
import VisitHtmlImagePopup from "../view/VisitHtmlImagePopup"
import ApprovedVisitList from "../../visit-approved/list/Table"
import ArchiveVisitList from "../list/ArchiveTable"
// ** Reactstrap Imports
import {
    Button, Card, Col, DropdownItem, DropdownMenu, DropdownToggle, Input,
    TabContent,
    TabPane,
    Label,
    Nav, NavItem, NavLink, Row, UncontrolledDropdown,
    Spinner
} from "reactstrap"

import Select from "react-select"

// ** Styles
import "@styles/react/libs/react-select/_react-select.scss"
import "@styles/react/libs/tables/react-dataTable-component.scss"
import "./column-fixer-styles.css"
import AutoApproveModal from "./AutoApproveModal"
import { LuFilterX } from "react-icons/lu"
import { FaRegFileZipper } from "react-icons/fa6"

// ** Table Header
const STORAGE_KEY = 'visit-list-columns'

const ColumnSelectionMenu = ({ columns, handleColumnChange, resetColumns }) => {
    const getColumnName = (col) => {
        if (typeof col.name === 'object' && col.name.props) {
            return col.name.props.name || 'Unknown'
        }
        return col.name
    }

    // Filter out the first 5 columns from the menu
    const selectableColumns = columns.slice(5)

    return (
        <UncontrolledDropdown className='column-selection-dropdown me-1'>
            <DropdownToggle color='secondary' outline className='btn-icon d-flex align-items-center' style={{marginLeft: 10, backgroundColor: 'transparent', height: "38px"}}>
                <Columns className='font-small-4 me-50' />
                <span>Kolonlar</span>
            </DropdownToggle>
            <DropdownMenu container="body" style={{
                minWidth: '250px',
                maxHeight: '400px',
                overflowY: 'auto'
            }}>
                <div className="px-2 py-1">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <h6 className="m-0">Görünür Kolonlar</h6>
                        <Button.Ripple
                            color="flat-secondary"
                            size="sm"
                            onClick={resetColumns}
                        >
                            Varsayılana Dön
                        </Button.Ripple>
                    </div>
                    {selectableColumns.map((col, index) => (
                        <div key={index} className="d-flex align-items-center py-1">
                            <Input
                                type="checkbox"
                                checked={!col.omit}
                                onChange={() => {
                                    const newColumns = [...columns]
                                    // Add 5 to index since we sliced off the first 5 columns
                                    newColumns[index + 5] = { ...col, omit: !col.omit }
                                    handleColumnChange(newColumns)
                                }}
                            />
                            <span className="ms-1">{getColumnName(col)}</span>
                        </div>
                    ))}
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

const CustomHeader = ({
                          store,
                          handlePerPage,
                          rowsPerPage,
                          handleFilter,
                          searchTerm,
                          selectedRows,
                          dispatch,
                          currentPage,
                          setApproveVisitChange,
                          hasFilters,
                          isColumnsFixed,
                          toggleColumnsFixed,
                          columns,
                          handleColumnChange,
                          resetColumns
                      }) => {
    const visitsAutoApproval = useSelector(state => state.visit.visitsAutoApproval)
    const isExportZipLoading = useSelector(state => state.visit.isExportZipLoading)
    const isExportExcelLoading = useSelector(state => state.visit.isExportExcelLoading)
    const [showFixedColumnsButton, setShowFixedColumnsButton] = useState(true)
    const [isModalOpen, setIsModalOpen] = useState(false)
    const user = JSON.parse(localStorage.getItem("userData"))
    const receiveAutomatedUnapprovedVisitMails = useSelector(state => state.visit.receiveAutomatedUnapprovedVisitMails)
    const receiveAutomatedReportEndDateArrivedVisitMails = useSelector(state => state.visit.receiveAutomatedReportEndDateArrivedVisitMails)

    useEffect(() => {
        const handleResize = () => {
            setShowFixedColumnsButton(window.innerWidth >= 992)
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const toggleModal = () => setIsModalOpen(!isModalOpen)

    const handleAutoApproveChange = (e) => {
        if (e.target.checked) {
            setIsModalOpen(true)
        } else {
            dispatch(setVisitsAutoApproval(false))
        }
    }

    const handleConfirmAutoApprove = () => {
        setIsModalOpen(false)
        dispatch(setVisitsAutoApproval(true))
    }

    const handleClearFilters = () => {
        dispatch(clearFilters())
        handleFilter("")
    }

    const handleUnapprovedVisitMailOptionChange = () => {
        dispatch(setMailOptions(!receiveAutomatedUnapprovedVisitMails))
    }

    const handleReportEndDateArrivedMailOptionChange = () => {
        dispatch(setReportEndDayArrivedMailOptions(!receiveAutomatedReportEndDateArrivedVisitMails))
    }

    return (
        <div className="invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75">
            <Row>
                <Col xl="4" className="d-flex align-items-center p-0">
                    <div className='d-flex flex-wrap align-items-center w-100'>
                        <div className='d-flex align-items-center mb-sm-0 mb-1'>
                            <label htmlFor="rows-per-page">Toplam</label>
                            <Input
                                className="mx-50"
                                type="select"
                                id="rows-per-page"
                                value={rowsPerPage}
                                onChange={handlePerPage}
                                style={{width: "5rem", height: "38px"}}
                            >
                                <option value="10">10</option>
                                <option value="25">25</option>
                                <option value="50">50</option>
                            </Input>
                            <label htmlFor="rows-per-page">Kayıt Göster</label>
                        </div>
                        {showFixedColumnsButton && (
                            <Button
                                className='btn-icon ms-2 fixed-columns-toggle'
                                color='flat-dark'
                                onClick={toggleColumnsFixed}
                                style={{height: "38px"}}
                            >
                                {isColumnsFixed ? <Lock size={16}/> : <Unlock size={16}/>}
                                <span className='ms-1'>{isColumnsFixed ? 'Sütunları Serbest Bırak' : 'Sütunları Sabitle'}</span>
                            </Button>
                        )}
                    </div>
                </Col>

                <Col
                    xl="8"
                    className="d-flex flex-wrap align-items-sm-center justify-content-xl-end justify-content-start pe-xl-1 p-0 mt-xl-0 mt-1"
                >
                    <div className='d-flex flex-wrap align-items-center gap-1' style={{ minWidth: 'auto' }}>
                        <div className='d-flex align-items-center mb-sm-0 mb-1' style={{ minWidth: '180px' }}>
                            <label className="mb-0" htmlFor="search-invoice">
                                Ara:
                            </label>
                            <Input
                                id="search-invoice"
                                className="ms-50"
                                type="text"
                                value={searchTerm}
                                onChange={e => handleFilter(e.target.value)}
                                style={{minWidth: '130px', height: "38px"}}
                            />
                        </div>

                        {hasFilters && (
                            <Button
                                className='btn-icon btn-gray d-flex align-items-center'
                                style={{ backgroundColor: 'transparent', width: "130px", height: "38px"}}
                                outline
                                onClick={handleClearFilters}
                            >
                                <LuFilterX size={16} className={"me-50"}/>
                                <span>Filtreleri Kaldır</span>
                            </Button>
                        )}

                        <ColumnSelectionMenu
                            columns={columns}
                            handleColumnChange={handleColumnChange}
                            resetColumns={resetColumns}
                        />

                        <Button
                            onClick={() => dispatch(exportExcel(store.data))}
                            className='btn-icon btn-gray d-flex align-items-center'
                            outline
                            disabled={isExportExcelLoading}
                            style={{ width: "110px", height: "38px", justifyContent: "center" }}
                        >
                            {isExportExcelLoading ? (
                                <Spinner size="sm" color="primary" className={"me-50"} />
                            ) : (
                                <Grid className='font-small-4 me-50' />
                            )}
                            {isExportExcelLoading ? 'Hazırlanıyor...' : 'Excele Aktar'}
                        </Button>

                        <Button
                            onClick={() => dispatch(exportVisitsZip({data:store.data, visitType: 1}))}
                            className='btn-icon btn-gray d-flex align-items-center'
                            outline
                            disabled={isExportZipLoading}
                            style={{ width: "100px", height: "38px", justifyContent: "center" }}
                        >
                            {isExportZipLoading ? (
                                <Spinner size="sm" color="primary" className={"me-50"} />
                            ) : (
                                <FaRegFileZipper className='font-small-4 me-50' />
                            )}
                            {isExportZipLoading ? 'Hazırlanıyor...' : 'Zipe Aktar'}
                        </Button>

                        <UncontrolledDropdown>
                            <DropdownToggle color="secondary" caret outline style={{ width: "160px", height: "38px", whiteSpace: "nowrap", display: "flex", justifyContent: "center", alignItems: "center" }}>
                                <Settings className="font-small-4 me-50"/>
                                <span className="align-middle text-wrap">Otomasyon Ayarları</span>
                            </DropdownToggle>
                            <DropdownMenu end>
                                <DropdownItem onClick={(e) => { e.preventDefault() }}>
                                    <div className="d-flex align-items-center">
                                        <Input
                                            type="checkbox"
                                            id="auto-approve"
                                            checked={visitsAutoApproval}
                                            onChange={handleAutoApproveChange}
                                        />
                                        <Label
                                            for="auto-approve"
                                            className="mb-0 ms-1"
                                        >
                                            Otomatik Onaylama
                                        </Label>
                                    </div>
                                </DropdownItem>

                                <DropdownItem onClick={(e) => { e.preventDefault() }}>
                                    <div className="d-flex align-items-center">
                                        <Input
                                            type="checkbox"
                                            id="receiveAutomatedUnapprovedVisitMails"
                                            checked={receiveAutomatedUnapprovedVisitMails}
                                            onChange={handleUnapprovedVisitMailOptionChange}
                                        />
                                        <Label
                                            for="auto-approve"
                                            className="mb-0 ms-1"
                                        >
                                            Yeni Eklenen Onaylanmamış Vizite Mail Bildirimi
                                        </Label>
                                    </div>
                                </DropdownItem>

                                <DropdownItem onClick={(e) => { e.preventDefault() }}>
                                    <div className="d-flex align-items-center">
                                        <Input
                                            type="checkbox"
                                            id="receiveAutomatedReportEndDateArrivedVisitMails"
                                            checked={receiveAutomatedReportEndDateArrivedVisitMails}
                                            onChange={handleReportEndDateArrivedMailOptionChange}
                                        />
                                        <Label
                                            for="auto-approve"
                                            className="mb-0 ms-1"
                                        >
                                            Rapor Bitiş Tarihi Geldi Mail Bildirimi
                                        </Label>
                                    </div>
                                </DropdownItem>
                            </DropdownMenu>
                        </UncontrolledDropdown>

                        <Button
                            style={{width: "120px", height: "38px", display: "flex", justifyContent: "center", alignItems: "center"}}
                            className="add-new-user"
                            color="primary"
                            onClick={() => dispatch(toggleManualVisitDeclarationPopupState(true))}
                        >
                            Manuel Bildirim
                        </Button>

                        <Button
                            style={{width: "90px", height: "38px", display: "flex", justifyContent: "center", alignItems: "center"}}
                            className="add-new-user"
                            color="success"
                            onClick={() => dispatch(sendApprovedVisits(true))}
                        >
                            Onayla
                        </Button>
                    </div>
                </Col>
            </Row>
            <Row>
                {selectedRows.length > 0 &&
                    <SelectVisits
                        dataSource={selectedRows}
                        currentPage={currentPage}
                        searchTerm={searchTerm}
                        setApproveVisitChange={setApproveVisitChange}
                    />
                }
            </Row>
            <AutoApproveModal
                isOpen={isModalOpen}
                toggle={toggleModal}
                onConfirm={handleConfirmAutoApprove}
            />
        </div>
    )
}

const UsersList = () => {
    // ** Store Vars
    const dispatch = useDispatch()
    const store = useSelector(state => state.visit)
    const authStore = useSelector(state => state.auth)


    // ** States
    const [active, setActive] = useState('1')
    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }
    const [sort, setSort] = useState("desc")
    const [searchTerm, setSearchTerm] = useState("")
    const [currentPage, setCurrentPage] = useState(1)
    const [setApproveVisitChange, updateSetApproveVisitChange] = useState(1)
    const [sortColumn, setSortColumn] = useState("id")
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [selectedRows, setSelectedRows] = useState([])
    const [handleClearSelectedRows, setHandleClearSelectedRows] = useState(false)
    const toggleVisitHtmlImagePopup = () => dispatch(ToggleVisitHtmlImagePopup({show: false, image: null}))
    const [isIsyeriDropdownOpen, setIsIsyeriDropdownOpen] = useState(false)
    const [isReportStartDateDropdownOpen, setIsReportStartDateDropdownOpen] = useState(false)
    const [isReportEndDateDropdownOpen, setIsReportEndDateDropdownOpen] = useState(false)
    const [isPositionReportTypeDropdownOpen, setIsPositionReportTypeDropdownOpen] = useState(false)
    const selectedCompanyIds = useSelector(state => state.visit.selectedCompanyIds)
    const selectedReportStartDateYears = useSelector(state => state.visit.selectedReportStartDateYears)
    const selectedReportStartDateMonths = useSelector(state => state.visit.selectedReportStartDateMonths)
    const selectedReportEndDateYears = useSelector(state => state.visit.selectedReportEndDateYears)
    const selectedReportEndDateMonths = useSelector(state => state.visit.selectedReportEndDateMonths)
    const selectedPositionReportTypes = useSelector((state) => state.visit.selectedPositionReportTypes)
    const hasFilters = selectedCompanyIds.length > 0 || selectedReportStartDateYears.length > 0 || selectedReportStartDateMonths.length > 0 || selectedReportEndDateYears.length > 0 || selectedReportEndDateMonths.length > 0 || selectedPositionReportTypes.length > 0
    const [isColumnsFixed, setIsColumnsFixed] = useState(false)

    const toggleIsyeriDropdown = () => setIsIsyeriDropdownOpen(!isIsyeriDropdownOpen)
    const toggleReportStartDateDropdown = () => setIsReportStartDateDropdownOpen(!isReportStartDateDropdownOpen)
    const toggleReportEndDateDropdown = () => setIsReportEndDateDropdownOpen(!isReportEndDateDropdownOpen)
    const togglePositionReportTypeDropdown = () => setIsPositionReportTypeDropdownOpen(!isPositionReportTypeDropdownOpen)

    console.log(store.approvedList)
    const getDataParameter = () => {
        return {
            sort,
            sortColumn,
            q: searchTerm,
            page: currentPage,
            pageSize: rowsPerPage,
            visitType: 1,
            CompanyGroupId: authStore.selectedCompanyGroup
        }
    }

    // ** Get data on mount
    useEffect(() => {
        dispatch(getData(getDataParameter()))
        dispatch(getMailOptions())
        dispatch(getReportEndDayArrivedMailOptions())
        dispatch(approveVisitListClear())
        dispatch(getCompanyOptions(authStore.selectedCompanyGroup))

    }, [dispatch, store.data.length, authStore.selectedCompanyGroup, sort, sortColumn, currentPage, selectedCompanyIds, selectedReportStartDateYears, selectedReportStartDateMonths, selectedReportEndDateYears, selectedReportEndDateMonths, selectedPositionReportTypes])

    useEffect(() => {
        if (currentPage > 1) setCurrentPage(1)
        else dispatch(getData(getDataParameter()))
        dispatch(approveVisitListClear())
    }, [searchTerm, selectedCompanyIds, selectedReportStartDateYears, selectedReportStartDateMonths, selectedReportEndDateYears, selectedReportEndDateMonths, selectedPositionReportTypes])

    useEffect(() => {
        // dispatch(
        //     getData(getDataParameter())
        // )
    }, [])

    useEffect(() => {
        // unmount oldugunda filtreleri temizle
        return () => {
          dispatch(clearFilters())
        }
    }, [])

    // ** Function in get data on page change
    const handlePagination = page => {
        setCurrentPage(page.selected + 1)
        setHandleClearSelectedRows(!handleClearSelectedRows)
    }

    // ** Function in get data on rows per page
    const handlePerPage = e => {
        const value = parseInt(e.currentTarget.value)
        // dispatch(
        //     getData(getDataParameter())
        // )
        setRowsPerPage(value)
    }

    // ** Function in get data on search query change
    const handleFilter = val => {
        setSearchTerm(val)
        // dispatch(
        //     getData(getDataParameter())
        // )
    }

    // ** Custom Pagination
    const CustomPagination = () => {
        //const count = Number(Math.ceil(store.total / rowsPerPage))

        return (
            <ReactPaginate
                previousLabel={""}
                nextLabel={""}
                pageCount={store.total || 1}
                activeClassName="active"
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                onPageChange={page => handlePagination(page)}
                pageClassName={"page-item"}
                nextLinkClassName={"page-link"}
                nextClassName={"page-item next"}
                previousClassName={"page-item prev"}
                previousLinkClassName={"page-link"}
                pageLinkClassName={"page-link"}
                containerClassName={"pagination react-paginate justify-content-end my-2 pe-1"}
            />
        )
    }

    // ** Table data to render
    const dataToRender = () => {
        const filters = {
            q: searchTerm
        }

        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (store.data.length > 0) {
            return store.data
        } else if (store.data.length === 0 && isFiltered) {
            return []
        } else {
            return store.allData.slice(0, rowsPerPage)
        }
    }
    console.log(store.approvedList)

    const handleSort = (column, sortDirection) => {
        setSort(sortDirection)
        setSortColumn(column.sortField)
        // dispatch(
        //     getData(getDataParameter())
        // )
    }

    function onSelectedRowsChanged(item) {
        setSelectedRows(item.selectedRows)

        const newApproveSelectedList = [...store.approveSelectedList]
        newApproveSelectedList.forEach((x, index) => {
            const selectedItem = item.selectedRows.find(z => {
                return z.approveSelectList.find(y => y.value === x)
            })
            if (!selectedItem) {
                newApproveSelectedList.splice(index, 1)
            }
        })
        dispatch(setApproveSelectedList(newApproveSelectedList))

        if (item.selectedRows.length === 0) {
            dispatch(approveCountPlus(store.approveCount + 1))
        }

        updateSetApproveVisitChange(setApproveVisitChange + 1)
    }

    const toggleColumnsFixed = () => {
        setIsColumnsFixed(!isColumnsFixed)
    }

    // Add column management state
    const [visitColumns, setVisitColumns] = useState(() => {
        try {
            const saved = localStorage.getItem(STORAGE_KEY)
            // Get original columns with all necessary props
            const originalColumns = columns(
                store.approveSelectedList,
                isIsyeriDropdownOpen,
                toggleIsyeriDropdown,
                isReportStartDateDropdownOpen,
                toggleReportStartDateDropdown,
                isReportEndDateDropdownOpen,
                toggleReportEndDateDropdown,
                isPositionReportTypeDropdownOpen,
                togglePositionReportTypeDropdown
            )

            if (!saved) return originalColumns

            const savedStates = JSON.parse(saved)

            // Apply saved visibility states while preserving the original column properties
            return originalColumns.map(col => ({
                ...col,
                omit: savedStates[col.name] || false
            }))
        } catch (error) {
            console.error('Error loading columns:', error)
            return columns(
                store.approveSelectedList,
                isIsyeriDropdownOpen,
                toggleIsyeriDropdown,
                isReportStartDateDropdownOpen,
                toggleReportStartDateDropdown,
                isReportEndDateDropdownOpen,
                toggleReportEndDateDropdown,
                isPositionReportTypeDropdownOpen,
                togglePositionReportTypeDropdown
            )
        }
    })

    // Update columns when dependencies change
    useEffect(() => {
        const updatedColumns = columns(
            store.approveSelectedList,
            isIsyeriDropdownOpen,
            toggleIsyeriDropdown,
            isReportStartDateDropdownOpen,
            toggleReportStartDateDropdown,
            isReportEndDateDropdownOpen,
            toggleReportEndDateDropdown,
            isPositionReportTypeDropdownOpen,
            togglePositionReportTypeDropdown
        )

        // Preserve visibility states while updating other properties
        setVisitColumns(updatedColumns.map(col => ({
            ...col,
            omit: visitColumns.find(vc => vc.name === col.name)?.omit || false
        })))
    }, [
        store.approveSelectedList,
        isIsyeriDropdownOpen,
        isReportStartDateDropdownOpen,
        isReportEndDateDropdownOpen,
        isPositionReportTypeDropdownOpen
    ])

    // Save column visibility states to localStorage
    useEffect(() => {
        try {
            const visibilityStates = visitColumns.reduce((acc, col) => ({
                ...acc,
                [col.name]: col.omit || false
            }), {})
            localStorage.setItem(STORAGE_KEY, JSON.stringify(visibilityStates))
        } catch (error) {
            console.error('Error saving columns:', error)
        }
    }, [visitColumns])

    // Reset columns to default function
    const resetColumns = () => {
        const defaultColumns = columns(
            store.approveSelectedList,
            isIsyeriDropdownOpen,
            toggleIsyeriDropdown,
            isReportStartDateDropdownOpen,
            toggleReportStartDateDropdown,
            isReportEndDateDropdownOpen,
            toggleReportEndDateDropdown,
            isPositionReportTypeDropdownOpen,
            togglePositionReportTypeDropdown
        )
        setVisitColumns(defaultColumns)
        localStorage.removeItem(STORAGE_KEY)
    }

    const handleColumnChange = (newColumns) => {
        // Get fresh columns to ensure we have the latest FilterComponents
        const currentColumns = columns(
            store.approveSelectedList,
            isIsyeriDropdownOpen,
            toggleIsyeriDropdown,
            isReportStartDateDropdownOpen,
            toggleReportStartDateDropdown,
            isReportEndDateDropdownOpen,
            toggleReportEndDateDropdown,
            isPositionReportTypeDropdownOpen,
            togglePositionReportTypeDropdown
        )

        // Map through new columns and preserve all properties including FilterComponents
        setVisitColumns(newColumns.map(col => {
            const originalCol = currentColumns.find(origCol => {
                // Match by name, handling both string and object (FilterComponent) cases
                const origName = typeof origCol.name === 'object' ? origCol.name.props.name : origCol.name
                const newName = typeof col.name === 'object' ? col.name.props.name : col.name
                return origName === newName
            })

            return {
                ...originalCol,
                omit: col.omit
            }
        }))
    }

    return (
        <Fragment>

            <Nav pills>
                <NavItem>
                    <NavLink
                        active={active === '1'}
                        onClick={() => {
                            toggle('1')
                        }}
                    >
                        Onay Bekleyen Rapor Listesi
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '2'}
                        onClick={() => {
                            toggle('2')
                        }}
                    >
                        Onaylı Rapor Listesi
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '3'}
                        onClick={() => {
                            toggle('3')
                        }}
                    >
                        Arşiv Rapor Listesi
                    </NavLink>
                </NavItem>
            </Nav>
            <TabContent className='py-50' activeTab={active}>
                <TabPane tabId='1'>
                    <Card className="overflow-hidden">
                        <div className="react-dataTable" >
                            <DataTable
                                noHeader
                                selectableRowsHighlight
                                subHeader
                                sortServer
                                pagination
                                responsive
                                paginationServer
                                selectableRows={true}
                                selectableRowDisabled={(row) => !row.isApproved || row.reportEndDate === "-" || row.approvableStatus === 1}
                                onSelectedRowsChange={onSelectedRowsChanged}
                                clearSelectedRows={handleClearSelectedRows}
                                columns={visitColumns}
                                onSort={handleSort}
                                sortIcon={<ChevronDown/>}
                                className={`react-dataTable ${isColumnsFixed ? 'unapproved-archive-fixed-columns' : ''}`}
                                paginationComponent={CustomPagination}
                                data={dataToRender()}
                                fixedHeader
                                fixedHeaderScrollHeight="70vh"
                                subHeaderComponent={
                                    <CustomHeader
                                        store={store}
                                        searchTerm={searchTerm}
                                        rowsPerPage={rowsPerPage}
                                        handleFilter={handleFilter}
                                        handlePerPage={handlePerPage}
                                        selectedRows={selectedRows}
                                        dispatch={dispatch}
                                        currentPage={currentPage}
                                        setApproveVisitChange={setApproveVisitChange}
                                        hasFilters={hasFilters}
                                        isColumnsFixed={isColumnsFixed}
                                        toggleColumnsFixed={toggleColumnsFixed}
                                        columns={visitColumns}
                                        handleColumnChange={handleColumnChange}
                                        resetColumns={resetColumns}
                                    />
                                }
                            />
                        </div>
                    </Card>
                </TabPane>
                <TabPane tabId='2'>
                    <ApprovedVisitList/>
                </TabPane>
                <TabPane tabId='3'>
                    <ArchiveVisitList/>
                </TabPane>
            </TabContent>
            <ManualVisitDeclarationPopup open={store.manualVisitDeclarationStatus}/>
            <VisitDetailPopup open={store.detailPopupStatus}/>
            <VisitHtmlImagePopup open={store.toggleVisitHtmlImagePopup?.show}
                                 toggleSidebar={toggleVisitHtmlImagePopup}/>
        </Fragment>
    )
}


function SelectVisits({dataSource, currentPage, searchTerm, setApproveVisitChange}) {
    const dispatch = useDispatch()
    const store = useSelector(state => state.visit)
    const [selectedValue, setSelectedValue] = useState({name: "default", label: "Islem tipi seciniz"})
    const options = [
        {
            value: -1,
            label: "Seçiniz"
        },
        {
            value: 1,
            label: "Çalıştı olarak isaretle"
        },
        {
            value: 0,
            label: "Çalışmadı olarak isaretle"
        }
    ]

    function setApproveList(item) {
        const updatedItemList = [
            ...store.data.map(x => ({
                ...x,
                approveSelectList: x.approveSelectList.map(z => ({...z, selected: false}))
            }))
        ]
        const approveSelectedList = []
        const approveVisitList = []
        updatedItemList.forEach(x => {
            const selectedIndex = dataSource.findIndex(z => z.reportNo === x.reportNo)
            if (selectedIndex === -1) return x

            const approvelist = x.approveSelectList.filter(z => !z.isDisabled)
            const selectedDataList = approvelist.filter(z => parseInt(z.value.substring(11)) === item.value)
            if (selectedDataList.length > 0) {
                const selectedData = selectedDataList[selectedDataList.length - 1]
                x.approveSelectList.some(z => {
                    if (z.value === selectedData.value) {
                        approveSelectedList.push(z.value)
                        approveVisitList.push({
                            qualityState: z.value,
                            identificationNumber: x.identificationNumber,
                            case: x.case,
                            medulaReportId: x.medularReportId,
                            date: x.reportEndDate,
                            visitId: x.visitId
                        })
                        return true
                    }
                    return false
                })
            }
            return x
        })
        dispatch(setVisitList(approveVisitList))
        dispatch(setApproveSelectedList(approveSelectedList))
    }

    function onChange(item) {
        setSelectedValue(item)
        setApproveList(item)
    }

    useEffect(() => {
        onChange({value: -1, label: "Seçiniz"})
        dispatch(approveVisitListClear())
    }, [currentPage, store.approveCount, searchTerm])


    useEffect(() => {
        setApproveList(selectedValue)
    }, [setApproveVisitChange])


    return (
        <div style={{display: "flex"}}>
            <div className="col-1 mt-2">Secilenleri</div>
            <div className="col-4 mt-1">
                <Select
                    isClearable={false}
                    options={options}
                    onChange={onChange}
                    className="react-select"
                    classNamePrefix="select"
                    value={selectedValue}
                />
            </div>
        </div>
    )
}

export default UsersList
