// ** Redux Imports
import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const getData = createAsyncThunk('workerdeclaration/getData', async (params, {getState}) => {
    params.CompanyGroupId = getState().auth.selectedCompanyGroup
    params.WorkerDeclarationType = 2
    const response = await axios.get(`/api/workerdeclaration`, {params})
    return {
        params,
        data: response.data.workerDeclarations,
        totalPages: response.data.pageCount
    }
})

export const togglePopup = createAsyncThunk('api/togglePopup', async (status) => {
    return status
})

export const setFilePopupOpen = createAsyncThunk('api/setFilePopupOpen', async (status) => {
    return status
})

export const DissmisalClose = createAsyncThunk('api/DissmisalClose', async () => {
    return false
})

export const SyncWorkerList = createAsyncThunk('workerdeclaration/SyncWorkerList', async (data, {getState}) => {
    const response = await axios.get(`/api/workerdeclaration/Sync/${data}/${getState().auth.selectedCompanyGroup}`)
    if (response && response.status === 200) {
        toast.success("İşleminiz başlatıldı.")
    }
    return police
})

export const setAutoSyncOptions = createAsyncThunk('workerdeclaration/setAutoSyncOptions', async (data, {getState}) => {
    debugger
    const response = await axios.get(`/api/workerdeclaration/Sync/OptionUpdate/${data.type}/${getState().auth.selectedCompanyGroup}`, {
        params: {
            status: data.status
        }
    })
    if (response && response.status === 200) {
        toast.success("İşleminiz tamamlandı.")
    }
    return data.status
})

export const getAutoSyncOptions = createAsyncThunk("workAccidents/getAutoSyncOptions", async (data, {getState}) => {
    const url = `/api/workerdeclaration/Sync/GetOption/${data}/${getState().auth.selectedCompanyGroup}`
    const response = await axios.get(url)
    console.log("getresponse", response.data)
    return response.data.status
})

export const addWorkerDeclaration = createAsyncThunk('api/workerdeclaration', async (data, {dispatch, getState}) => {
    const response = await axios.post('/api/workerdeclaration', data)
    if (response && response.status === 200) {
        await dispatch(getData(getState().police.params))
        toast.success("İşleminiz başlatıldı.")
        dispatch(togglePopup(false))
    }
    return police
})

export const removeWorkerDeclaration = createAsyncThunk('api/workerdeclaration', async (data, {dispatch, getState}) => {
    const response = await axios.post('/api/workerdeclaration/dissmisalnotify', data)
    if (response && response.status === 200) {
        await dispatch(DissmisalClose())
        await dispatch(getData(getState().police.params))
        toast.success("İşleminiz başlatıldı.")
    }
    return police
})

export const addWorkerDeclarationFile = createAsyncThunk('api/workerdeclarationfile', async (data, {
    dispatch,
    getState
}) => {
    let fileUrl = `create`
    if (getState().police.fileType === "2") fileUrl = `delete`
    const response = await axios.post(`/api/workerdeclaration/file/${fileUrl}`, data)
    if (response && response.status === 200) {
        await dispatch(getData(getState().police.params))
        toast.success("İşleminiz başlatıldı.")
        dispatch(setFilePopupOpen(false))
    }
    return response
})

export const setFileType = createAsyncThunk('api/FileType', async (type) => {
    return type
})

export const DissmisalUser = createAsyncThunk('api/DissmisalUser', async (data) => {
    return data
})
export const getCompanyList = createAsyncThunk('api/getCompanyList', async (data, {getState}) => {
    const response = await axios.get(`/api/companygroup/${getState().auth.selectedCompanyGroup}/companies`)
    return response.data
})

export const appWorkerDeclarationPoliceSlice = createSlice({
    name: 'appWorkerDeclarationsPolice',
    initialState: {
        data: [],
        total: 1,
        params: {},
        allData: [],
        selectedUser: null,
        dissmisalPopup: false,
        sidebarOpen: false,
        filePopupOpen: false,
        isPoliceHotelWorkerDeclarationEmploye: false,
        receiveAutomatedWorkerSync: false,
        fileType: "1",
        companyList: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
            })
            .addCase(setFileType.fulfilled, (state, action) => {
                state.fileType = action.payload
            })
            .addCase(DissmisalUser.fulfilled, (state, action) => {
                state.selectedUser = action.payload
                state.dissmisalPopup = true
            })
            .addCase(DissmisalClose.fulfilled, (state, action) => {
                state.dissmisalPopup = action.payload
            })
            .addCase(togglePopup.fulfilled, (state, action) => {
                state.sidebarOpen = action.payload
            })
            .addCase(setFilePopupOpen.fulfilled, (state, action) => {
                state.filePopupOpen = action.payload
            })
            .addCase(getCompanyList.fulfilled, (state, action) => {
                state.companyList = action.payload
            })
            .addCase(getAutoSyncOptions.fulfilled, (state, action) => {
                state.receiveAutomatedWorkerSync = action.payload
            })
            .addCase(setAutoSyncOptions.fulfilled, (state, action) => {
                state.receiveAutomatedWorkerSync = action.payload
            })
    }
})

export default appWorkerDeclarationPoliceSlice.reducer
