import { lazy } from 'react'

const Incentive = lazy(() => import('../../views/pages/incentive/index'))
const IncentiveBilling = lazy(() => import('../../views/pages/incentive-billing/index'))
const IncentiveDefinationDelete = lazy(() => import('../../views/pages/incentive-defination-delete/view'))
const IncentiveDefinationDeleteManagement = lazy(() => import('../../views/pages/incentive-defination-delete-management/view'))

const IncentiveRoutes = [
  {
    path: '/tesvik',
    element: <Incentive />
  },
  {
    path: '/tesvik-yonetim',
    element: <Incentive />
  },
  {
    path: '/tesvik-fatura',
    element: <IncentiveBilling />
  },
  {
    path: '/tesvik-tanimlama-silme',
    element: <IncentiveDefinationDelete />
  },
  {
    path: '/tesvik-tanimlama-silme-yonetim',
    element: <IncentiveDefinationDeleteManagement />
  }
]

export default IncentiveRoutes