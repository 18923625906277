// ** Reducers Imports
import companyGroups from '@src/views/pages/company-group/store'
import company from '@src/views/pages/company/store'
import edeclarationByServices from '@src/views/pages/edeclaration-by-service/store'
import edeclarations from '@src/views/pages/edeclaration/store'
import payroll from '@src/views/pages/payroll/store'
import users from '@src/views/pages/user-management/store'
import userFileManager from '@src/views/pages/UserFileManager/store'
import approvedVisits from '@src/views/pages/visit-approved/store'
import visit from '@src/views/pages/visit/store'
import workAccident from '@src/views/pages/work-accident/store'
import gendarmerie from '@src/views/pages/WorkerDeclaration/Gendarmerie/store'
import police from '@src/views/pages/WorkerDeclaration/Police/store'
import sgkWorkerDeclaration from '@src/views/pages/WorkerDeclaration/Sgk/store'
import workerlist from '@src/views/pages/workerlist/store'
import auth from './authentication'
import layout from "./layout"
import navbar from "./navbar"
import appTabsManagement from './tabManagement'
import incentive from '@src/views/pages/incentive/store'
import incentiveManagement from '@src/views/pages/incentive-management/store'
import currentAudit from '@src/views/pages/current-audit/store'
import incentiveBilling from '@src/views/pages/incentive-billing/store'
import announcements from '@src/views/pages/announcements/store'
import queue from '@src/views/pages/queue/store'
import iskur from '@src/views/pages/workforce-graph/store'
import userLogs from '@src/views/pages/userLogs/store'
import debtQuestioning from '@src/views/pages/debt-questioning/store'
import incentiveDefinationDelete from '@src/views/pages/incentive-defination-delete/store'
import incentiveDefinationDeleteManagement from '@src/views/pages/incentive-defination-delete-management/store'

const rootReducer = {
    auth,
    navbar,
    layout,
    police,
    gendarmerie,
    company,
    companyGroups,
    users,
    appTabsManagement,
    edeclarations,
    workerlist,
    edeclarationByServices,
    workAccident,
    visit,
    userFileManager,
    payroll,
    approvedVisits,
    sgkWorkerDeclaration,
    incentive,
    incentiveManagement,
    incentiveBilling,
    currentAudit,
    announcements,
    queue,
    iskur,
    userLogs,
    debtQuestioning,
    incentiveDefinationDelete,
    incentiveDefinationDeleteManagement
}

export default rootReducer
