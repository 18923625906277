import {createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import axios from 'axios'
import toast from 'react-hot-toast'

export const getCompanyList = createAsyncThunk('visit/getCompanyList', async (data, {getState}) => {
    const response = await axios.get(`/api/companygroup/${getState().auth.selectedCompanyGroup}/companies`)
    return response.data
})

export const buttonDisabled = createAsyncThunk('incentive/buttonDisabled', async (data) => {
    return data
})

export const getIncentiveProcess = createAsyncThunk('incentive/getProcess', async (data, {getState}) => {
    let path = `/api/incentive/lastProcess?CompanyGroupId=${getState().auth.selectedCompanyGroup}`
    if (data) {
        path += `&CompanyId=${data}`
    }

    const response = await axios.get(path)
    return response.data
})

export const getIncentiveFileUploadProcess = createAsyncThunk('incentive/getActiveFileUploads', async (data, {getState}) => {
    const response = await axios.get(`/api/Incentive/GetProcess?CompanyGroupId=${getState().auth.selectedCompanyGroup}&Size=${data.value}`)
    return response.data
})

export const handleCancel = createAsyncThunk('incentive/handleCancel', async (data, {dispatch}) => {
    const response = await axios.post(`/api/Incentive/CancelProcess`, {
        IncentiveProccessId: data
    })
    if (response && response.status === 200) {
        toast.success('İşleminiz Başarılı')
        dispatch(getIncentiveFileUploadProcess())
    }
})

export const showHideFilePopup = createAsyncThunk('incentive/closeFilePopup', async (status) => {
    return status
})
export const showSelectPeriodPopup = createAsyncThunk('incentive/showSelectPeriodPopup', async (status) => {
    return status
})
export const showHideMinimumSupportPopup = createAsyncThunk('incentive/closeMinimumSupportPopup', async (status) => {
    return status
})
export const showHideIncentiveQuestioningFilePopup = createAsyncThunk('incentive/closeIncentiveQuestioningFilePopup', async (status) => {
    return status
})

export const selectCompanyId = createAsyncThunk('incentive/selectCompanyId', async (status, {dispatch}) => {
    dispatch(getIncentiveProcess(status.value))
    return status
})

export const addIncentiveFile = createAsyncThunk('api/addIncentiveFile', async (data, {dispatch}) => {
    const toastId = toast.loading('XML yüklenirken lütfen bekleyin...')
    const response = await axios.post('/api/incentive/uploadIncentiveXml', data)
    toast.dismiss(toastId)
    dispatch(buttonDisabled(false))
    if (response && response.status === 200) {
        toast.success('İşleminiz Başarılı')
        await dispatch(showHideFilePopup(false))
    }
})

export const addIncentiveQuestioningFile = createAsyncThunk('api/addIncentiveQuestioningFile', async (data, {dispatch}) => {
    const toastId = toast.loading('Dosya yüklenirken lütfen bekleyin...')
    const response = await axios.post('/api/incentive/ImportIncentiveQuestioningExcel', data)
    toast.dismiss(toastId)
    dispatch(buttonDisabled(false))
    if (response && response.status === 200) {
        toast.success('İşleminiz Başarılı')
        await dispatch(showHideIncentiveQuestioningFilePopup(false))
    }
})

export const onlyIncentiveWorkerIdentification = createAsyncThunk('api/onlyIncentiveWorkerIdentification', async (data, {dispatch}) => {
    const toastId = toast.loading('İşlem başlatılırken lütfen bekleyin...')
    const response = await axios.post('/api/incentive/OnlyIncentiveWorkerIdentification', data)
    toast.dismiss(toastId)
    if (response && response.status === 200) {
        toast.success('İşleminiz Başlatıldı')
        await dispatch(showSelectPeriodPopup(false))
    }
})

//MOSİP
export const getDepositMosipList = createAsyncThunk('incentive/getDepositMosipList', async (data) => {
    const response = await axios.get(`/api/Incentive/DepositMosipList`, {
        params: {
            CompanyGroupId: data
        }
    })
    return response.data.scheduler
})

export const depositMosipListCreate = createAsyncThunk('incentive/depositMosipListCreate', async (data) => {
    const response = await axios.get(`/api/Incentive/DepositMosip`, {
        params: {
            CompanyGroupId: data
        }
    })
    if (response.status === 200) {
        toast.success("İşleminiz Başlatıldı")
    } else {
        toast.error("Bir Sorun Oluştu")
    }
})

export const getCompanyProcess = createAsyncThunk('incentive/getCompanyProcess', async (data) => {
    const response = await axios.get(`/api/Incentive/CompanyProcess`, {
        params: {
            CompanyGroupId: data
        }
    })
    return response.data.processList
})

export const downloadSchedulerExcel = createAsyncThunk('incentive/GetIncentiveProcessWorkerExportQuery', async (id) => {
    const response = await axios.post(`/api/Incentive/GetIncentiveProcessWorkerExportQuery`, {
            CompanyGroupId: id
        },
        {
            responseType: 'arraybuffer',
            'Content-Type': 'blob'
        })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'sonuc.xlsx')
    document.body.appendChild(link)
    link.click()
    return {
        data: response.data.scheduler
    }
})

export const downloadDepositMosipExcel = createAsyncThunk('incentive/downloadDepositMosipExcel', async (id) => {
    const response = await axios.post(`/api/Incentive/DepositMosipExport`, {companyGroupId: id}, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob'
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'sonuc.xlsx')
    document.body.appendChild(link)
    link.click()
    return {
        data: response.data.scheduler
    }
})

//6661 Asgari Destek
export const getMinimumSupportList = createAsyncThunk('incentive/getMinimumSupportList', async (data) => {
    const response = await axios.get(`/api/Incentive/MinimumSupportList`, {
        params: {
            CompanyGroupId: data
        }
    })
    return response.data.scheduler
})

export const minimumSupportListCreate = createAsyncThunk('incentive/minimumSupportListCreate', async (data, {
    dispatch,
    getState
}) => {
    const toastId = toast.loading('Kuyruklar oluşturulurken lütfen bekleyin...')
    const response = await axios.get(`/api/Incentive/MinimumSupport`, {
        params: data
    })
    dispatch(showHideMinimumSupportPopup(false))
    toast.dismiss(toastId)
    dispatch(getMinimumSupportList(getState().auth.selectedCompanyGroup))
    if (response.status === 200) {
        toast.success("İşleminiz Başlatıldı")
    } else {
        toast.error("Bir Sorun Oluştu")
    }
})

export const downloadMinimumSupportExcel = createAsyncThunk('incentive/downloadMinimumSupportExcel', async (id) => {
    const response = await axios.post(`/api/Incentive/MinimumSupportExport`, {companyGroupId: id}, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob'
    })
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'sonuc.xlsx')
    document.body.appendChild(link)
    link.click()
    return {
        data: response.data.scheduler
    }
})

export const getIncentiveQuestioningRequestsForCompany = createAsyncThunk('incentive/getIncentiveQuestioningRequestsForCompany', async (data) => {
    const response = await axios.get(`/api/Incentive/GetIncentiveQuestioningRequestsForCompany?CompanyGroupId=${data}`)
    return response.data
})

export const getIncentiveQuestioningResultFile = createAsyncThunk('incentive/getIncentiveQuestioningResultFile', async (data) => {
    const response = await axios.get(`/api/Incentive/GetIncentiveQuestioningResultFile?SchedulerId=${data}`,
        {
            responseType: 'arraybuffer',
            'Content-Type': 'blob'
        }
    )
    const url = window.URL.createObjectURL(new Blob([response.data]))
    const link = document.createElement('a')
    link.href = url
    link.setAttribute('download', 'sonuc.xlsx')
    document.body.appendChild(link)
    link.click()
})

export const incentiveEfficiencyAnalysis = createAsyncThunk('incentive/incentiveEfficiencyAnalysis', async (data) => {
    const response = await axios.get(`/api/Incentive/incentiveEfficiencyAnalysis?CompanyGroupId=${data}`,
        {
            responseType: 'arraybuffer',
            'Content-Type': 'blob'
        }
    )
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'sonuc.xlsx')
        document.body.appendChild(link)
        link.click()
    }
})

export const incentiveSlice = createSlice({
    name: 'incentive',
    initialState: {
        selectedCompanyId: null,
        filePopupOpen: false,
        selectPeriodPopupOpen: false,
        fileButtonDisabled: false,
        companyList: [],
        incentiveProcess: {},
        incentiveFileUploadProcess: {},
        depositMosipData: [],
        minimumSupportData: [],
        companyProcess: [],
        minimumSupportPopupOpen: false,
        incentiveQuestioningFilePopup: false,
        incentiveQuestioningRequests: []
    },
    reducers: {},
    extraReducers: builder => {
        builder
            .addCase(getCompanyList.fulfilled, (state, action) => {
                state.companyList = action.payload
            })
            .addCase(buttonDisabled.fulfilled, (state, action) => {
                state.fileButtonDisabled = action.payload
            })
            .addCase(getIncentiveProcess.fulfilled, (state, action) => {
                state.incentiveProcess = action.payload
            })
            .addCase(showHideFilePopup.fulfilled, (state, action) => {
                state.filePopupOpen = action.payload
            })
            .addCase(showSelectPeriodPopup.fulfilled, (state, action) => {
                state.selectPeriodPopupOpen = action.payload
            })
            .addCase(showHideMinimumSupportPopup.fulfilled, (state, action) => {
                state.minimumSupportPopupOpen = action.payload
            })
            .addCase(selectCompanyId.fulfilled, (state, action) => {
                state.selectedCompanyId = action.payload
            })
            .addCase(getIncentiveFileUploadProcess.fulfilled, (state, action) => {
                state.incentiveFileUploadProcess = action.payload
            })
            .addCase(getDepositMosipList.fulfilled, (state, action) => {
                state.depositMosipData = action.payload
            })
            .addCase(getMinimumSupportList.fulfilled, (state, action) => {
                state.minimumSupportData = action.payload
            })
            .addCase(getCompanyProcess.fulfilled, (state, action) => {
                state.companyProcess = action.payload
            })
            .addCase(showHideIncentiveQuestioningFilePopup.fulfilled, (state, action) => {
                state.incentiveQuestioningFilePopup = action.payload
            })
            .addCase(getIncentiveQuestioningRequestsForCompany.fulfilled, (state, action) => {
                state.incentiveQuestioningRequests = action.payload.incentiveQuestioningRequests
            })
    }
})

export default incentiveSlice.reducer