// ** React Imports
import React, {Fragment} from 'react'

// ** Reactstrap Imports
import {Button, CardBody, Col, Form, Input, Label, Modal, ModalBody, ModalHeader, Row} from 'reactstrap'
import Select from 'react-select'

// ** Store & Actions
import {useDispatch, useSelector} from 'react-redux'
import {
    toggleEntryOutDeclarationDeletePopup,
    entryOutDeclarationDeleteApi
} from '../store'
import {Controller, useForm} from "react-hook-form"
import Cleave from "cleave.js/react"

const EntryOutDeclarationDeletePopup = () => {
    // ** Store Vars
    const dispatch = useDispatch()
    const store = useSelector(state => state.sgkWorkerDeclaration)

    // ** Form
    const {control, handleSubmit, formState: {errors}} = useForm()

    const optionsTcKimlikNo = {
        numericOnly: true,
        blocks: [11]
    }

    const declarationTypes = [
        {value: '', label: 'Seçiniz'},
        {value: 'entry', label: 'Giriş Bildirgesi İptal Et'},
        {value: 'exit', label: 'Çıkış Bildirgesi İptal Et'}
    ]

    const onSubmit = (data) => {
        if (!data.declarationType?.value) {
            return
        }
        
        const payload = {
            identificationNumber: data.identificationNumber,
            companyId: data.company?.value,
            type: data.declarationType.value
        }
        dispatch(entryOutDeclarationDeleteApi(payload))
    }

    const handleClose = () => {
        dispatch(toggleEntryOutDeclarationDeletePopup(false))
    }

    return (
        <Modal
            isOpen={store.entryOutDeclarationDeletePopupStatus}
            toggle={handleClose}
            className='modal-dialog-centered modal-lg'
        >
            <ModalHeader className='bg-transparent' toggle={handleClose}></ModalHeader>
            <ModalBody className='px-sm-5 mx-50 pb-5'>
                <div className='text-center mb-2'>
                    <h1 className='mb-1'>SGK</h1>
                    <p>Giriş/Çıkış Bildirgesi Sil</p>
                </div>

                <Form style={{}} onSubmit={handleSubmit(onSubmit)}>
                    <div className='mb-1'>
                        <Label className='form-label' for='declarationType'>
                            İptal Türü <span className='text-danger'>*</span>
                        </Label>
                        <Controller
                            name='declarationType'
                            control={control}
                            defaultValue={declarationTypes[0]}
                            rules={{ 
                                required: true,
                                validate: value => value?.value !== ''
                            }}
                            render={({field}) => (
                                <Select
                                    isClearable={false}
                                    options={declarationTypes}
                                    className='react-select'
                                    classNamePrefix='select'
                                    {...field}
                                />
                            )}
                        />
                        {errors.declarationType && <small className='text-danger'>İptal türü seçimi zorunludur</small>}
                    </div>
                    <div className='mb-1'>
                        <Label className='form-label' for='company'>
                            Sicil <span className='text-danger'>*</span>
                        </Label>
                        <Controller
                            name='company'
                            control={control}
                            render={({field}) => (
                                <Select
                                    isClearable={false}
                                    options={store.companyList}
                                    className='react-select'
                                    classNamePrefix='select'
                                    {...field}
                                    onChange={e => {
                                        field.onChange(e)
                                    }}
                                />

                            )}
                        />
                    </div>
                    <div className='mb-1'>
                        <Label className='form-label' for='identificationNumber'>
                            T.C. Kimlik No <span className='text-danger'>*</span>
                        </Label>
                        <Controller
                            name='identificationNumber'
                            control={control}
                            rules={{required: true, minLength: 11, maxLength: 11}}
                            render={({field}) => (
                                <Cleave
                                    className='form-control'
                                    placeholder='TC Kimlik No'
                                    options={optionsTcKimlikNo}
                                    {...field}
                                />
                            )}
                        />
                        {errors.identificationNumber &&
                            <small className='text-danger'>Geçerli bir TC Kimlik No giriniz</small>}
                    </div>
                    <div className='d-flex justify-content-end mt-2'>
                        <Button type='submit' className='me-1' color='primary'>
                            İptal Et
                        </Button>
                        <Button type='reset' color='secondary' outline onClick={handleClose}>
                            Vazgeç
                        </Button>
                    </div>
                </Form>
            </ModalBody>
        </Modal>
    )
}

export default EntryOutDeclarationDeletePopup 