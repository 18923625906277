import {
    Archive,
    Briefcase,
    Calendar,
    File,
    FileMinus,
    FileText,
    Folder,
    Settings,
    Home,
    LogOut,
    Tool,
    User,
    Users,
    Circle,
    Bell,
    Activity,
    Table,
    List
} from "react-feather"
// import { getUserData } from "@utils"

export const getNavigation = () => {
    const RouteArray = [
        {
            id: "home",
            moduleId: -2,
            title: "Ana Sayfa",
            icon: <Home size={20}/>,
            navLink: "/home"
        }
    ]
    const user = JSON.parse(localStorage.getItem("userData"))
    console.log(user)
    if (user !== null) {
        if (user.role === "ConsultantAdmin") {
            RouteArray.push({
                id: "companies-information",
                moduleId: -2,
                title: "Firma Bilgileri",
                icon: <File size={20}/>,
                children: [
                    {
                        id: "companies-group",
                        moduleId: -1,
                        title: "Firma Grup",
                        icon: <LogOut size={20}/>,
                        navLink: "/firma-grup"

                    },
                    {
                        id: "companies",
                        moduleId: -1,
                        title: "Firmalar",
                        icon: <Folder size={20}/>,
                        navLink: "/firmalar"
                    }

                ]
            })

            RouteArray.push({
                id: "users-management",
                moduleId: 2,
                title: "Kullanıcı Yönetimi",
                icon: <Users size={20}/>,
                navLink: "/kullanici-yonetim"
            })

            RouteArray.push({
                id: "user-logs",
                moduleId: 2,
                title: "Kullanıcı Logları",
                icon: <List size={20}/>,
                navLink: "/kullanici-loglari"
            })

            RouteArray.push({
                id: "AddAnnouncements",
                moduleId: 17,
                title: "Duyuru Ekle",
                icon: <Circle size={20}/>,
                navLink: "/duyuru-ekle"
            })

            RouteArray.push({
                id: "queue-status",
                title: "Kuyruk Yönetim",
                icon: <Activity size={20}/>,
                children: [
                    {
                        id: "queue-performance",
                        moduleId: 18,
                        title: "Kuyruk Performansı",
                        icon: <Activity size={20}/>,
                        navLink: "/kuyruk-performansi"

                    },
                    {
                        id: "queue-company-status",
                        moduleId: 18,
                        title: "Firma Kuyruk ",
                        icon: <Activity size={20}/>,
                        navLink: "/firma-kuyruk-durumu"
                    }

                ]
            })
        }

        if (user.role === "Customer" || user.role === "CustomerAdmin") {
            RouteArray.push({
                id: "companies-information",
                moduleId: -2,
                title: "Firma Bilgileri",
                icon: <File size={20}/>,
                children: [
                    {
                        id: "companies",
                        moduleId: 22,
                        title: "Firmalar",
                        icon: <Folder size={20}/>,
                        navLink: "/firmalar"
                    }
                ]
            })
            RouteArray.push({
                id: "users-management",
                moduleId: 2,
                title: "Kullanıcı Yönetimi",
                icon: <Users size={20}/>,
                navLink: "/kullanici-yonetim"
            })
        }
        
        if (user.role === "CustomerAdmin" || user.role === "Customer" || user.role === "ConsultantAdmin") {
            RouteArray.push(
                {
                    id: "Automation",
                    title: "Otomasyon",
                    icon: <Tool size={20}/>,
                    children: [
                        {
                            id: "GendarmerieWorkerDeclaration",
                            moduleId: 3,
                            title: "Jandarma Bildirimi",
                            icon: <Archive size={20}/>,
                            navLink: "/jandarma-bildirimi"

                        },
                        {
                            id: "PoliceWorkerDeclaration",
                            moduleId: 4,
                            title: "Emniyet Bildirimi",
                            icon: <Briefcase size={20}/>,
                            navLink: "/emniyet-bildirimi"
                        },
                        {
                            id: "workaccident",
                            moduleId: 5,
                            title: "SGK İş Kazası",
                            icon: <Tool size={20}/>,
                            navLink: "/is-kazasi"
                        },
                        {
                            id: "visit",
                            moduleId: 6,
                            title: "SGK Vizite",
                            icon: <FileText size={20}/>,
                            navLink: "/visit"
                        },
                        {
                            id: "workerEntryLayoffDeclaration",
                            moduleId: 7,
                            title: "İşe Giriş / Çıkış Bild.",
                            icon: <FileText size={20}/>,
                            navLink: "/ise-giris-cikis-bildirimi"
                        },
                        {
                            id: "iskurWorkforceGraph",
                            moduleId: 19,
                            title: "İşgücü Çizelgesi",
                            icon: <Table size={20}/>,
                            navLink: "/isgucu-cizelgesi"
                        },
                        {
                            id: "debtQuestioning",
                            moduleId: 5,
                            title: "SGK Borç Sorgulama",
                            icon: <Table size={20}/>,
                            navLink: "/sgk-borc-sorgulama"
                        }

                    ]
                })
            RouteArray.push({
                id: "HumanResoucesInformationManagement",
                title: "İKBS",
                icon: <User size={20}/>,
                children: [
                    {
                        id: "worker-list",
                        moduleId: 8,
                        title: "Personel Listesi",
                        icon: <Users size={20}/>,
                        navLink: "/calisan-listesi"
                    },
                    {
                        id: "payroll",
                        moduleId: 9,
                        title: "Bordro Kontrol",
                        icon: <Calendar size={20}/>,
                        navLink: "/payroll"
                    },
                    {
                        id: "edeclaration",
                        moduleId: 10,
                        title: "Tahakkuk ve Hizmet",
                        icon: <FileMinus size={20}/>,
                        navLink: "/e-bildirge"

                    }
                ]

            })
            RouteArray.push({
                id: "filemanager",
                moduleId: 11,
                title: "Dosya Yönetimi",
                icon: <Folder size={20}/>,
                navLink: "/dosya-yonetimi"
            })
            RouteArray.push({
                id: "incentive-menu",
                title: "Teşvik",
                icon: <Settings size={20}/>,
                children: [
                    {
                        id: "incentive",
                        title: "Teşvik İşlemleri",
                        moduleId: 12,
                        icon: <Settings size={20}/>,
                        navLink: "/tesvik"
                    },
                    {
                        id: "incentive-management",
                        title: "Teşvik Yönetim",
                        moduleId: 13,
                        icon: <Settings size={20}/>,
                        navLink: "/tesvik-yonetim"
                    },
                    {
                        id: "incentive-defination-delete",
                        title: "Tanımlama Silme",
                        moduleId: 21,
                        icon: <Settings size={20}/>,
                        navLink: "/tesvik-tanimlama-silme"
                    },
                    {
                        id: "incentive-defination-delete-management",
                        title: "T. Silme Yönetim",
                        moduleId: 21,
                        icon: <Settings size={20}/>,
                        navLink: "/tesvik-tanimlama-silme-yonetim"
                    }
                ]
            })
            RouteArray.push({
                id: "current-audit",
                title: "Cari Denetim",
                moduleId: 15,
                icon: <Circle size={20}/>,
                navLink: "/cari-denetim"
            })
            RouteArray.push({
                id: "ListAnnouncements",
                moduleId: 16,
                title: "Duyurular",
                icon: <Circle size={20}/>,
                navLink: "/duyurular"
            })
        }


    }
    return RouteArray
}