import { Button } from "reactstrap"
import { ToggleHtmlImagePopup, ToggleOutHtmlImagePopup } from "../../store"
import {store} from "@store/store"

//Taşan verileri truncate eden fonksiyon
const truncate = (str, n) => {
  return (str.length > n) ? `${str.slice(0, n - 1)  }...` : str
}

//Truncate edilecek verilerde kullanılacak style
const truncateStyle = {
  whiteSpace: 'nowrap',
  overflow: 'hidden',
  textOverflow: 'ellipsis',
  maxWidth: '200px'
}

export const entryOutTableColumns = [
  {
    name: 'Grup',
    sortable: false,
    minWidth: '120px',
    sortField: 'companyGroupName',
    selector: row => row.companyGroupName,
    cell: row => row.companyGroupName
  },
  {
    name: 'İşyeri Adı ve Sicil No',
    sortable: false,
    minWidth: '250px',
    sortField: 'name',
    selector: row => row.registrationNumber,
    cell: row => (
        <div className='d-flex justify-content-left align-items-center'>
            <div className='d-flex flex-column'>
                <p className='user_name text-truncate text-body mb-0'>
                    <span style={truncateStyle} className='fw-bolder'> {truncate(row.companyName, 20)} </span>
                </p>
                <small className='text-truncate text-muted mb-0'>{row.registrationNumber}</small>
            </div>
        </div>
    )
},
  {
    name: 'Ad Soyad',
    sortable: false,
    minWidth: '250px',
    sortField: 'nameSurname',
    selector: row => row.nameSurname,
    cell: row => (
      <div className='d-flex justify-content-left align-items-center me-2'>
          <div className='d-flex flex-column'>
              <p className='user_name text-truncate text-body mb-0'>
                  <span className='fw-bolder'>{row.nameSurname}</span>
              </p>
              <small className='text-truncate text-muted mb-0'>{row.identityNumber}</small>
          </div>
      </div>
  )
  },
  {
    name: 'Giriş Tarihi',
    minWidth: '175px',
    sortable: false,
    sortField: 'startDate',
    selector: row => row.startDate,
    cell: row => (
      row.isEntryWorkerLog ? <Button onClick={() => store.dispatch(ToggleHtmlImagePopup({
          show: true,
          html: `${row.declarationHtml}`,
          id: row.companyWorkerLogId
      }))} className='btn-icon me-1 btn-light-gray'>{row.startDate}</Button> : "Bildirge Bulunamadı"
  )  },
  {
    name: 'Çıkış Tarihi',
    minWidth: '175px',
    sortable: false,
    sortField: 'endDate',
    selector: row => row.endDate,
    cell: row => (
      row.endDate !== 'AKTİF' ? <Button onClick={() => store.dispatch(ToggleHtmlImagePopup({
          show: true,
          html: `${row.outDeclarationHtml}`,
          id: row.companyWorkerLogId
      }))} className='btn-icon me-1 btn-light-gray'>{row.endDate}</Button> : "AKTİF"
  )
  },
  {
    name: 'Ayrılış Kodu',
    minWidth: '200px',
    sortable: false,
    sortField: 'outDeclarationCode',
    selector: row => row.outDeclarationCode,
    cell: row => row.outDeclarationCode
  },
  {
    name: 'Gün',
    maxWidth: '100px',
    sortable: false,
    sortField: 'days',
    selector: row => row.days,
    cell: row => row.days
  },
  {
    name: 'Meslek Kodu',
    minWidth: '150px',
    sortable: false,
    sortField: 'jobCode',
    selector: row => row.jobCode,
    cell: row => row.jobCode
  },
  {
    name: 'Meslek Adı',
    minWidth: '200px',
    sortable: false,
    sortField: 'jobName',
    selector: row => row.jobName,
    cell: row => row.jobName
  },
  {
    name: 'Engelli',
    minWidth: '175px',
    sortable: false,
    sortField: 'disability',
    selector: row => row.disability,
    cell: row => row.disability
  },
  {
    name: 'Emekli',
    minWidth: '175px',
    sortable: false,
    sortField: 'retirement',
    selector: row => row.retirement,
    cell: row => row.retirement
  }
]
