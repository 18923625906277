// ** Reactstrap Imports
import {Fragment, useEffect, useState, useCallback} from 'react'
import {
    Label,
    Button,
    Card,
    CardBody,
    Col,
    Row,
    DropdownItem,
    TabContent,
    TabPane,
    Nav,
    NavItem,
    NavLink,
    Progress,
    Table
} from 'reactstrap'

import {useDispatch, useSelector} from 'react-redux'
import {FileText, CheckCircle, XCircle} from 'react-feather'
import {DataGrid, trTR} from '@mui/x-data-grid'

import FilePopup from './FilePopup'
import SelectPeriodPopup from './SelectPeriodPopup'
import MinimumSupportPopup from './MinimumSupportPopup'
import {
    getCompanyList,
    getIncentiveProcess,
    showHideFilePopup,
    showHideMinimumSupportPopup,
    getIncentiveFileUploadProcess,
    handleCancel,
    getDepositMosipList,
    downloadDepositMosipExcel,
    depositMosipListCreate,
    getMinimumSupportList,
    downloadMinimumSupportExcel,
    showSelectPeriodPopup,
    getCompanyProcess,
    showHideIncentiveQuestioningFilePopup,
    getIncentiveQuestioningRequestsForCompany,
    getIncentiveQuestioningResultFile,
    incentiveEfficiencyAnalysis, downloadSchedulerExcel
} from './store'
import Select from 'react-select'
import * as React from "react"
import {Box, CircularProgress, IconButton, Typography} from "@mui/material"
import {BsArrowCounterclockwise, BsFiletypeXls} from "react-icons/bs"
import PropTypes from "prop-types"
import IncentiveQuestioningFilePopup from './IncentiveQuestioningFilePopup'

const columns = [
    {field: 'name', headerName: 'İşyeri', filterable: false, sortable: false, minWidth: 120, flex: 1},
    {field: 'period', headerName: 'Period', filterable: false, sortable: false, minWidth: 120, flex: 1},
    {
        field: 'totalWorker',
        headerName: 'Top. Tanımlanacak Personel',
        filterable: false,
        sortable: false,
        minWidth: 120,
        flex: 1
    },
    {
        field: 'successWorker',
        headerName: 'Top. Tanımlanan Personel',
        filterable: false,
        sortable: false,
        minWidth: 120,
        flex: 1
    },
    {
        field: 'status',
        headerName: 'Durum',
        filterable: false,
        sortable: true,
        minWidth: 120,
        flex: 1
    },
    {
        field: 'description',
        headerName: 'Açıklama',
        filterable: false,
        sortable: true,
        minWidth: 120,
        flex: 1
    }
]


const Incentive = () => {
    const [active, setActive] = useState('1')
    const toggle = tab => {
        if (active !== tab) {
            setActive(tab)
        }
    }
    const dispatch = useDispatch()
    const store = useSelector(state => state.incentive)
    const authStore = useSelector(state => state.auth)

    const incentiveFilterOptions = [
        {value: '0', label: 'Hepsi'},
        {value: '1', label: '1'},
        {value: '5', label: '5'},
        {value: '10', label: '10'}
    ]
    const [selectedIncentiveFilter, setSelectedIncentiveFilter] = useState(incentiveFilterOptions[1])

    const toggleFilePopup = () => dispatch(showHideFilePopup(!store.filePopupOpen))
    const toggleIncentiveQuestioningFilePopup = () => dispatch(showHideIncentiveQuestioningFilePopup(!store.incentiveQuestioningFilePopup))
    const toggleMinimumSupportPopup = () => dispatch(showHideMinimumSupportPopup(true))
    const toggleSelectPeriod = () => dispatch(showSelectPeriodPopup(!store.selectPeriodPopupOpen))

    const handleRefresh = useCallback(() => {
        dispatch(getIncentiveFileUploadProcess(selectedIncentiveFilter))
    }, [selectedIncentiveFilter])

    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{position: 'relative', display: 'inline-flex'}}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        )
    }

    CircularProgressWithLabel.propTypes = {
        value: PropTypes.number.isRequired
    }

    const downloadXml = (file) => {
        const link = document.createElement('a')
        const filename = `${store.incentiveProcess.period}-incentive.xlsx`
        link.setAttribute('href', file)
        link.setAttribute('target', '_blank')
        link.setAttribute('rel', 'noopener noreferrer')
        link.setAttribute('download', filename)
        link.click()
    }

    function convertToPeriodName(dateStr) {

        if (!dateStr) return "Cari Dönem Hesaplama sonucu bulunamadı ..."
        // Tarih dizgesini aylık ve yıllık değerlere ayırma
        const [year, month] = dateStr.split('/')

        // Ayların İngilizce isimleri
        const monthNames = [
            'Ocak',
            'Şubat',
            'Mart',
            'Nisan',
            'Mayıs',
            'Haziran',
            'Temmuz',
            'Ağustos',
            'Eylül',
            'Ekim',
            'Kasım',
            'Aralık'
        ]

        // Aylık değeri (string) sayısal değere çevirme
        const monthNumber = parseInt(month, 10)

        // Ayların İngilizce isimlerinden seçilen ayın ismini alma
        const monthName = monthNames[monthNumber - 1]

        // Cari dönem ismini oluşturma ve geriye döndürme
        return `${monthName} ${year} Cari Dönemi Hesaplama sonucu`
    }


    const incentiveFilterOptionsStyles = {
        control: (base) => ({
            ...base,
            width: '100px'  // İşte burada genişlik ayarlanıyor
        })
    }
    useEffect(() => {
        dispatch(getCompanyList())
        dispatch(getIncentiveProcess(null))
        dispatch(getIncentiveFileUploadProcess(selectedIncentiveFilter))
        dispatch(getDepositMosipList(authStore.selectedCompanyGroup))
        dispatch(getMinimumSupportList(authStore.selectedCompanyGroup))
        dispatch(getIncentiveQuestioningRequestsForCompany(authStore.selectedCompanyGroup))
    }, [dispatch, authStore.selectedCompanyGroup])
    useEffect(() => {
        dispatch(handleRefresh)
    }, [handleRefresh])
    useEffect(() => {
        if (active === '3') {
            dispatch(getCompanyProcess(authStore.selectedCompanyGroup))
        }
    }, [active, authStore.selectedCompanyGroup])

    const handleGetIncentiveQuestioningResultFile = (schedulerId) => {
        dispatch(getIncentiveQuestioningResultFile(schedulerId))
    }

    return (
        <Fragment>
            <Nav tabs>
                <NavItem>
                    <NavLink
                        active={active === '1'}
                        onClick={() => {
                            toggle('1')
                        }}
                    >
                        Teşvik İşlemleri
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '2'}
                        onClick={() => {
                            toggle('2')
                        }}
                    >
                        Teşvik Sorgulama
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '3'}
                        onClick={() => {
                            toggle('3')
                        }}
                    >
                        Dosya Yükleme Durumu
                    </NavLink>
                </NavItem>
                <NavItem>
                    <NavLink
                        active={active === '4'}
                        onClick={() => {
                            toggle('4')
                        }}
                    >
                        Tanımlama Kuyruğu Durumu
                    </NavLink>
                </NavItem>

            </Nav>
            <TabContent className='py-50' activeTab={active}>
                <TabPane tabId='1'>
                    <Card>
                        <Row>
                            <Col xl={2} md={6} className='d-flex align-items-center p-2'>
                                <Row>
                                    <Col className='d-flex align-items-center p-0'>
                                        <CardBody className='text-sm text-center ps-sm-2'>
                                            <div className='align-items-center w-100'>
                                                <Button className='mb-1' color='primary'
                                                        onClick={toggleSelectPeriod}>
                                                    Tanımlama Yap
                                                </Button>
                                                <Button className='ps-sm-0 mb-1' color='primary'
                                                        onClick={toggleFilePopup}>
                                                    Teşvik kontrol Xml/Excel Yükleme
                                                </Button>
                                                <Button className='ps-sm-0 mb-1' color='primary'
                                                        disabled={!store.incentiveProcess || !store.incentiveProcess.excelReportFileDownloadUrl}
                                                        onClick={() => downloadXml(store.incentiveProcess.excelReportFileDownloadUrl)}>
                                                    Teşvik kontrol Sonuç EXCEL Rapor İndir
                                                </Button>
                                                <Button className='ps-sm-0 mb-1' color='primary'
                                                        disabled={!store.incentiveProcess || !store.incentiveProcess.calculatedXmlFileDownloadUrl}
                                                        onClick={() => downloadXml(store.incentiveProcess.calculatedXmlFileDownloadUrl)}>
                                                    Teşvik kontrol Sonuç XML Dosya İndir
                                                </Button>
                                                <Button className='mb-1' color='primary'
                                                        onClick={() => dispatch(incentiveEfficiencyAnalysis(authStore.selectedCompanyGroup))}>
                                                    Teşvik Verimlilik Analizi
                                                </Button>
                                            </div>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Col>
                            <Col xl={10} md={10} className='align-items-top p-2'>
                                <Row>
                                    <Col className='d-flex align-items-left p-0'>
                                        <CardBody className='text-left ps-sm-5'>
                                            <table className='w-100'>
                                                <thead>
                                                <tr>
                                                    <th>{convertToPeriodName(store.incentiveProcess ? store.incentiveProcess.period : null)}</th>
                                                </tr>
                                                </thead>

                                                {store.incentiveProcess ? (
                                                    <tbody>
                                                    {store.incentiveProcess.baseXmlFileDownloadUrl ? (
                                                        <tr>
                                                            <td className='fw-bolder'>Yüklenen Dosya:</td>
                                                            <td className='fw-bolder'>
                                                                <Button color='flat-primary'
                                                                        onClick={() => downloadXml(store.incentiveProcess.baseXmlFileDownloadUrl)}>
                                                                    <FileText className='font-small-4 me-50'/>
                                                                    <span className='align-middle'>XML</span>
                                                                </Button>
                                                            </td>
                                                        </tr>
                                                    ) : null}
                                                    <tr>
                                                        <td className='fw-thin'>Dönem:</td>
                                                        <td className='fw-thin'>{store.incentiveProcess.period} </td>
                                                    </tr>
                                                    <tr>
                                                        <td className='fw-thin'>İşlem Tarihi:</td>
                                                        <td className='fw-thin'>{store.incentiveProcess.processDate}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='fw-thin'>Personel Sayısı:</td>
                                                        <td className='fw-thin'>{store.incentiveProcess.workerCount}</td>
                                                    </tr>
                                                    <tr>
                                                        <td className='fw-thin'>Teşvik Tutarı:</td>
                                                        <td className='fw-thin'>{store.incentiveProcess.incentiveAmount}</td>
                                                    </tr>
                                                    </tbody>
                                                ) : null}
                                            </table>
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                        <FilePopup open={store.filePopupOpen} toggleFilePopup={toggleFilePopup}/>
                        <SelectPeriodPopup open={store.selectPeriodPopupOpen} toggleSelectPeriod={toggleSelectPeriod}/>
                    </Card>
                    <Card>
                        <Row className='d-flex align-items-center p-1'>
                            <Label className='form-label' for='period'>
                                <span className='bold ps-sm-2'>Dosya Durumu</span>
                                {store.incentiveProcess ? (
                                    store.incentiveProcess.errorList && store.incentiveProcess.errorList.length > 0 ? (
                                        <div className="ps-sm-2 mt-2" style={{overflowX: 'auto !important'}}>
                                            <Table responsive>
                                                <thead>
                                                <th scope='col' className='text-nowrap'> Hata Durumu</th>
                                                </thead>
                                                <tbody>
                                                {store.incentiveProcess.errorList.filter(err => err !== undefined).map((err, index) => (
                                                    <tr key={index}>
                                                        <td className='text-nowrap'>{err}</td>
                                                    </tr>
                                                ))}
                                                </tbody>
                                            </Table>
                                        </div>) : (
                                        <div>Hata bulunmamaktadır.</div>)
                                ) : (<div>Veri yükleniyor...</div>)}
                            </Label>
                        </Row>
                    </Card>
                    <Card className='mt-5'><Row className='d-flex align-items-center p-1'>
                        <Label className='form-label' for='period'>
                            <span className='bold ps-sm-2'>Emanet ve mosip durumu</span>
                            <div className="ps-sm-2 mt-2" style={{overflowX: 'auto !important'}}>
                                <Table responsive>
                                    <thead>
                                    <th scope='col' className='text-nowrap'></th>
                                    <th scope='col' className='text-nowrap'> Tarih</th>
                                    <th scope='col' className='text-nowrap'> İşlem Durumu</th>
                                    <th scope='col' className='text-nowrap'> Sonuç Rapor</th>
                                    </thead>
                                    <tbody>
                                    {store.depositMosipData.length !== 0 ? (
                                        (<>
                                            {store.depositMosipData.map((item, index) => (
                                                <tr key={index}>
                                                    <td className='text-nowrap'>
                                                        <Button size='60'
                                                                onClick={() => dispatch(depositMosipListCreate(authStore.selectedCompanyGroup))}>
                                                            Kontrol Et
                                                        </Button>
                                                    </td>
                                                    <td className='text-nowrap'>{item.date}</td>
                                                    <td className='text-nowrap'>
                                                        {parseInt(item.succesPercentInt) >= 100 ? (
                                                            <CheckCircle color="green" size={40}/>) : (
                                                            <CircularProgressWithLabel
                                                                value={parseInt(item.succesPercentInt)}/>)
                                                        }
                                                    </td>
                                                    {
                                                        parseInt(item.succesPercentInt) >= 100 ? (<>
                                                            <td className='text-nowrap'>
                                                                <IconButton size='60'
                                                                            onClick={() => dispatch(downloadDepositMosipExcel(authStore.selectedCompanyGroup))}>
                                                                    <BsFiletypeXls size={30}/>
                                                                </IconButton>
                                                            </td>
                                                        </>) : (<>
                                                            <td className='text-nowrap'>-</td>
                                                        </>)
                                                    }

                                                </tr>
                                            ))}
                                        </>)
                                    ) : (<tr key={1}>
                                        <td className='text-nowrap'>
                                            <Button size='60'
                                                    onClick={() => dispatch(depositMosipListCreate(authStore.selectedCompanyGroup))}>
                                                Kontrol Et
                                            </Button>
                                        </td>
                                        <td className='text-nowrap'>-</td>
                                        <td className='text-nowrap'>-</td>
                                        <td className='text-nowrap'>-</td>
                                    </tr>)}
                                    </tbody>
                                </Table>
                            </div>
                        </Label>
                    </Row>
                    </Card>
                    <Card className='mt-5'>
                        <Row className='d-flex align-items-center p-1'>
                            <Label className='form-label' for='period'>
                                <span className='bold ps-sm-2'>6661 Asgari Destek</span>
                                <div className="ps-sm-2 mt-2" style={{overflowX: 'auto !important'}}>
                                    <Table responsive>
                                        <thead>
                                        <th scope='col' className='text-nowrap'></th>
                                        <th scope='col' className='text-nowrap'> Tarih</th>
                                        <th scope='col' className='text-nowrap'> İşlem Durumu</th>
                                        <th scope='col' className='text-nowrap'> Sonuç Rapor</th>
                                        </thead>
                                        <tbody>
                                        {store.minimumSupportData.length !== 0 ? (
                                            (<>
                                                {store.minimumSupportData.map((item, index) => (
                                                    <tr key={index}>
                                                        <td className='text-nowrap'>
                                                            <Button size='60'
                                                                    onClick={() => toggleMinimumSupportPopup()}>
                                                                Kontrol Et
                                                            </Button>
                                                        </td>
                                                        <td className='text-nowrap'>{item.date}</td>
                                                        <td className='text-nowrap'>
                                                            {parseInt(item.succesPercentInt) >= 100 ? (
                                                                <CheckCircle color="green" size={40}/>) : (
                                                                <CircularProgressWithLabel
                                                                    value={parseInt(item.succesPercentInt)}/>)
                                                            }
                                                        </td>
                                                        {
                                                            parseInt(item.succesPercentInt) >= 100 ? (<>
                                                                <td className='text-nowrap'>
                                                                    <IconButton size='60'
                                                                                onClick={() => dispatch(downloadMinimumSupportExcel(authStore.selectedCompanyGroup))}>
                                                                        <BsFiletypeXls size={30}/>
                                                                    </IconButton>
                                                                </td>
                                                            </>) : (<>
                                                                <td className='text-nowrap'>-</td>
                                                            </>)
                                                        }
                                                    </tr>
                                                ))}
                                            </>)
                                        ) : (<tr key={1}>
                                            <td className='text-nowrap'>
                                                <Button size='60' onClick={() => toggleMinimumSupportPopup()}>
                                                    Kontrol Et
                                                </Button>
                                            </td>
                                            <td className='text-nowrap'>-</td>
                                            <td className='text-nowrap'>-</td>
                                            <td className='text-nowrap'>-</td>
                                        </tr>)}
                                        </tbody>
                                    </Table>
                                </div>
                            </Label>
                        </Row>
                        <MinimumSupportPopup open={store.minimumSupportPopupOpen}
                                             toggleMinimumSupportPopup={toggleMinimumSupportPopup}/>
                    </Card>
                </TabPane>
                <TabPane tabId='2'>
                <Card>
                        <Row>
                            {/* <Col xl={2} md={6} className='d-flex align-items-center p-2'> */}
                                <Row>
                                        <CardBody className='text-sm text-center ps-sm-2'>
                                            <div className='align-items-center w-100'>
                                                <Button className='mb-1' color='primary'
                                                        onClick={toggleIncentiveQuestioningFilePopup}>
                                                   Potansiyel Teşvik Sorgula
                                                </Button>

                                            </div>
                                        </CardBody>
                                </Row>
                            {/* </Col> */}
                        </Row>

                        <IncentiveQuestioningFilePopup open={store.incentiveQuestioningFilePopup} toggleFilePopup={toggleIncentiveQuestioningFilePopup}/>
                    </Card>
                    <Card className='mt-5'><Row className='d-flex align-items-center p-1'>
                        <Label className='form-label' for='period'>
                            <span className='bold ps-sm-2'>Teşvik Sorgulama Durumu</span>
                            <div className="ps-sm-2 mt-2" style={{overflowX: 'auto !important'}}>
                                <Table responsive>
                                    <thead>
                                    <th scope='col' className='text-nowrap'></th>
                                    <th scope='col' className='text-nowrap'> Tarih</th>
                                    <th scope='col' className='text-nowrap'> Sonuç Rapor</th>
                                    </thead>
                                    <tbody>
                                    {store.incentiveQuestioningRequests.length !== 0 ? (
                                        (<>
                                            {store.incentiveQuestioningRequests.map((item, index) => (
                                                <tr key={index}>
                                                    <td className='text-nowrap'>
                                                            Teşvik Sorgulama
                                                    </td>
                                                    <td className='text-nowrap'>
                                                        {item.createdAt}
                                                    </td>
                                                    <td className='text-nowrap'>
                                                        <Button onClick={() => handleGetIncentiveQuestioningResultFile(item.schedulerId)}  >
                                                        <FileText className='font-small-4 me-50'/>
                                                        <span className='align-middle'>Sonuç Rapor</span>
                                                        </Button>
                                                    </td>
                                                </tr>
                                            ))}
                                        </>)
                                    ) : (<tr key={1}>
                                        <td className='text-nowrap'>
                                            Görüntülenecek veri bulunamadı.
                                        </td>
                                        <td className='text-nowrap'>-</td>
                                        <td className='text-nowrap'>-</td>
                                    </tr>)}
                                    </tbody>
                                </Table>
                            </div>
                        </Label>
                    </Row>
                    </Card>
                </TabPane>
                <TabPane tabId='3'>
                    <Card>
                        <Row>
                            <Col xl={2} md={6} className='d-flex align-items-center p-2'>
                                <Row>
                                    <Col className='d-flex align-items-center p-0'>
                                        <CardBody className='text-sm text-center align-items-center ps-sm-2 a'>
                                            <div className='d-flex  justify-content-center w-100'>
                                                <Button className='ps-sm-2 mb-1 me-1' color='primary'
                                                        onClick={() => dispatch(handleRefresh)}>
                                                    Yenile
                                                </Button>
                                                <Select
                                                    isClearable={false}
                                                    options={incentiveFilterOptions}
                                                    defaultValue={incentiveFilterOptions[1]}
                                                    onChange={setSelectedIncentiveFilter}
                                                    className='react-select'
                                                    classNamePrefix='select'
                                                    isSearchable={false}
                                                    styles={incentiveFilterOptionsStyles}
                                                />
                                            </div>


                                            {store.incentiveFileUploadProcess ? (
                                                store.incentiveFileUploadProcess.processList && store.incentiveFileUploadProcess.processList.length > 0 ? (
                                                    <div className="ps-sm-4" style={{overflowX: 'auto !important'}}>
                                                        <Table responsive>
                                                            <thead>
                                                            <tr>
                                                                <th scope='col' className='text-nowrap'>İptal Et</th>
                                                                <th scope='col' className='text-nowrap'>Dosya Adı</th>
                                                                <th scope='col' className='text-nowrap'>Yükleme Durumu
                                                                </th>
                                                                <th scope='col' className='text-nowrap'>Başlangıç
                                                                    Tarihi
                                                                </th>
                                                                <th scope='col' className='text-nowrap'>Bitiş Tarihi
                                                                </th>
                                                                <th scope='col' className='text-nowrap'>Periyot</th>
                                                            </tr>
                                                            </thead>
                                                            <tbody>
                                                            {store.incentiveFileUploadProcess.processList.filter(item => {
                                                                const value = parseInt(item.succesPercent, 10)
                                                                return !isNaN(value)
                                                            }).map((item, index) => (
                                                                <tr key={index}>
                                                                    <td scope='col' className='text-nowrap'>
                                                                        {parseInt(item.succesPercent) !== 100 && item.status !== "2" ? <XCircle
                                                                                onClick={() => dispatch(handleCancel(item.id))}
                                                                                size={18}
                                                                                className='cursor-pointer me-1'/> : "-"}
                                                                    </td>
                                                                    <td className='text-wrap'>{item.fileName}</td>
                                                                    <td className='text-nowrap'>{parseInt(item.succesPercent) >= 100 ? (
                                                                        <CheckCircle color="green"
                                                                                     size={30}/>) : item.status === "2" ? (
                                                                        <XCircle color="red" size={30}/>) : (
                                                                        <Progress animated striped
                                                                                  className='progress-bar-success'
                                                                                  value={parseInt(item.succesPercent)}/>)}
                                                                    </td>
                                                                    <td className='text-nowrap'>{item.startDate}</td>
                                                                    <td className='text-nowrap'>{item.endDate}</td>
                                                                    <td className='text-nowrap'>{item.period}</td>
                                                                </tr>
                                                            ))}
                                                            </tbody>
                                                        </Table></div>
                                                ) : (
                                                    <div>Yüklenmiş bir teşvik dosyası bulunmamaktadır.</div>
                                                )
                                            ) : (
                                                <div>Veri yükleniyor...</div> // Bu, veri yüklenirken gösterilecek, eğer gerekliyse
                                            )}
                                        </CardBody>
                                    </Col>
                                </Row>
                            </Col>
                        </Row>
                    </Card>
                </TabPane>
                <TabPane tabId='4'>
                    <Card>
                        <Row>
                            <Col className='d-flex align-items-center p-0'>
                                <CardBody className='text-sm text-center align-items-center ps-sm-2 a'>
                                    <div className='d-flex  justify-content-center w-100'>
                                        <Button className='ps-sm-2 mb-1 me-1' color='primary'
                                                onClick={() => dispatch(getCompanyProcess(authStore.selectedCompanyGroup))}>
                                            Yenile
                                        </Button>
                                        <Button className='ps-sm-2 mb-1 me-1' color='primary'
                                                onClick={() => dispatch(downloadSchedulerExcel(authStore.selectedCompanyGroup))}>
                                            Excel Rapor İndir
                                        </Button>
                                    </div>
                                </CardBody>
                            </Col>
                        </Row>
                        <Row>
                            <Col xl={12} className='d-flex align-items-center p-2'>
                                <DataGrid
                                    getRowId={(row) => row.id}
                                    rows={store.companyProcess}
                                    rowCount={store.companyProcess.length}
                                    columns={columns}
                                    pagination
                                    initialState={{
                                        pagination: {
                                            paginationModel: {page: 0, pageSize: 10}
                                        }
                                    }}
                                    pageSizeOptions={[5, 10, 25, 50, 100]}
                                    localeText={trTR.components.MuiDataGrid.defaultProps.localeText}
                                />
                            </Col>
                        </Row>
                    </Card>
                </TabPane>

            </TabContent>

        </Fragment>
    )
}

export default Incentive
