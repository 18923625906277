// ** User List Component
import '@styles/react/apps/app-users.scss'
import {Button, Card, CardBody, CardHeader, CardTitle, Spinner, Table} from "reactstrap"
import {CheckCircle} from "react-feather"
import {Box, CircularProgress, IconButton, Typography} from "@mui/material"
import {BsArrowCounterclockwise, BsFiletypeXls} from "react-icons/bs"
import React, {Fragment, useEffect, useState} from "react"
import {filePopupState, incentiveFilePopupState, getSchedulerList, getSchedulerListV2, showProcessData, downloadSchedulerExcel, secondPhasePopupState, getSchedulerListV3, downloadComparisonExcel, downloadFinalResultExcel} from '../store'
import {useDispatch, useSelector} from "react-redux"
import FilePopup from "../view/FilePopup"
import IncentiveFilePopup from "../view/IncentiveFilePopup"
import Tesvik_Tanimlama_Ornek from "../../../../assets/incentive/Tesvik_Tanimlama_Ornek.xlsx"
import PropTypes from "prop-types"
import SecondPhasePopup from './SecondPhasePopup'

const IncentiveDefinationDelete = () => {
    const dispatch = useDispatch()
    const store = useSelector(state => state.incentiveDefinationDelete)
    const [combinedData, setCombinedData] = useState([])
    const isResultExcelLoading = useSelector(state => state.incentiveDefinationDelete.finalResultExcelLoading)
    useEffect(() => {
      // Combine the data first
      const newCombinedData = [...store.schedulerData, ...store.schedulerDataV3]

      // Then sort it by id in descending order (highest id first)
      const sortedData = newCombinedData.sort((a, b) => b.id - a.id)

      // Then set the state once with the sorted data
      setCombinedData(sortedData)
    }, [store.schedulerData, store.schedulerDataV3])
    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{position: 'relative', display: 'inline-flex'}}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        )
    }

    CircularProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate variant.
         * Value between 0 and 100.
         * @default 0
         */
        value: PropTypes.number.isRequired
    }

    const _downloadExcel = () => {
        const link = document.createElement("a")
        link.setAttribute("href", Tesvik_Tanimlama_Ornek)
        link.setAttribute("download", "Tesvik_Tanimlama_Ornek.xlsx")
        link.click()
    }

    useEffect(() => {
        dispatch(getSchedulerList(30))
        dispatch(getSchedulerListV2(31))
        dispatch(getSchedulerListV3(36))
        console.log(store.data)
    }, [])

    return (
        <Fragment>
            <Card>
                <CardHeader className="d-flex justify-content-between align-items-center">
                    <CardTitle tag='h4' className="m-0">Silinecek TCKN'lerin Tespiti</CardTitle>
                    <div className='d-flex flex-wrap'>

                    <Button className='add-new-user me-1' style={{width: "180px"}} color='primary'
                                onClick={() => dispatch(downloadFinalResultExcel())}
                                disabled={isResultExcelLoading}>
                            {isResultExcelLoading ? <>
                            <Spinner size="sm" color="light" className={"me-1"} />
                                Hazırlanıyor... Lütfen Bekleyiniz
                            </> : "Sonuç Exceli"}
                        </Button>
                        <Button className='add-new-user me-1' style={{width: "180px"}} color='primary'
                                onClick={() => dispatch(downloadComparisonExcel())}>
                            Müşteri Tarama
                        </Button>
                        <Button className='add-new-user me-1' style={{width: "180px"}} color='primary'
                                onClick={() => dispatch(secondPhasePopupState(true))}>
                            Şirket İçi Analiz
                        </Button>
                        <Button className='add-new-user me-1' style={{width: "180px"}} color='primary'
                                onClick={() => dispatch(filePopupState(true))}>
                            Tanımlama Sil Analizi
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <Table responsive>
                        <thead>
                        <th scope='col' className='text-nowrap'> İşlem ID</th>
                        <th scope='col' className='text-nowrap'> Tarih</th>
                        <th scope='col' className='text-nowrap'> İşlem Tipi</th>
                        <th scope='col' className='text-nowrap'> İşlem Durumu</th>
                        <th scope='col' className='text-nowrap'> Sonuç Rapor</th>
                        </thead>
                        <tbody>
                        {combinedData.map((item, index) => (
                            <tr key={index}>
                                <td className='text-nowrap'>
                                    <button onClick={() => dispatch(showProcessData({
                                        id: item.id,
                                        name: 'IncentiveDefinationProcessWorkers',
                                        text: item.id
                                    }))}
                                            className="btn btn-outline-primary"
                                            id={item.id}>{item.id}
                                    </button>
                                </td>
                                <td className='text-nowrap'>{item.date}</td>
                                <td className='text-nowrap'>{item.schedulerType === 36 ? "Şirkette Tanımlananların Tekrar Sorgusu" :  "Tüm XML"}</td>
                                <td className='text-nowrap'>
                                    {item.succesPercentInt >= 100 ? (
                                        <CheckCircle color="green" size={40}/>) : (
                                        <CircularProgressWithLabel
                                            value={parseInt(item.succesPercentInt)}/>)
                                    }
                                </td>
                                {
                                    item.succesPercentInt >= 100 ? (<>
                                        <td className='text-nowrap'>
                                            <IconButton size='60'
                                                        onClick={() => dispatch(downloadSchedulerExcel(item.id))}>
                                                <BsFiletypeXls size={30}/>
                                            </IconButton>
                                        </td>
                                    </>) : (<>
                                        <td className='text-nowrap'>-</td>
                                    </>)
                                }

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            <Card>
                <CardHeader className="d-flex justify-content-between align-items-center">
                    <CardTitle tag='h4' className="m-0">Tanımlama Yap</CardTitle>
                    <div>
                        <Button className='add-new-user me-1' style={{width: "180px"}} color='success'
                                onClick={() => _downloadExcel()}>
                            Örnek Excel İndir
                        </Button>
                        <Button className='add-new-user me-1' style={{width: "180px"}} color='primary'
                                onClick={() => dispatch(incentiveFilePopupState(true))}>
                            Tanımlama yap
                        </Button>
                    </div>
                </CardHeader>
                <CardBody>
                    <Table responsive>
                        <thead>
                        <th scope='col' className='text-nowrap'> İşlem ID</th>
                        <th scope='col' className='text-nowrap'> Tarih</th>
                        <th scope='col' className='text-nowrap'> İşlem Durumu</th>
                        <th scope='col' className='text-nowrap'> Sonuç Rapor</th>
                        </thead>
                        <tbody>
                        {store.schedulerDataV2.map((item, index) => (
                            <tr key={index}>
                                <td className='text-nowrap'>
                                    <button onClick={() => dispatch(showProcessData({
                                        id: item.id,
                                        name: 'IncentiveDefinationProcessWorkers',
                                        text: item.id
                                    }))}
                                            className="btn btn-outline-primary"
                                            id={item.id}>{item.id}
                                    </button>
                                </td>
                                <td className='text-nowrap'>{item.date}</td>
                                <td className='text-nowrap'>
                                    {item.succesPercentInt >= 100 ? (
                                        <CheckCircle color="green" size={40}/>) : (
                                        <CircularProgressWithLabel
                                            value={parseInt(item.succesPercentInt)}/>)
                                    }
                                </td>
                                {
                                    item.succesPercentInt >= 100 ? (<>
                                        <td className='text-nowrap'>
                                            <IconButton size='60'
                                                        onClick={() => dispatch(downloadSchedulerExcel(item.id))}>
                                                <BsFiletypeXls size={30}/>
                                            </IconButton>
                                        </td>
                                    </>) : (<>
                                        <td className='text-nowrap'>-</td>
                                    </>)
                                }
                                {
                                    item.schedulerStatus !== 1 ? (<>
                                        <td className='text-nowrap'>
                                            <IconButton size='60' disabled={store.retryButtonDisabled}
                                                        onClick={() => dispatch(reTry(item.id))}>
                                                <BsArrowCounterclockwise size={30}/>
                                            </IconButton>
                                        </td>
                                    </>) : null
                                }

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
            <FilePopup open={store.filePopupStatus}/>
            <IncentiveFilePopup open={store.incentiveFilePopupStatus}/>
            <SecondPhasePopup open={store.secondPhasePopupStatus}/>
        </Fragment>
    )
}

export default IncentiveDefinationDelete