// ** Redux Imports
import { createAsyncThunk, createSlice } from '@reduxjs/toolkit'
import { createTab } from '../../../../redux/tabManagement'
// ** Axios Imports
import axios from 'axios'

export const getData = createAsyncThunk('approvedVisitList/getData', async (params, { getState }) => {
  params.visitType = 0
  params.CompanyGroupId = params.CompanyGroupId = getState().auth.selectedCompanyGroup
  let url = `/api/visits/0?`

  const selectedCompanyIds = getState().visit.selectedCompanyIds
  const selectedReportStartDateYears = getState().visit.selectedReportStartDateYears
  const selectedReportStartDateMonths = getState().visit.selectedReportStartDateMonths
  const selectedReportEndDateYears = getState().visit.selectedReportEndDateYears
  const selectedReportEndDateMonths = getState().visit.selectedReportEndDateMonths

  if (selectedCompanyIds.length > 0) {
      url += selectedCompanyIds.map(id => `&SelectedCompanyIds=${id}`).join('')
      console.log("urlapproved", url)
  }

  if (selectedReportStartDateYears.length > 0) {
      url += selectedReportStartDateYears.map(id => `&SelectedReportStartDateYears=${id}`).join('')
      console.log("urlapproved", url)

  }

  if (selectedReportStartDateMonths.length > 0) {
      url += selectedReportStartDateMonths.map(id => `&SelectedReportStartDateMonths=${id}`).join('')
      console.log("urlapproved", url)

  }

  if (selectedReportEndDateYears.length > 0) {
      url += selectedReportEndDateYears.map(id => `&SelectedReportEndDateYears=${id}`).join('')
      console.log("urlapproved", url)

  }

  if (selectedReportEndDateMonths.length > 0) {
      url += selectedReportEndDateMonths.map(id => `&SelectedReportEndDateMonths=${id}`).join('')
      console.log("urlapproved", url)
  }

  const response = await axios.get(url, { params })
  return {
    params,
    data: response.data.visits,
    totalPages: response.data.pageCount
  }
})

export const getDetail = createAsyncThunk('approvedVisit/getDetail', async (data) => {
  const response = await axios.get(`/api/visits/getApprovedVisitDetail?medulaReportId=${data.reportId}`)
  return { popupStatus: true, responseData: response }
})

export const ToggleVisitHtmlImagePopup = createAsyncThunk('approvedVisit/ToggleVisitHtmlImagePopup', (data) => {
  return data
})

export const getHtmlFile = createAsyncThunk('approvedVisit/getHtmlFile', async (data, { getState }) => {
  const response = await axios.get(`/api/visits/HtmlFile`, {
      responseType: 'arraybuffer',
      'Content-Type': 'blob',
      params: { ReportTrackingNo: getState().approvedVisits.toggleVisitHtmlImagePopup.visitReportNumber, VisitType: 0}
  })
  if (response.status === 200) {
      const url = window.URL.createObjectURL(new Blob([response.data]))
      const link = document.createElement('a')
      link.href = url
      link.setAttribute('download', 'HtmlFile.html')
      document.body.appendChild(link)
      link.click()
  }

})

export const detailPopupState = createAsyncThunk('approvedVisit/detailPopupState', (data, { dispatch }) => {
  dispatch(getDetail(data))
})

export const detailPopupStateClose = createAsyncThunk('approvedVisit/detailPopupStateClose', (data) => {
  return data
})

export const approvedReportCancel = createAsyncThunk('approvedVisit/approvedReportCancel', async (data, { dispatch, getState }) => {
  // data.companyId = 7
  data.CompanyGroupId = getState().auth.selectedCompanyGroup
  const response = await axios.post('/api/visits/cancelDeclaration', data)
  await dispatch(getData(getState().approvedVisits.params))
  return response
})
export const getCompanyDetails = createAsyncThunk('user/getCompanyDetails', (data, {dispatch}) => {
  dispatch(createTab(data))
})
export const exportExcel = createAsyncThunk('approvedVisit/exportExcel', async (data, { getState }) => {
    console.log(data)
    const selectedCompanyIds = getState().visit.selectedCompanyIds
    const selectedReportStartDateYears = getState().visit.selectedReportStartDateYears
    const selectedReportStartDateMonths = getState().visit.selectedReportStartDateMonths
    const selectedReportEndDateYears = getState().visit.selectedReportEndDateYears
    const selectedReportEndDateMonths = getState().visit.selectedReportEndDateMonths
    let url = `/api/visits/approvedExport?`

    if (selectedCompanyIds.length > 0) {
        url += selectedCompanyIds.map(id => `&SelectedCompanyIds=${id}`).join('')
        console.log(url)
    }

    if (selectedReportStartDateYears.length > 0) {
        url += selectedReportStartDateYears.map(id => `&SelectedReportStartDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedReportStartDateMonths.length > 0) {
        url += selectedReportStartDateMonths.map(id => `&SelectedReportStartDateMonths=${id}`).join('')
        console.log(url)

    }

    if (selectedReportEndDateYears.length > 0) {
        url += selectedReportEndDateYears.map(id => `&SelectedReportEndDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedReportEndDateMonths.length > 0) {
        url += selectedReportEndDateMonths.map(id => `&SelectedReportEndDateMonths=${id}`).join('')
        console.log(url)
    }
    const response = await axios.get(url, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob',
        params: { CompanyGroupId: getState().auth.selectedCompanyGroup, Q: getState().workAccident.params.q }
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'onayli_vizite_listesi.xlsx')
        document.body.appendChild(link)
        link.click()
    }

})
export const approvedVisitsSlice = createSlice({
  name: 'approvedVisits',
  initialState: {
    data: [],
    total: 1,
    params: {},
    allData: [],
    detailPopupStatus: false,
    detailVisitData: [],
    toggleVisitHtmlImagePopup: null,
    isExportExcelLoading: false
  },
  reducers: {},
  extraReducers: builder => {
    builder
      .addCase(getData.fulfilled, (state, action) => {
        state.data = action.payload.data
        state.params = action.payload.params
        state.total = action.payload.totalPages
      })
      .addCase(detailPopupStateClose.fulfilled, (state, action) => {
        state.detailPopupStatus = action.payload
      })
      .addCase(getDetail.fulfilled, (state, action) => {
        state.detailPopupStatus = action.payload.popupStatus
        state.detailVisitData = action.payload.responseData
      })
      .addCase(ToggleVisitHtmlImagePopup.fulfilled, (state, action) => {
        state.toggleVisitHtmlImagePopup = action.payload
      })
      .addCase(exportExcel.pending, (state) => {
        state.isExportExcelLoading = true
      })
      .addCase(exportExcel.fulfilled, (state) => {
        state.isExportExcelLoading = false
      })
      .addCase(exportExcel.rejected, (state) => {
        state.isExportExcelLoading = false
      })
  }
})

export default approvedVisitsSlice.reducer
