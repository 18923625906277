// ** React Imports
import { Fragment, useEffect, useState } from 'react'

// ** Table Columns
import { columns } from './columns'

// ** Store & Actions
import { useDispatch, useSelector } from 'react-redux'
import { getData, exportExcel, ToggleVisitHtmlImagePopup } from '../store'

// ** Third Party Components
import DataTable from 'react-data-table-component'
import { ChevronDown, Copy, File, FileText, Grid, Printer, Share, Lock, Unlock, Columns } from 'react-feather'
import Flatpickr from 'react-flatpickr'
import ReactPaginate from 'react-paginate'

import ApprovedVisitDetailPopup from '../view/ApprovedVisitDetailPopup'
import VisitHtmlImagePopup from '../view/VisitHtmlImagePopup'

import flatpickrConfig from "@configs/flatpickrConfig"

// ** Reactstrap Imports
import {
  Button,
  Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input,
  Label, Row, Spinner, UncontrolledDropdown
} from 'reactstrap'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import "./column-fixer-styles.css"
import { clearFilters, exportVisitsZip } from '../../visit/store'
import { LuFilterX } from 'react-icons/lu'
import { FaRegFileZipper } from 'react-icons/fa6'

const STORAGE_KEY = 'approved-visit-list-columns'

const ColumnSelectionMenu = ({ columns, handleColumnChange, resetColumns }) => {
    const getColumnName = (col) => {
        if (typeof col.name === 'object' && col.name.props) {
            return col.name.props.name || 'Unknown'
        }
        return col.name
    }

    // Filter out the first 5 columns from the menu
    const selectableColumns = columns.slice(5)

    return (
        <UncontrolledDropdown className='column-selection-dropdown me-1'>
            <DropdownToggle color='secondary' outline className='btn-icon d-flex align-items-center' style={{marginLeft: 10, backgroundColor: 'transparent'}}>
                <Columns className='font-small-4 me-50' />
                <span>Kolonlar</span>
            </DropdownToggle>
            <DropdownMenu container="body" style={{
                minWidth: '250px',
                maxHeight: '400px',
                overflowY: 'auto'
            }}>
                <div className="px-2 py-1">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <h6 className="m-0">Görünür Kolonlar</h6>
                        <Button.Ripple
                            color="flat-secondary"
                            size="sm"
                            onClick={resetColumns}
                        >
                            Varsayılana Dön
                        </Button.Ripple>
                    </div>
                    {selectableColumns.map((col, index) => (
                        <div key={index} className="d-flex align-items-center py-1">
                            <Input
                                type="checkbox"
                                checked={!col.omit}
                                onChange={() => {
                                    const newColumns = [...columns]
                                    // Add 5 to index since we sliced off the first 5 columns
                                    newColumns[index + 5] = { ...col, omit: !col.omit }
                                    handleColumnChange(newColumns)
                                }}
                            />
                            <span className="ms-1">{getColumnName(col)}</span>
                        </div>
                    ))}
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

// ** Table Header
const CustomHeader = ({ store, handlePerPage, rowsPerPage, handleFilter, searchTerm, dispatch, hasFilters, isColumnsFixed, toggleColumnsFixed, columns, handleColumnChange, resetColumns }) => {
  const isExportZipLoading = useSelector(state => state.visit.isExportZipLoading)
  const isExportExcelLoading = useSelector(state => state.approvedVisits.isExportExcelLoading)
  const [showFixedColumnsButton, setShowFixedColumnsButton] = useState(true)

  useEffect(() => {
    const handleResize = () => {
        setShowFixedColumnsButton(window.innerWidth >= 992)
    }
    handleResize()
    window.addEventListener('resize', handleResize)
    return () => window.removeEventListener('resize', handleResize)
  }, [])

  // ** Clear filters when clicked
  const handleClearFilters = () => {
    dispatch(clearFilters())
    handleFilter("")  // Eğer bir arama filtresi de varsa onu da temizle
  }

  return (
    <div className='invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75'>
      <Row>
        <Col xl='6' className='d-flex align-items-center p-0'>
          <div className='d-flex flex-wrap align-items-center w-100'>
            <div className='d-flex align-items-center mb-sm-0 mb-1'>
              <label htmlFor='rows-per-page'>Toplam</label>
              <Input
                className='mx-50'
                type='select'
                id='rows-per-page'
                value={rowsPerPage}
                onChange={handlePerPage}
                style={{ width: '5rem' }}
              >
                <option value='10'>10</option>
                <option value='25'>25</option>
                <option value='50'>50</option>
              </Input>
              <label htmlFor='rows-per-page'>Kayıt Göster</label>
            </div>
            {showFixedColumnsButton && (
              <Button
                className='btn-icon ms-2 fixed-columns-toggle'
                color='flat-dark'
                onClick={toggleColumnsFixed}
              >
                {isColumnsFixed ? <Lock size={16}/> : <Unlock size={16}/>}
                <span className='ms-1'>{isColumnsFixed ? 'Sütunları Serbest Bırak' : 'Sütunları Sabitle'}</span>
              </Button>
            )}
          </div>
        </Col>
        <Col
          xl='6'
          className='d-flex flex-wrap align-items-sm-center justify-content-xl-end justify-content-start pe-xl-1 p-0 mt-xl-0 mt-1'
        >
          <div className='d-flex flex-wrap gap-1'>
            <div className='d-flex align-items-center mb-sm-0 mb-1'>
              <label className='mb-0' htmlFor='search-invoice'>
                Ara:
              </label>
              <Input
                id='search-invoice'
                className='ms-50'
                type='text'
                value={searchTerm}
                onChange={e => handleFilter(e.target.value)}
                style={{ minWidth: '150px' }}
              />
            </div>

            {hasFilters && (
              <Button
                className='btn-icon btn-gray d-flex align-items-center'
                style={{ backgroundColor: 'transparent', width: "160px"}}
                outline
                onClick={handleClearFilters}
              >
                <LuFilterX size={16} className={"me-50"}/>
                <span>Filtreleri Kaldır</span>
              </Button>
            )}

            <ColumnSelectionMenu
              columns={columns}
              handleColumnChange={handleColumnChange}
              resetColumns={resetColumns}
            />

            <Button
              onClick={() => dispatch(exportExcel(store.data))}
              className='btn-icon btn-gray d-flex align-items-center'
              outline
              disabled={isExportExcelLoading}
            >
              {isExportExcelLoading ? (
                <Spinner size="sm" color="primary" className={"me-50"} />
              ) : (
                <Grid className='font-small-4 me-50' />
              )}
              {isExportExcelLoading ? 'Hazırlanıyor...' : 'Excele Aktar'}
            </Button>

            <Button
              onClick={() => dispatch(exportVisitsZip({data:store.data, visitType: 0}))}
              className='btn-icon btn-gray d-flex align-items-center'
              outline
              disabled={isExportZipLoading}
            >
              {isExportZipLoading ? (
                <Spinner size="sm" color="primary" className={"me-50"} />
              ) : (
                <FaRegFileZipper className='font-small-4 me-50' />
              )}
              {isExportZipLoading ? 'Hazırlanıyor...' : 'Zipe Aktar'}
            </Button>
          </div>
        </Col>
      </Row>
    </div>
  )
}

const ApprovedVisitList = () => {
  // ** Store Vars
  const dispatch = useDispatch()
  const store = useSelector(state => state.approvedVisits)
  const authStore = useSelector(state => state.auth)
  // ** States
  const [sort, setSort] = useState('desc')
  const [searchTerm, setSearchTerm] = useState('')
  const [searchIdentificationNumber, setSearchIdentificationNumber] = useState('')
  const [searchName, setSearchName] = useState('')
  const [searchSurname, setSearchSurname] = useState('')
  const [searchEmployedDate, setSearchEmployedDate] = useState(null)
  const [currentPage, setCurrentPage] = useState(1)
  const [sortColumn, setSortColumn] = useState('id')
  const [rowsPerPage, setRowsPerPage] = useState(10)
  const [handleClearSelectedRows, setHandleClearSelectedRows] = useState(false)
  const toggleVisitHtmlImagePopup = () => dispatch(ToggleVisitHtmlImagePopup({ show: false, image: null }))
  const [isIsyeriDropdownOpen, setIsIsyeriDropdownOpen] = useState(false)
  const [isReportStartDateDropdownOpen, setIsReportStartDateDropdownOpen] = useState(false)
  const [isReportEndDateDropdownOpen, setIsReportEndDateDropdownOpen] = useState(false)
  const selectedCompanyIds = useSelector(state => state.visit.selectedCompanyIds)
  const selectedReportStartDateYears = useSelector(state => state.visit.selectedReportStartDateYears)
  const selectedReportStartDateMonths = useSelector(state => state.visit.selectedReportStartDateMonths)
  const selectedReportEndDateYears = useSelector(state => state.visit.selectedReportEndDateYears)
  const selectedReportEndDateMonths = useSelector(state => state.visit.selectedReportEndDateMonths)
  const hasFilters = selectedCompanyIds.length > 0 || selectedReportStartDateYears.length > 0 || selectedReportStartDateMonths.length > 0 || selectedReportEndDateYears.length > 0 || selectedReportEndDateMonths.length > 0
  const [isColumnsFixed, setIsColumnsFixed] = useState(false)

  const toggleIsyeriDropdown = () => setIsIsyeriDropdownOpen(!isIsyeriDropdownOpen)
  const toggleReportStartDateDropdown = () => setIsReportStartDateDropdownOpen(!isReportStartDateDropdownOpen)
  const toggleReportEndDateDropdown = () => setIsReportEndDateDropdownOpen(!isReportEndDateDropdownOpen)
  const toggleColumnsFixed = () => {
    setIsColumnsFixed(!isColumnsFixed)
  }

  const getDataParameter = () => {
    return {
      sort,
      sortColumn,
      q: searchTerm,
      page: currentPage,
      pageSize: rowsPerPage,
      identificationNumber: searchIdentificationNumber,
      name: searchName,
      surname: searchSurname,
      employedDate: searchEmployedDate
    }
  }

  // ** Get data on mount
  useEffect(() => {
    dispatch(
      getData(getDataParameter())
    )
  }, [dispatch, store.data.length, authStore.selectedCompanyGroup, sort, sortColumn, currentPage, selectedCompanyIds, selectedReportStartDateYears, selectedReportStartDateMonths, selectedReportEndDateYears, selectedReportEndDateMonths])

  useEffect(() => {
    if (currentPage > 1) setCurrentPage(1)
    else dispatch(getData(getDataParameter()))
  }, [searchTerm, selectedCompanyIds, selectedReportStartDateYears, selectedReportStartDateMonths, selectedReportEndDateYears, selectedReportEndDateMonths])


  // ** Function in get data on page change
  const handlePagination = page => {
    setCurrentPage(page.selected + 1)
    setHandleClearSelectedRows(!handleClearSelectedRows)
  }

  // ** Function in get data on rows per page
  const handlePerPage = e => {
    const value = parseInt(e.currentTarget.value)
    dispatch(
      getData(getDataParameter())
    )
    setRowsPerPage(value)
  }

  // ** Function in get data on search query change
  const handleFilter = val => {
    setSearchTerm(val)
    // dispatch(
    //   getData(getDataParameter())
    // )
  }

  useEffect(() => {
    // unmount oldugunda filtreleri temizle
    return () => {
      dispatch(clearFilters())
    }
}, [])

  // ** Custom Pagination
  const CustomPagination = () => {
    //const count = Number(Math.ceil(store.total / rowsPerPage))

    return (
      <ReactPaginate
        previousLabel={''}
        nextLabel={''}
        pageCount={store.total || 1}
        activeClassName='active'
        forcePage={currentPage !== 0 ? currentPage - 1 : 0}
        onPageChange={page => handlePagination(page)}
        pageClassName={'page-item'}
        nextLinkClassName={'page-link'}
        nextClassName={'page-item next'}
        previousClassName={'page-item prev'}
        previousLinkClassName={'page-link'}
        pageLinkClassName={'page-link'}
        containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
      />
    )
  }

  // ** Table data to render
  const dataToRender = () => {
    const filters = {
      q: searchTerm
    }

    const isFiltered = Object.keys(filters).some(function (k) {
      return filters[k].length > 0
    })

    if (store.data.length > 0) {
      return store.data
    } else if (store.data.length === 0 && isFiltered) {
      return []
    } else {
      return store.allData.slice(0, rowsPerPage)
    }
  }

  const handleSort = (column, sortDirection) => {
    setSort(sortDirection)
    setSortColumn(column.sortField)
    // dispatch(
    //   getData(getDataParameter())
    // )
  }

  const [approvedColumns, setApprovedColumns] = useState(() => {
    try {
      const saved = localStorage.getItem(STORAGE_KEY)
      const selectedCompanyGroup = authStore.selectedCompanyGroup
      const moduleList = authStore.userData.authModuleList.find(module => module.companyGroupId === selectedCompanyGroup)?.moduleId
      const originalColumns = columns(
        isIsyeriDropdownOpen,
        toggleIsyeriDropdown,
        isReportStartDateDropdownOpen,
        toggleReportStartDateDropdown,
        isReportEndDateDropdownOpen,
        toggleReportEndDateDropdown,
        selectedCompanyGroup,
        moduleList
      )

      if (!saved) return originalColumns

      const savedStates = JSON.parse(saved)

      return originalColumns.map((col, index) => ({
        ...col,
        omit: index < 5 ? false : (savedStates[col.name] || false) // First 5 columns are never hidden
      }))
    } catch (error) {
      console.error('Error loading columns:', error)
      const selectedCompanyGroup = authStore.selectedCompanyGroup
      const moduleList = authStore.userData.authModuleList.find(module => module.companyGroupId === selectedCompanyGroup)?.moduleId
      return columns(
        isIsyeriDropdownOpen,
        toggleIsyeriDropdown,
        isReportStartDateDropdownOpen,
        toggleReportStartDateDropdown,
        isReportEndDateDropdownOpen,
        toggleReportEndDateDropdown,
        selectedCompanyGroup,
        moduleList
      )
    }
  })

  // Update columns when dependencies change
  useEffect(() => {
    const selectedCompanyGroup = authStore.selectedCompanyGroup
    const moduleList = authStore.userData.authModuleList.find(module => module.companyGroupId === selectedCompanyGroup)?.moduleId
    const updatedColumns = columns(
      isIsyeriDropdownOpen,
      toggleIsyeriDropdown,
      isReportStartDateDropdownOpen,
      toggleReportStartDateDropdown,
      isReportEndDateDropdownOpen,
      toggleReportEndDateDropdown,
      selectedCompanyGroup,
      moduleList
    )

    setApprovedColumns(updatedColumns.map((col, index) => ({
      ...col,
      omit: index < 5 ? false : (approvedColumns.find(vc => vc.name === col.name)?.omit || false) // First 5 columns are never hidden
    })))
  }, [isIsyeriDropdownOpen, isReportStartDateDropdownOpen, isReportEndDateDropdownOpen, authStore.selectedCompanyGroup])

  // Save column visibility states to localStorage
  useEffect(() => {
    try {
      const visibilityStates = approvedColumns.reduce((acc, col, index) => ({
        ...acc,
        [col.name]: index < 5 ? false : (col.omit || false) // First 5 columns are never hidden
      }), {})
      localStorage.setItem(STORAGE_KEY, JSON.stringify(visibilityStates))
    } catch (error) {
      console.error('Error saving columns:', error)
    }
  }, [approvedColumns])

  // Reset columns to default function
  const resetColumns = () => {
    const selectedCompanyGroup = authStore.selectedCompanyGroup
    const moduleList = authStore.userData.authModuleList.find(module => module.companyGroupId === selectedCompanyGroup)?.moduleId
    const defaultColumns = columns(
      isIsyeriDropdownOpen,
      toggleIsyeriDropdown,
      isReportStartDateDropdownOpen,
      toggleReportStartDateDropdown,
      isReportEndDateDropdownOpen,
      toggleReportEndDateDropdown,
      selectedCompanyGroup,
      moduleList
    )
    setApprovedColumns(defaultColumns.map(col => ({
      ...col,
      omit: false // All columns visible by default
    })))
    localStorage.removeItem(STORAGE_KEY)
  }

  const handleColumnChange = (newColumns) => {
    const selectedCompanyGroup = authStore.selectedCompanyGroup
    const moduleList = authStore.userData.authModuleList.find(module => module.companyGroupId === selectedCompanyGroup)?.moduleId
    const currentColumns = columns(
      isIsyeriDropdownOpen,
      toggleIsyeriDropdown,
      isReportStartDateDropdownOpen,
      toggleReportStartDateDropdown,
      isReportEndDateDropdownOpen,
      toggleReportEndDateDropdown,
      selectedCompanyGroup,
      moduleList
    )

    setApprovedColumns(newColumns.map((col, index) => {
      const originalCol = currentColumns.find(origCol => {
        const origName = typeof origCol.name === 'object' ? origCol.name.props.name : origCol.name
        const newName = typeof col.name === 'object' ? col.name.props.name : col.name
        return origName === newName
      })

      return {
        ...originalCol,
        omit: index < 5 ? false : col.omit // First 5 columns are never hidden
      }
    }))
  }

  return (
    <Fragment>
      <Card hidden='true'>
        <CardHeader>
          <CardTitle tag='h4'>Filtreler</CardTitle>
        </CardHeader>
        <CardBody>
          <Row>
            <Col md='3'>
              <Label for='role-select'>Kimlik No</Label>
              <Input name='text' id='username' rows='3' placeholder=''
                className='react-select'
                onChange={data => {
                  setSearchIdentificationNumber(data.target.value)
                }} />
            </Col>
            <Col className='my-md-0 my-1' md='3'>
              <Label for='plan-select'>İsim</Label>
              <Input name='text' id='username' rows='3' placeholder=''
                className='react-select'
                onChange={data => {
                  setSearchName(data.target.value)
                }} />
            </Col>
            <Col md='3'>
              <Label for='status-select'>Soyisim</Label>
              <Input name='text' id='username' rows='3' placeholder=''
                className='react-select'
                onChange={data => {
                  setSearchSurname(data.target.value)
                }} />
            </Col>
            <Col md='3'>
              <Label for='status-select'>Giriş Tarihi</Label>
              <Flatpickr className='form-control' id='employmentDate' options={flatpickrConfig} onChange={data => {
                const strDate = `${data[0].getFullYear()}-${(`0${(data[0].getMonth() + 1)}`).slice(-2)}-${(`0${data[0].getDate()}`).slice(-2)}`
                setSearchEmployedDate(strDate)
              }} />
            </Col>
          </Row>
        </CardBody>
      </Card>

      <Card className='overflow-hidden'>
        <div className='react-dataTable approved-visit-list'>
          <DataTable
            noHeader
            subHeader
            sortServer
            pagination
            responsive
            paginationServer
            columns={approvedColumns}
            onSort={handleSort}
            sortIcon={<ChevronDown />}
            className={`react-dataTable ${isColumnsFixed ? 'approved-fixed-columns' : ''}`}
            paginationComponent={CustomPagination}
            data={dataToRender()}
            subHeaderComponent={
              <CustomHeader
                store={store}
                searchTerm={searchTerm}
                rowsPerPage={rowsPerPage}
                handleFilter={handleFilter}
                handlePerPage={handlePerPage}
                dispatch={dispatch}
                hasFilters={hasFilters}
                isColumnsFixed={isColumnsFixed}
                toggleColumnsFixed={toggleColumnsFixed}
                columns={approvedColumns}
                handleColumnChange={handleColumnChange}
                resetColumns={resetColumns}
              />
            }
          />
        </div>
      </Card>
      <ApprovedVisitDetailPopup open={store.detailPopupStatus} />
      <VisitHtmlImagePopup open={store.toggleVisitHtmlImagePopup?.show} toggleSidebar={toggleVisitHtmlImagePopup} />
    </Fragment>
  )
}

export default ApprovedVisitList
