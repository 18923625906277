// // ** React Imports
import { store } from "@store/store"
import { Eye, Settings } from "react-feather"
import { DropdownItem, DropdownMenu, DropdownToggle, UncontrolledDropdown } from "reactstrap"
import { detailPopupState, getCompanyDetails, ToggleVisitHtmlImagePopup } from "../store"
import { createWorkerDetailsTab } from "../../workerlist/store"
import toast from "react-hot-toast"
import FilterComponent from "../../visit/list/FilterComponent"


export const columns = (isIsyeriDropdownOpen, toggleIsyeriDropdown, isReportStartDateDropdownOpen, toggleReportStartDateDropdown, isReportEndDateDropdownOpen, toggleReportEndDateDropdown) => {
return  [
  {
    name: <FilterComponent toggleDropdown={toggleIsyeriDropdown} isOpen={isIsyeriDropdownOpen} type={"isyeri"} name={"Kod"}/>,
    sortable: true,
    minWidth: "150px",
    sortField: "CompanyName",
    selector: row => row.companyName,
    cell: row => row.companyName,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "İşyeri",
    sortable: true,
    minWidth: "350px",
    sortField: "WorkplaceCode",
    selector: row => row.workplaceCode,
    //cell: row => row.workplaceCode
    cell: (row) => <button className="btn btn-danger"
                           onClick={() => store.dispatch(getCompanyDetails({
                             id: row.companyId,
                             name: "FirmaDetay1",
                             text: row.companyName
                           }))}
                           id={row.workplaceCode}>{row.workplaceCode}</button>,
    button: true,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "#fffff" })
  },
  {
    name: "T.C.",
    sortable: true,
    minWidth: "200px",
    sortField: "IdentificationNumber",
    selector: row => row.identificationNumber,
    cell: (row) => <button className="btn btn-danger" id={row.identificationNumber} onClick={() => (row.identificationNumber ? store.dispatch(createWorkerDetailsTab(
      {
          id: row.identificationNumber,
          name: "CalisanDetayi",
          text:`${row.name  } ${  row.surname}`
      }
    )) : toast.error("Çalışan detayı bulunamadı"))
    } >{row.identificationNumber}</button>,
    button: true,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "#fffff" })
  },
  {
    name: "Ad",
    sortable: true,
    minWidth: "200px",
    sortField: "Name",
    selector: row => row.name,
    cell: row => row.name,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Soyad",
    sortable: true,
    minWidth: "150px",
    sortField: "Surname",
    selector: row => row.surname,
    cell: row => row.surname,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Vaka",
    sortable: true,
    // minWidth: '100px',
    sortField: "case",
    selector: row => row.case,
    cell: row => row.case,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Pers.Degil",
    sortable: false,
    // minWidth: '100px',
    sortField: "staffState1",
    selector: row => row.notEmployee,
    cell: row => row.notEmployee,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Ceza",
    sortable: true,
    minWidth: "100px",
    sortField: "punishment",
    selector: row => row.punishment,
    cell: row => row.punishment,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },


  {
    name: "Rapor No",
    sortable: true,
    minWidth: "275px",
    sortField: "reportNo",
    selector: row => row.reportNo,
    cell: (row) => <button className="btn btn-danger" id={row.reportNo}
                           onClick={() => store.dispatch(ToggleVisitHtmlImagePopup({
                             show: true,
                             image: `${row.approvedScreenShot}`,
                             visitReportNumber: row.reportNo.split("-")[1]
                           }))}>{row.reportNo}</button>,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: <FilterComponent toggleDropdown={toggleReportStartDateDropdown} isOpen={isReportStartDateDropdownOpen} type={"reportStartDate"} name={"Rapor Baş."}/>,
    sortable: true,
    minWidth: "190px",
    sortField: "reportStartDate",
    selector: row => row.reportStartDate,
    cell: row => row.reportStartDate,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: <FilterComponent toggleDropdown={toggleReportEndDateDropdown} isOpen={isReportEndDateDropdownOpen} type={"reportEndDate"} name={"Rapor Bit."}/>,
    sortable: true,
    minWidth: "190px",
    sortField: "reportEndDate",
    selector: row => row.reportEndDate,
    cell: row => row.reportEndDate,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "İzin Günü",
    sortable: true,
    minWidth: "150px",
    sortField: "dayOff",
    selector: row => row.dayOff,
    cell: row => row.dayOff,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "İzin ÇG",
    sortable: true,
    // minWidth: '100px',
    sortField: "dayOffWorkDay",
    selector: row => row.dayOffWorkDay,
    cell: row => row.dayOffWorkDay,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Cari Etki",
    sortable: true,
    minWidth: "150px",
    sortField: "currentImpact",
    selector: row => row.currentImpact,
    cell: row => row.currentImpact,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },

  {
    name: "Ekran Düşme",
    sortable: true,
    minWidth: "175px",
    sortField: "policlinicDate",
    selector: row => row.policlinicDate,
    cell: row => row.policlinicDate,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Sist. Eklenme",
    sortable: true,
    minWidth: "175px",
    sortField: "systemDate",
    selector: row => row.systemDate,
    cell: row => row.systemDate,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Açıklama",
    sortable: false,
    minWidth: "350px",
    sortField: "explanation",
    selector: row => row.explanation,
    cell: row => row.explanation,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Ödeme Durumu",
    sortable: true,
    // minWidth: '100px',
    sortField: "isPayment",
    selector: row => row.isPayment,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Onaylama",
    sortable: false,
    minWidth: "250px",
    sortField: "approveMessage",
    selector: row => row.approveMessage,
    cell: row => row.approveMessage,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "Çalışma Durumu",
    sortable: true,
    // minWidth: '100px',
    sortField: "workDidntWork",
    selector: row => row.workDidntWork,
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  },
  {
    name: "İşlemler",
    sortable: false,
    minWidth: "100px",
    cell: row => (
      <div className="column-action">
        <UncontrolledDropdown>
          <DropdownToggle tag="div" className="btn btn-sm">
            <Settings size={14} className="cursor-pointer" />
          </DropdownToggle>
          <DropdownMenu container="body">
            <DropdownItem
              tag="a"
              href="/"
              id={row.id}
              className="w-100"
              onClick={e => {
                e.preventDefault()
                store.dispatch(detailPopupState({ reportId: row.medularReportId, popupStatus: true }))
              }}
            >
              <Eye size={14} className="me-50" />
              <span className="align-middle">Detay</span>
            </DropdownItem>
          </DropdownMenu>
        </UncontrolledDropdown>
      </div>
    ),
    conditionalCellStyles: [
      {
        when: row => row.isStaffState,
        style: { backgroundColor: "#fce4e4", color: "#f18e8f" }
      }
    ],
    style: ({ backgroundColor: "inerit", color: "inerit" })
  }

]
}