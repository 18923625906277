// ** React Imports
import {store} from "@store/store"
import {getCompanyDetails, ToggleHtmlPopup, ToggleVisitHtmlImagePopup} from "../store"
// ** Icons Imports
// import { Settings, FileText, Trash2 } from 'react-feather'

// ** Reactstrap Imports
import {File} from "react-feather"
import {Button} from "reactstrap"
import { createWorkerDetailsTab } from "../../workerlist/store"
import FilterComponent from "./FilterComponent"

export const columns = (isIsyeriDropdownOpen, toggleIsyeriDropdown, isWorkAccidentDateDropdownOpen, toggleWorkAccidentDateDropdown) => {

return [
    {
        name: <FilterComponent toggleDropdown={toggleIsyeriDropdown} isOpen={isIsyeriDropdownOpen} type={"isyeri"} name={"Kod"}/>,
        sortable: true,
        minWidth: "180px",
        sortField: "CompanyName",
        selector: row => row.companyName,
        cell: row => row.companyName,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },


    {
        name: "İŞYERİ",
        sortable: false,
        minWidth: "180px",
        sortField: "registrationNumber",
        selector: row => row.registrationNumber,
        when: row => row.notStaff === "Evet",
        cell: (row) => <button className="btn btn-danger"
                               onClick={() => store.dispatch(getCompanyDetails({
                                   id: row.companyId,
                                   name: "FirmaDetay1",
                                   text: row.companyName
                               }))}
                               id={row.registrationNumber}>{row.registrationNumber ? row.registrationNumber : ""}</button>,
        button: true,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#fffff"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "KİMLİK NO",
        sortable: true,
        minWidth: "200px",
        sortField: "identificationNo",
        selector: row => row.identificationNo,
        //cell: row => row.identificationNo
        cell: (row) => <button className="btn btn-danger" id={row.identificationNo} onClick={() => store.dispatch(createWorkerDetailsTab(
            {
                id: row.identificationNo,
                name: "CalisanDetayi",
                text:`${row.workerName}`
            }
      )) }>{row.identificationNo}</button>,
        button: true,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#fffff"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "ADI VE SOYADI",
        sortable: true,
        minWidth: "250px",
        sortField: "workerName",
        selector: row => row.workerName,
        cell: row => row.workerName,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "PERS. DEĞİL",
        sortable: true,
        minWidth: "180px",
        sortField: "notWorker",
        selector: row => row.NotStaff,
        when: row => row.notStaff === "Evet",
        cell: row => row.notStaff,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },


    {
        name: "Kaza No",
        sortable: true,
        minWidth: "160px",
        sortField: "accidentNo",
        selector: row => row.accidentNo,
        when: row => row.notStaff === "Evet",
        cell: row => (
            <>
                <Button.Ripple onClick={() => window.open(`${row.documentFileName}`)}
                               className="btn-icon" outline color="primary">
                    {row.accidentNo}
                </Button.Ripple>
            </>
        ),
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "RAPOR",
        sortable: false,
        minWidth: "200px",
        sortField: "visitReportNumber",
        selector: row => row.identificationNo,
        cell: (row) => (row.visitReportNumber ? <button className="btn btn-danger" id={row.visitReportNumber}
                                                        onClick={() => store.dispatch(ToggleVisitHtmlImagePopup({
                                                            show: true,
                                                            image: row.visitHtmlImage,
                                                            visitReportNumber: row.visitReportNumber
                                                        }))}>{row.visitReportNumber}</button> : null),
        button: true,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#fffff"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "Vaka",
        sortable: false,
        minWidth: "180px",
        sortField: "caseName",
        selector: row => row.visitCaseName,
        cell: row => row.visitCaseName,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}

    },
    {
        name: <FilterComponent toggleDropdown={toggleWorkAccidentDateDropdown} isOpen={isWorkAccidentDateDropdownOpen} type={"workAccidentDate"} name={"KAZA TARİHİ"}/>,
        sortable: true,
        minWidth: "200px",
        sortField: "accidentDate",
        selector: row => row.accidentDate,
        cell: row => row.accidentDate,
        when: row => row.notStaff === "Evet",
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "KAZA SAATİ",
        sortable: false,
        minWidth: "180px",
        sortField: "accidentTime",
        selector: row => row.accidentTime,
        cell: row => row.accidentTime,
        when: row => row.notStaff === "Evet",
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "KİŞİ",
        minWidth: "150px",
        sortable: true,
        sortField: "numberOfAccidents",
        selector: row => row.numberOfAccidents,
        cell: row => row.numberOfAccidents,
        when: row => row.notStaff === "Evet",
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "BİLDİRİM ZAMANI",
        minWidth: "230px",
        sortable: false,
        sortField: "notificationDate",
        selector: row => row.notificationDate,
        when: row => row.notStaff === "Evet",
        cell: row => row.notificationDate,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "SİSTEM EKLEME",
        minWidth: "230px",
        sortable: true,
        sortField: "createdDate",
        selector: row => row.createdDate,
        when: row => row.notStaff === "Evet",
        cell: row => row.createdDate,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "KAZA İL",
        minWidth: "230px",
        sortable: true,
        sortField: "city",
        selector: row => row.city,
        when: row => row.notStaff === "Evet",
        cell: row => row.city,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "KAZA İLÇE",
        minWidth: "230px",
        sortable: true,
        sortField: "town",
        selector: row => row.town,
        when: row => row.notStaff === "Evet",
        cell: row => row.town,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "NEREDE ÇALIŞIYOR",
        minWidth: "300px",
        sortable: false,
        sortField: "explanation2",
        selector: row => row.explanation ?? row.explanation2,
        //when: row => row.notStaff === 'Evet',
        cell: row => row.explanation ?? row.explanation2,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "MESLEK",
        minWidth: "250px",
        sortable: false,
        sortField: "job",
        selector: row => row.job,
        when: row => row.notStaff === "Evet",
        cell: row => row.job,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "İŞE GİRİŞ",
        minWidth: "230px",
        sortable: false,
        sortField: "workEntryDate",
        selector: row => row.dismissalDate,
        when: row => row.notStaff === "Evet",
        cell: row => row.workEntryDate,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "İŞTEN ÇIKIŞ",
        minWidth: "230px",
        sortable: false,
        sortField: "workLayoffDate",
        selector: row => row.WorkLayoffDate,
        when: row => row.notStaff === "Evet",
        cell: row => row.WorkLayoffDate,
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    },
    {
        name: "Görüntüle",
        minWidth: "230px",
        sortField: "htmlPage",
        sortable: false,
        selector: row => row.htmlPage,
        when: row => row.notStaff === "Evet",
        cell: row => (
            <Button.Ripple onClick={() => store.dispatch(ToggleHtmlPopup({show: true, html: row.htmlPage}))}
                           className="btn-icon" outline color="primary">
                <File size={16}/>
            </Button.Ripple>
        ),
        conditionalCellStyles: [
            {
                when: row => row.staffState1 === "Evet" || row.visitCaseName === "HASTALIK",
                style: {backgroundColor: "#fce4e4", color: "#f18e8f"}
            }
        ],
        style: {backgroundColor: "inherit", color: "inherit"}
    }
]
}