// ** Reactstrap Imports
import Select from 'react-select'
import {Button, Col, Label, Modal, ModalBody, ModalHeader, Row, ListGroup, ListGroupItem} from 'reactstrap'

import {Fragment} from 'react'
import {FileText, X, DownloadCloud} from 'react-feather'
// ** Store & Actions
import '@styles/react/libs/file-uploader/file-uploader.scss'
import '@styles/react/libs/flatpickr/flatpickr.scss'
import {useDispatch, useSelector} from 'react-redux'
import { IncentiveDeletionDetectedSecondPhase, IncentiveDeletionDetectedXml, secondPhasePopupState} from '../store'

const SecondPhasePopup = ({open}) => {
    const authStore = useSelector(state => state.auth)
    const dispatch = useDispatch()

    const closePopup = () => {
        dispatch(secondPhasePopupState(false))
    }

    return (
        <>
            <Modal isOpen={open} toggle={closePopup} className='modal-dialog-centered modal-lg'>
                <ModalHeader className='bg-transparent' toggle={closePopup}></ModalHeader>

                <ModalBody className='px-sm-5 mx-50 pb-5'>
                    <div className='text-center mb-2'>
                        <h1 className='mb-1'>İkinci Aşama</h1>
                    </div>
                    <Fragment>
                        <Row>
                            <Col sm='12'>
                                <div className='mb-1'>
                                    <Label className='form-label' for='period'>
                                        <h6 className='bold'>"Tanımlaması bu şirket içinde yapıldığı için sorgulanmadı." ibaresi olan personelleriniz için sorgulama yapılacaktır.</h6>
                                    </Label>
                                </div>
                            </Col>
                        </Row>

                        <Row>
                            <Col sm='12'>
                                <Button type='button' className='me-1' color='primary' onClick={() => {
                                    const data = new FormData()
                                    data.append(`CompanyGroupId`, authStore.selectedCompanyGroup)
                                    data.append(`Period`, 1)
                                    dispatch(IncentiveDeletionDetectedSecondPhase(data))
                                    closePopup()
                                }}>
                                    Kontrol Et
                                </Button>
                                <Button type='reset' color='secondary' outline onClick={closePopup}>
                                    İptal
                                </Button>
                            </Col>
                        </Row>
                    </Fragment>
                </ModalBody>
            </Modal>
        </>
    )
}

export default SecondPhasePopup
