// ** User List Component
import '@styles/react/apps/app-users.scss'
import {Button, Card, CardBody, CardHeader, CardTitle, Table} from "reactstrap"
import {CheckCircle} from "react-feather"
import {Box, CircularProgress, IconButton, Typography} from "@mui/material"
import {BsArrowCounterclockwise, BsFiletypeXls} from "react-icons/bs"
import React, {Fragment, useEffect, useState} from "react"
// eslint-disable-next-line no-unused-vars
import {getSchedulerList, showProcessData, downloadSchedulerExcel} from '../store'
import {useDispatch, useSelector} from "react-redux"
import PropTypes from "prop-types"
import { getSchedulerListV3 } from '../../incentive-defination-delete/store'

const IncentiveDefinationDeleteManagement = () => {
    const dispatch = useDispatch()
    const store = useSelector(state => state.incentiveDefinationDeleteManagement)
    const [combinedData, setCombinedData] = useState([])

    useEffect(() => {
      // Combine the data first
      const newCombinedData = [...store.schedulerData, ...store.schedulerDataV3]

      // Then sort it by id in descending order (highest id first)
      const sortedData = newCombinedData.sort((a, b) => b.id - a.id)

      // Then set the state once with the sorted data
      setCombinedData(sortedData)
    }, [store.schedulerData, store.schedulerDataV3])

    function CircularProgressWithLabel(props) {
        return (
            <Box sx={{position: 'relative', display: 'inline-flex'}}>
                <CircularProgress variant="determinate" {...props} />
                <Box
                    sx={{
                        top: 0,
                        left: 0,
                        bottom: 0,
                        right: 0,
                        position: 'absolute',
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'center'
                    }}
                >
                    <Typography variant="caption" component="div" color="text.secondary">
                        {`${Math.round(props.value)}%`}
                    </Typography>
                </Box>
            </Box>
        )
    }

    CircularProgressWithLabel.propTypes = {
        /**
         * The value of the progress indicator for the determinate variant.
         * Value between 0 and 100.
         * @default 0
         */
        value: PropTypes.number.isRequired
    }

    useEffect(() => {
        dispatch(getSchedulerList(30))
        dispatch(getSchedulerListV3(36))
        // dispatch(getSchedulerListV2(31))
        console.log(store.data)
    }, [])

    return (
        <Fragment>
            <Card>
                <CardHeader className="d-flex justify-content-between align-items-center">
                    <CardTitle tag='h4' className="m-0">Silinecek TCKN'lerin Tespiti</CardTitle>
                </CardHeader>
                <CardBody>
                    <Table responsive>
                        <thead>
                        <th scope='col' className='text-nowrap'> İşlem ID</th>
                        <th scope='col' className='text-nowrap'> Grup</th>
                        <th scope='col' className='text-nowrap'> Tarih</th>
                        <th scope='col' className='text-nowrap'> İşlem Tipi</th>
                        <th scope='col' className='text-nowrap'> İşlem Durumu</th>
                        <th scope='col' className='text-nowrap'> Sonuç Rapor</th>
                        </thead>
                        <tbody>
                        {combinedData && combinedData.map((item, index) => (
                            <tr key={index}>
                                <td className='text-nowrap'>
                                    <button onClick={() => dispatch(showProcessData({
                                        id: item.id,
                                        name: 'IncentiveDefinationProcessWorkers',
                                        text: item.id
                                    }))}
                                            className="btn btn-outline-primary"
                                            id={item.id}>{item.id}
                                    </button>
                                </td>
                                <td className='text-nowrap'>{item.groupName}</td>
                                <td className='text-nowrap'>{item.date}</td>
                                <td className='text-nowrap'>{item.schedulerType === 36 ? "Şirkette Tanımlananların Tekrar Sorgusu" :  "Tüm XML"}</td>
                                <td className='text-nowrap'>
                                    {item.succesPercentInt >= 100 ? (
                                        <CheckCircle color="green" size={40}/>) : (
                                        <CircularProgressWithLabel
                                            value={parseInt(item.succesPercentInt)}/>)
                                    }
                                </td>
                                {
                                    item.succesPercentInt >= 100 ? (<>
                                        <td className='text-nowrap'>
                                            <IconButton size='60'
                                                        onClick={() => dispatch(downloadSchedulerExcel(item.id))}>
                                                <BsFiletypeXls size={30}/>
                                            </IconButton>
                                        </td>
                                    </>) : (<>
                                        <td className='text-nowrap'>-</td>
                                    </>)
                                }

                            </tr>
                        ))}
                        </tbody>
                    </Table>
                </CardBody>
            </Card>
        </Fragment>
    )
}

export default IncentiveDefinationDeleteManagement