import {Button} from "reactstrap"
import {store} from "@store/store"
import {createWorkerDetailsTab, ToggleHtmlImagePopup} from "../store"
import { Download } from "react-feather"
import { FaBarcode } from "react-icons/fa6"

//Taşan verileri truncate eden fonksiyon
const truncate = (str, n) => {
    return (str.length > n) ? `${str.slice(0, n - 1)  }...` : str
}

//Truncate edilecek verilerde kullanılacak style
const truncateStyle = {
    whiteSpace: 'nowrap',
    overflow: 'hidden',
    textOverflow: 'ellipsis',
    maxWidth: '200px'
}

export const activeWorkerColumns = [
    {
        name: 'İşyeri Adı ve Sicil No',
        sortable: false,
        minWidth: '200px',
        sortField: 'name',
        selector: row => row.firstAndLastName,
        cell: row => (
            <div className='d-flex justify-content-left align-items-center'>
                <div className='d-flex flex-column'>
                    <a className='user_name text-truncate text-body'>
                        <span style={truncateStyle} className='fw-bolder'> {truncate(row.companyName, 20)} </span>
                    </a>
                    <small className='text-truncate text-muted mb-0'>{row.registrationNumber}</small>
                </div>
            </div>
        )
    },
    // Personel Detaylarını Yeni Sekmede Açan Buton
    {
        name: 'TC',
        minWidth: '180px',
        sortable: false,
        sortField: 'gender',
        selector: row => row.identityNumber,
        cell: row => (
            <Button className='btn-icon me-1 btn-light-gray' onClick={() => store.dispatch(createWorkerDetailsTab(
                                                                {
                                                                    id: row.identityNumber,
                                                                    name: "CalisanDetayi",
                                                                    text:`${row.firstname  } ${  row.lastname}`
                                                                }
            )) }>{row.identityNumber}</Button>
        )
    },
    {
        name: 'Ad Soyad',
        minWidth: '175px',
        sortable: true,
        sortField: 'firstname',
        selector: row => `${row.firstname} ${row.lastname}`,
        cell: row => `${row.firstname} ${row.lastname}`
    },
    {
        name: 'Giriş Tarihi',
        minWidth: '225px',
        sortable: true,
        sortField: 'startDate',
        selector: row => row.startDate,
        cell: row => (
            row.entryWorkerLogCount !== 0 ? <>
            <Button onClick={() => {
                if (row.entryDeclarationPdf) {
                    store.dispatch(ToggleHtmlImagePopup({
                        show: true,
                        html: `<iframe src="${`${row.entryDeclarationPdf}#toolbar=0&view=FitH`}" width="850px" height="800px" style="max-width: 100%;" frameborder="0"></iframe>`,
                        id: row.companyWorkerLogId,
                        type: 'pdf',
                        pdfUrl: row.entryDeclarationPdf ? row.entryDeclarationPdf : null
                    }))
                } else {
                    store.dispatch(ToggleHtmlImagePopup({
                        show: true,
                        html: `${row.declarationHtml}`,
                        id: row.companyWorkerLogId
                    }))
                }
            }} className='btn-icon me-1 btn-light-gray'>{row.startDate}</Button>
            </> : "Bildirge Bulunamadı"
        )
    },
    {
        name: 'Meslek Adı',
        minWidth: '200px',
        sortable: false,
        sortField: 'jobName',
        selector: row => row.jobName,
        cell: row => row.jobName
    },
    {
        name: 'Meslek Kodu',
        minWidth: '170px',
        sortable: false,
        sortField: 'jobCode',
        selector: row => row.jobCode,
        cell: row => row.jobCode
    },
    {
        name: 'Emekli',
        minWidth: '115px',
        sortable: true,
        sortField: 'insuranceType',
        selector: row => row.retirement,
        cell: row => row.retirement
    },
    {
        name: 'Engelli',
        minWidth: '120px',
        sortable: true,
        sortField: 'staffType',
        selector: row => row.disability,
        cell: row => row.disability
    },
    {
        name: 'Doğum',
        minWidth: '120px',
        sortable: false,
        sortField: 'staffType',
        selector: row => row.birthDate,
        cell: row => row.birthDate
    },
    {
        name: 'Uyruk',
        minWidth: '120px',
        sortable: false,
        sortField: 'staffType',
        selector: row => row.nationality,
        cell: row => row.nationality
    },
    {
        name: 'Nüfusa Kayıtlı Olduğu İl',
        minWidth: '120px',
        sortable: false,
        sortField: 'poRCity',
        selector: row => row.poRCity,
        cell: row => (row.poRCity ? row.poRCity.toUpperCase() : '')
    },
    {
        name: 'Nüfusa Kayıtlı Olduğu İlçe',
        minWidth: '120px',
        sortable: false,
        sortField: 'poRDistrict',
        selector: row => row.poRDistrict,
        cell: row => (row.poRDistrict ? row.poRDistrict.toUpperCase() : '')
    },
    {
        name: 'Nüfusa Kayıtlı Olduğu Köy',
        minWidth: '120px',
        sortable: false,
        sortField: 'poRVillage',
        selector: row => row.poRVillage,
        cell: row => (row.poRVillage ? row.poRVillage.toUpperCase() : '')
    },
    {
        name: 'Uyarı',
        minWidth: '120px',
        sortable: false,
        sortField: 'description',
        selector: row => row.description,
        cell: row => row.description
    }
]
