// ** Redux Imports
import {createAction, createAsyncThunk, createSlice} from '@reduxjs/toolkit'
import {createTab} from '../../../../redux/tabManagement'
// ** Axios Imports
import axios from 'axios'
import toast from 'react-hot-toast'

export const ToggleHtmlPopup = createAsyncThunk('workaccidents/OpenHtmlPopup', (data) => {
    return data
})
export const ToggleVisitHtmlImagePopup = createAsyncThunk('workaccidents/ToggleVisitHtmlImagePopup', (data) => {
    return data
})
export const getData = createAsyncThunk('workaccidents/getData', async (params, {getState}) => {
    let url = `/api/workaccidents/?`
    const selectedCompanyIds = getState().workAccident.selectedCompanyIds
    const selectedWorkAccidentDateYears = getState().workAccident.selectedWorkAccidentDateYears
    const selectedWorkAccidentDateMonths = getState().workAccident.selectedWorkAccidentDateMonths

    if (selectedCompanyIds.length > 0) {
        url += selectedCompanyIds.map(id => `&SelectedCompanyIds=${id}`).join('')
        console.log(url)
    }

    if (selectedWorkAccidentDateYears.length > 0) {
        url += selectedWorkAccidentDateYears.map(id => `&SelectedWorkAccidentDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedWorkAccidentDateMonths.length > 0) {
        url += selectedWorkAccidentDateMonths.map(id => `&SelectedWorkAccidentDateMonths=${id}`).join('')
        console.log(url)
    }

    const response = await axios.get(url, {params})
    return {
        params,
        data: response.data.workAccidents,
        totalPages: response.data.pageCount,
        credential: response.data.credential
    }
})

export const getDataSubAgent = createAsyncThunk('workaccidents/getDataSubAgent', async (params, {getState}) => {
    let url = `/api/workaccidents/?`
    const selectedCompanyIds = getState().workAccident.selectedCompanyIds
    const selectedWorkAccidentDateYears = getState().workAccident.selectedWorkAccidentDateYears
    const selectedWorkAccidentDateMonths = getState().workAccident.selectedWorkAccidentDateMonths

    if (selectedCompanyIds.length > 0) {
        url += selectedCompanyIds.map(id => `&SelectedCompanyIds=${id}`).join('')
        console.log(url)
    }

    if (selectedWorkAccidentDateYears.length > 0) {
        url += selectedWorkAccidentDateYears.map(id => `&SelectedWorkAccidentDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedWorkAccidentDateMonths.length > 0) {
        url += selectedWorkAccidentDateMonths.map(id => `&SelectedWorkAccidentDateMonths=${id}`).join('')
        console.log(url)
    }

    const response = await axios.get(url, {params})
    return {
        params,
        data: response.data.workAccidents,
        totalPages: response.data.pageCount,
        credential: response.data.credential
    }
})

export const getDataProvision = createAsyncThunk('workaccidents/getDataProvision', async (params, {getState}) => {
    let url = `/api/workaccidents/?`
    const selectedCompanyIds = getState().workAccident.selectedCompanyIds
    const selectedWorkAccidentDateYears = getState().workAccident.selectedWorkAccidentDateYears
    const selectedWorkAccidentDateMonths = getState().workAccident.selectedWorkAccidentDateMonths

    if (selectedCompanyIds.length > 0) {
        url += selectedCompanyIds.map(id => `&SelectedCompanyIds=${id}`).join('')
        console.log(url)
    }

    if (selectedWorkAccidentDateYears.length > 0) {
        url += selectedWorkAccidentDateYears.map(id => `&SelectedWorkAccidentDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedWorkAccidentDateMonths.length > 0) {
        url += selectedWorkAccidentDateMonths.map(id => `&SelectedWorkAccidentDateMonths=${id}`).join('')
        console.log(url)
    }

    const response = await axios.get(url, {params})

    return {
        params,
        data: response.data.workAccidents,
        totalPages: response.data.pageCount,
        credential: response.data.credential
    }
})

export const getCompanyOptions = createAsyncThunk('workaccidents/getCompanyOptions', async (companyGroupId) => {
    const response = await axios.get(`/api/workaccidents/CompanyOptions?companyGroupId=${companyGroupId}`)
    return response.data.companyOptions
  })

export const exportExcel = createAsyncThunk('workaccidents/exportExcel', async (params, {getState}) => {
    console.log(params)
    let url = '/api/workaccidents/Export/?'
    const selectedCompanyIds = getState().workAccident.selectedCompanyIds
    const selectedWorkAccidentDateYears = getState().workAccident.selectedWorkAccidentDateYears
    const selectedWorkAccidentDateMonths = getState().workAccident.selectedWorkAccidentDateMonths

    if (selectedCompanyIds.length > 0) {
        url += selectedCompanyIds.map(id => `&SelectedCompanyIds=${id}`).join('')
        console.log(url)
    }

    if (selectedWorkAccidentDateYears.length > 0) {
        url += selectedWorkAccidentDateYears.map(id => `&SelectedWorkAccidentDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedWorkAccidentDateMonths.length > 0) {
        url += selectedWorkAccidentDateMonths.map(id => `&SelectedWorkAccidentDateMonths=${id}`).join('')
        console.log(url)
    }
    const response = await axios.get(url, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob',
        params
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'iskazasi_listesi.xlsx')
        document.body.appendChild(link)
        link.click()
    }

})

export const getHtmlFile = createAsyncThunk('workaccidents/getHtmlFile', async (data, {getState}) => {
    const response = await axios.get(`/api/visits/HtmlFile`, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob',
        params: {ReportTrackingNo: getState().workAccident.toggleVisitHtmlImagePopup.visitReportNumber}
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'HtmlFile.html')
        document.body.appendChild(link)
        link.click()
    }

})

export const getCompanyDetails = createAsyncThunk('workaccidents/getCompanyDetails', (data, {dispatch}) => {
    dispatch(createTab(data))
})

export const getAllCompanies = createAsyncThunk('workaccidents/getAllCompaniesByGroupId', async (params, {getState}) => {
    const url = `/api/companygroup/${getState().auth.selectedCompanyGroup}/companies`
    const response = await axios.get(url)
    return response.data
})

export const getDecryptedCompanyServiceAccess = async (selectedCompanyId) => {
    const url = '/api/CompanyServiceAccess'
    const payload = {
        params: {
            companyId: selectedCompanyId,
            serviceType: 0,
            decryptData: true
        }
    }
    const response = await axios.get(url, payload)
    return response.data
}

export const getMailOptions = createAsyncThunk("workAccidents/getMailOptions", async () => {
    const url = `/api/WorkAccidents/GetWorkAccidentMailOptions`
    const response = await axios.get(url)
    console.log("getresponse", response.data)
    return response.data
})

export const setMailOptions = createAsyncThunk("workAccidents/setMailOptions", async (data) => {
    const url = `/api/WorkAccidents/SetWorkAccidentMailOptions`
    console.log("data", data)
    const response = await axios.post(url, {
        ReceiveAutomatedWorkAccidentMails: data
    })
    return response.data
})

export const clearFilters = createAction('edeclarations/clearFilters')

export const exportWorkAccidentsZip = createAsyncThunk('workaccidents/exportWorkAccidentsZip', async (params, {getState}) => {
    const selectedCompanyIds = getState().workAccident.selectedCompanyIds
    const selectedWorkAccidentDateYears = getState().workAccident.selectedWorkAccidentDateYears
    const selectedWorkAccidentDateMonths = getState().workAccident.selectedWorkAccidentDateMonths

    let url = `/api/workaccidents/WorkAccidentsZipExport?`

    if (selectedCompanyIds.length > 0) {
        url += selectedCompanyIds.map(id => `&SelectedCompanyIds=${id}`).join('')
        console.log(url)
    }

    if (selectedWorkAccidentDateYears.length > 0) {
        url += selectedWorkAccidentDateYears.map(id => `&SelectedWorkAccidentDateYears=${id}`).join('')
        console.log(url)

    }

    if (selectedWorkAccidentDateMonths.length > 0) {
        url += selectedWorkAccidentDateMonths.map(id => `&SelectedWorkAccidentDateMonths=${id}`).join('')
        console.log(url)
    }

    const response = await axios.get(url, {
        responseType: 'arraybuffer',
        'Content-Type': 'blob',
        params
    })
    if (response.status === 200) {
        const url = window.URL.createObjectURL(new Blob([response.data]))
        const link = document.createElement('a')
        link.href = url
        link.setAttribute('download', 'hero_is_kazasi.zip')
        document.body.appendChild(link)
        link.click()
    }
})


export const appWorkAccidentsSlice = createSlice({
    name: 'appedeclarations',
    initialState: {
        data: [],
        subAgentData: [],
        provisionData: [],
        credential: null,
        toggleHtmlPopup: null,
        toggleVisitHtmlImagePopup: null,
        total: 1,
        params: {},
        allData: [],
        subAgentTotal: 1,
        provisionTotal: 1,
        subAgentParams: {},
        provisionParams: {},
        subAgentAllData: [],
        companyGroupSelectData: [],
        companyPasswords: [],
        selectedWorkAccidents: null,
        receiveAutomatedWorkAccidentMails: false,
        companyOptions: [],
        selectedCompanyIds : [],
        selectedWorkAccidentDateYears: [],
        selectedWorkAccidentDateMonths: [],
        isExportZipLoading: false,
        isExportExcelLoading: false
    },
    reducers: {
        setSelectedCompanies: (state, action) => {
            state.selectedCompanyIds = action.payload
            console.log("state.selectedCompanies", state.selectedCompanyIds)
        },
        setSelectedWorkAccidentDateYears: (state, action) => {
            state.selectedWorkAccidentDateYears = action.payload
            console.log("state.selectedWorkAccidentDateYears", state.selectedWorkAccidentDateYears)
        },
        setSelectedWorkAccidentDateMonths: (state, action) => {
            state.selectedWorkAccidentDateMonths = action.payload
            console.log("state.selectedWorkAccidentDateMonths", state.selectedWorkAccidentDateMonths)
        }
    },
    extraReducers: builder => {
        builder
            .addCase(getData.fulfilled, (state, action) => {
                state.data = action.payload.data
                state.params = action.payload.params
                state.total = action.payload.totalPages
                state.credential = action.payload.credential
            })
            .addCase(getDataSubAgent.fulfilled, (state, action) => {
                state.subAgentData = action.payload.data
                state.subAgentParams = action.payload.params
                state.subAgentTotal = action.payload.totalPages
            }).addCase(getDataProvision.fulfilled, (state, action) => {
                state.provisionData = action.payload.data
                state.provisionParams = action.payload.params
                state.provisionTotal = action.payload.totalPages
            })
            .addCase(ToggleHtmlPopup.fulfilled, (state, action) => {
                state.toggleHtmlPopup = action.payload
            })
            .addCase(ToggleVisitHtmlImagePopup.fulfilled, (state, action) => {
                state.toggleVisitHtmlImagePopup = action.payload
            })
            .addCase(getAllCompanies.fulfilled, (state, action) => {
                state.companyGroupSelectData = action.payload
            })
            .addCase(getMailOptions.fulfilled, (state, action) => {
                console.log("actipa", action.payload)
                state.receiveAutomatedWorkAccidentMails = action.payload.receiveAutomatedWorkAccidentMails
            })
            .addCase(setMailOptions.fulfilled, (state, action) => {
                state.receiveAutomatedWorkAccidentMails = action.payload
                toast.success("İşleminiz Başarılı")
            })
            .addCase(getCompanyOptions.fulfilled, (state, action) => {
                state.companyOptions = action.payload
            })
            .addCase(clearFilters, (state) => {
                state.selectedCompanyIds = []
                state.selectedWorkAccidentDateYears = []
                state.selectedWorkAccidentDateMonths = []
            })
            .addCase(exportWorkAccidentsZip.pending, (state) => {
                state.isExportZipLoading = true
            })
            .addCase(exportWorkAccidentsZip.fulfilled, (state) => {
                state.isExportZipLoading = false
            })
            .addCase(exportWorkAccidentsZip.rejected, (state) => {
                state.isExportZipLoading = false
            })
            .addCase(exportExcel.pending, (state) => {
                state.isExportExcelLoading = true
            })
            .addCase(exportExcel.fulfilled, (state) => {
                state.isExportExcelLoading = false
            })
            .addCase(exportExcel.rejected, (state) => {
                state.isExportExcelLoading = false
            })
    }
})

export const {setSelectedCompanies, setSelectedWorkAccidentDateYears, setSelectedWorkAccidentDateMonths} = appWorkAccidentsSlice.actions

export default appWorkAccidentsSlice.reducer
