import { styled } from '@mui/material/styles'

const CustomScrollbar = styled('div')(({  }) => ({
  flex: 1,
    maxHeight: '200px',
  overflowY: 'scroll',
  padding: '0 16px',
  // WebKit (Chrome) için
  '&::-webkit-scrollbar': {
    width: '4px' // Adjusted width to make the scrollbar thinner
  },
  '&::-webkit-scrollbar-track': {
    background: '#f1f1f1'
  },
  '&::-webkit-scrollbar-thumb': {
    background: '#8b00ff'
    // borderRadius: '3px' // Reduced border-radius for a more compact look
  },
  '&::-webkit-scrollbar-thumb:hover': {
    background: '#6a00c2'
  }
}))

export default CustomScrollbar