// ** React Imports
import {Fragment, useEffect, useState} from 'react'

import CreatePopup from '../LoginPopup'
import HtmlPopup from '../HtmlPopup'
import VisitHtmlImagePopup from '../VisitHtmlImagePopup'

// ** Table Columns
import {columns} from './subAgentListColumns'

// ** Store & Actions
import {useDispatch, useSelector} from 'react-redux'
import {getDataSubAgent, ToggleHtmlPopup, exportExcel, ToggleVisitHtmlImagePopup, clearFilters} from '../store'

// ** Third Party Components
import DataTable from 'react-data-table-component'
import {ChevronDown, Copy, File, FileText, Grid, Lock, Printer, Share, Unlock, Columns} from 'react-feather'
import ReactPaginate from 'react-paginate'

// ** Reactstrap Imports
import {
    Button, Card, CardBody, CardHeader, CardTitle, Col, DropdownItem, DropdownMenu, DropdownToggle, Input,
    Label, Nav, NavItem, NavLink, Row, Spinner, TabContent, TabPane, UncontrolledDropdown
} from 'reactstrap'

// ** Styles
import '@styles/react/libs/react-select/_react-select.scss'
import '@styles/react/libs/tables/react-dataTable-component.scss'
import './column-fixer-styles.css'
import { LuFilterX } from 'react-icons/lu'

const STORAGE_KEY = 'work-accident-subagent-list-columns'

const ColumnSelectionMenu = ({ columns, handleColumnChange, resetColumns }) => {
    const getColumnName = (col) => {
        if (typeof col.name === 'object' && col.name.props) {
            return col.name.props.name || 'Unknown'
        }
        return col.name
    }

    // Filter out first 4 columns from being selectable
    const selectableColumns = columns.slice(4)

    return (
        <UncontrolledDropdown className='column-selection-dropdown me-1'>
            <DropdownToggle color='secondary' outline className='btn-icon d-flex align-items-center' style={{marginLeft: 10, backgroundColor: 'transparent'}}>
                <Columns className='font-small-4 me-50' />
                <span>Kolonlar</span>
            </DropdownToggle>
            <DropdownMenu container="body" style={{
                minWidth: '250px',
                maxHeight: '400px',
                overflowY: 'auto'
            }}>
                <div className="px-2 py-1">
                    <div className="d-flex justify-content-between align-items-center mb-1">
                        <h6 className="m-0">Görünür Kolonlar</h6>
                        <Button.Ripple
                            color="flat-secondary"
                            size="sm"
                            onClick={resetColumns}
                        >
                            Varsayılana Dön
                        </Button.Ripple>
                    </div>
                    {selectableColumns.map((col, index) => (
                        <div key={index} className="d-flex align-items-center py-1">
                            <Input
                                type="checkbox"
                                checked={!col.omit}
                                onChange={() => {
                                    const newColumns = [...columns]
                                    // Add 4 to index to account for the excluded columns
                                    newColumns[index + 4] = { ...col, omit: !col.omit }
                                    handleColumnChange(newColumns)
                                }}
                            />
                            <span className="ms-1">{getColumnName(col)}</span>
                        </div>
                    ))}
                </div>
            </DropdownMenu>
        </UncontrolledDropdown>
    )
}

// ** Table Header
// eslint-disable-next-line no-unused-vars
const CustomHeader = ({toggleSidebar, handlePerPage, rowsPerPage, handleFilter, searchTerm, dispatch, hasFilters, getDataParameter, isColumnsFixed, toggleColumnsFixed, columns, handleColumnChange, resetColumns}) => {
    const isExportExcelLoading = useSelector(state => state.workAccident.isExportExcelLoading)
    const [showFixedColumnsButton, setShowFixedColumnsButton] = useState(true)

    useEffect(() => {
        const handleResize = () => {
            setShowFixedColumnsButton(window.innerWidth >= 992)
        }
        handleResize()
        window.addEventListener('resize', handleResize)
        return () => window.removeEventListener('resize', handleResize)
    }, [])

    const handleClearFilters = () => {
        dispatch(clearFilters())
        handleFilter("")
    }

    return (
        <div className='invoice-list-table-header w-100 me-1 ms-50 mt-2 mb-75'>
            <Row>
                <Col xl='6' className='d-flex align-items-center p-0'>
                    <div className='d-flex flex-wrap align-items-center w-100'>
                        <div className='d-flex align-items-center mb-sm-0 mb-1'>
                            <label htmlFor='rows-per-page'>Toplam</label>
                            <Input
                                className='mx-50'
                                type='select'
                                id='rows-per-page'
                                value={rowsPerPage}
                                onChange={handlePerPage}
                                style={{width: '5rem'}}
                            >
                                <option value='10'>10</option>
                                <option value='25'>25</option>
                                <option value='50'>50</option>
                            </Input>
                            <label htmlFor='rows-per-page'>Kayıt Göster</label>
                        </div>
                        {showFixedColumnsButton && (
                            <Button
                                className='btn-icon ms-2 fixed-columns-toggle'
                                color='flat-dark'
                                onClick={toggleColumnsFixed}
                            >
                                {isColumnsFixed ? <Lock size={16}/> : <Unlock size={16}/>}
                                <span className='ms-1'>{isColumnsFixed ? 'Sütunları Serbest Bırak' : 'Sütunları Sabitle'}</span>
                            </Button>
                        )}
                    </div>
                </Col>
                <Col
                    xl='6'
                    className='d-flex flex-wrap align-items-sm-center justify-content-xl-end justify-content-start pe-xl-1 p-0 mt-xl-0 mt-1'
                >
                    <div className='d-flex flex-wrap gap-1'>
                        <div className='d-flex align-items-center mb-sm-0 mb-1'>
                            <label className='mb-0' htmlFor='search-invoice'>
                                Ara:
                            </label>
                            <Input
                                id='search-invoice'
                                className='ms-50'
                                type='text'
                                value={searchTerm}
                                onChange={e => handleFilter(e.target.value)}
                                style={{minWidth: '150px'}}
                            />
                        </div>

                        {hasFilters && (
                            <Button
                                className='btn-icon btn-gray d-flex align-items-center'
                                style={{ backgroundColor: 'transparent', width: "160px"}}
                                outline
                                onClick={handleClearFilters}
                            >
                                <LuFilterX size={16} className={"me-50"}/>
                                <span>Filtreleri Kaldır</span>
                            </Button>
                        )}

                        <ColumnSelectionMenu
                            columns={columns}
                            handleColumnChange={handleColumnChange}
                            resetColumns={resetColumns}
                        />

                        <Button
                            onClick={() => dispatch(exportExcel(getDataParameter()))}
                            className='btn-icon btn-gray d-flex align-items-center'
                            outline
                            disabled={isExportExcelLoading}
                        >
                            {isExportExcelLoading ? (
                                <Spinner size="sm" color="primary" className={"me-50"} />
                            ) : (
                                <Grid className='font-small-4 me-50' />
                            )}
                            {isExportExcelLoading ? 'Hazırlanıyor...' : 'Excele Aktar'}
                        </Button>
                    </div>
                </Col>
            </Row>
        </div>
    )
}

const UsersList = () => {
    // ** Store Vars
    const dispatch = useDispatch()
    const store = useSelector(state => state.workAccident)
    const authStore = useSelector(state => state.auth)

    // ** States
    const [sort, setSort] = useState('desc')
    const [searchTerm, setSearchTerm] = useState('')
    const [currentPage, setCurrentPage] = useState(1)
    const [sortColumn, setSortColumn] = useState('id')
    const [rowsPerPage, setRowsPerPage] = useState(10)
    const [sidebarOpen, setSidebarOpen] = useState(false)
    const [isIsyeriDropdownOpen, setIsIsyeriDropdownOpen] = useState(false)
    const [isWorkAccidentDateDropdownOpen, setIsWorkAccidentDateDropdownOpen] = useState(false)
    const selectedCompanyIds = useSelector(state => state.workAccident.selectedCompanyIds)
    const selectedWorkAccidentDateYears = useSelector(state => state.workAccident.selectedWorkAccidentDateYears)
    const selectedWorkAccidentDateMonths = useSelector(state => state.workAccident.selectedWorkAccidentDateMonths)
    const hasFilters = selectedCompanyIds.length > 0 || selectedWorkAccidentDateYears.length > 0 || selectedWorkAccidentDateMonths.length > 0
    const [isColumnsFixed, setIsColumnsFixed] = useState(false)

    // ** Function to toggle sidebar
    const toggleSidebar = () => setSidebarOpen(!sidebarOpen)
    const toggleHtmlPopup = () => dispatch(ToggleHtmlPopup({show: false, html: null}))
    const toggleVisitHtmlImagePopup = () => dispatch(ToggleVisitHtmlImagePopup({show: false, image: null}))
    const toggleIsyeriDropdown = () => setIsIsyeriDropdownOpen(!isIsyeriDropdownOpen)
    const toggleWorkAccidentDateDropdown = () => setIsWorkAccidentDateDropdownOpen(!isWorkAccidentDateDropdownOpen)
    const toggleColumnsFixed = () => setIsColumnsFixed(!isColumnsFixed)

    // Add column management state
    const [subAgentColumns, setSubAgentColumns] = useState(() => {
        try {
            const saved = localStorage.getItem(STORAGE_KEY)
            const originalColumns = columns(isIsyeriDropdownOpen, toggleIsyeriDropdown, isWorkAccidentDateDropdownOpen, toggleWorkAccidentDateDropdown)

            if (!saved) return originalColumns

            const savedStates = JSON.parse(saved)

            return originalColumns.map(col => ({
                ...col,
                omit: savedStates[col.name] || false
            }))
        } catch (error) {
            console.error('Error loading columns:', error)
            return columns(isIsyeriDropdownOpen, toggleIsyeriDropdown, isWorkAccidentDateDropdownOpen, toggleWorkAccidentDateDropdown)
        }
    })

    // Update columns when dependencies change
    useEffect(() => {
        const updatedColumns = columns(isIsyeriDropdownOpen, toggleIsyeriDropdown, isWorkAccidentDateDropdownOpen, toggleWorkAccidentDateDropdown)

        setSubAgentColumns(updatedColumns.map(col => ({
            ...col,
            omit: subAgentColumns.find(vc => vc.name === col.name)?.omit || false
        })))
    }, [isIsyeriDropdownOpen, isWorkAccidentDateDropdownOpen])

    // Save column visibility states to localStorage
    useEffect(() => {
        try {
            const visibilityStates = subAgentColumns.reduce((acc, col) => ({
                ...acc,
                [col.name]: col.omit || false
            }), {})
            localStorage.setItem(STORAGE_KEY, JSON.stringify(visibilityStates))
        } catch (error) {
            console.error('Error saving columns:', error)
        }
    }, [subAgentColumns])

    // Reset columns to default function
    const resetColumns = () => {
        const defaultColumns = columns(isIsyeriDropdownOpen, toggleIsyeriDropdown, isWorkAccidentDateDropdownOpen, toggleWorkAccidentDateDropdown)
        setSubAgentColumns(defaultColumns)
        localStorage.removeItem(STORAGE_KEY)
    }

    const handleColumnChange = (newColumns) => {
        const currentColumns = columns(isIsyeriDropdownOpen, toggleIsyeriDropdown, isWorkAccidentDateDropdownOpen, toggleWorkAccidentDateDropdown)

        setSubAgentColumns(newColumns.map(col => {
            const originalCol = currentColumns.find(origCol => {
                const origName = typeof origCol.name === 'object' ? origCol.name.props.name : origCol.name
                const newName = typeof col.name === 'object' ? col.name.props.name : col.name
                return origName === newName
            })

            return {
                ...originalCol,
                omit: col.omit
            }
        }))
    }

    const getDataParameter = () => {
        return {
            sort,
            sortColumn,
            agentNo: '1',
            q: searchTerm,
            page: currentPage,
            pageSize: rowsPerPage,
            companyGroupId: authStore.selectedCompanyGroup,
            workAccidentType: 0
        }
    }

    // ** Get data on mount
    useEffect(() => {
        dispatch(getDataSubAgent(getDataParameter()))
    }, [dispatch, store.data.length, authStore.selectedCompanyGroup, sort, sortColumn, rowsPerPage, currentPage, selectedCompanyIds, selectedWorkAccidentDateYears, selectedWorkAccidentDateMonths])

    useEffect(() => {
        if (currentPage > 1) setCurrentPage(1)
         dispatch(getDataSubAgent(getDataParameter()))
    }, [searchTerm, selectedCompanyIds, selectedWorkAccidentDateYears, selectedWorkAccidentDateMonths])

    // useEffect(() => {
    //     dispatch(
    //         getDataSubAgent(getDataParameter())
    //     )
    // }, [])

    // ** Function in get data on page change
    const handlePagination = page => {
        setCurrentPage(page.selected + 1)
    }

    // ** Function in get data on rows per page
    const handlePerPage = e => {
        const value = parseInt(e.currentTarget.value)
        // dispatch(
        //     getDataSubAgent(getDataParameter())
        // )
        setRowsPerPage(value)
    }

    // ** Function in get data on search query change
    const handleFilter = val => {
        setSearchTerm(val)
        // dispatch(
        //     getDataSubAgent(getDataParameter())
        // )
    }

    // ** Custom Pagination
    const CustomPagination = () => {
        //const count = Number(Math.ceil(store.subAgentTotal / rowsPerPage))

        return (
            <ReactPaginate
                previousLabel={''}
                nextLabel={''}
                pageCount={store.subAgentTotal || 1}
                activeClassName='active'
                forcePage={currentPage !== 0 ? currentPage - 1 : 0}
                onPageChange={page => handlePagination(page)}
                pageClassName={'page-item'}
                nextLinkClassName={'page-link'}
                nextClassName={'page-item next'}
                previousClassName={'page-item prev'}
                previousLinkClassName={'page-link'}
                pageLinkClassName={'page-link'}
                containerClassName={'pagination react-paginate justify-content-end my-2 pe-1'}
            />
        )
    }

    // ** Table data to render
    const dataToRender = () => {
        const filters = {
            q: searchTerm
        }

        const isFiltered = Object.keys(filters).some(function (k) {
            return filters[k].length > 0
        })

        if (store.subAgentData.length > 0) {
            return store.subAgentData
        } else if (store.subAgentData.length === 0 && isFiltered) {
            return []
        } else {
            return store.allData.slice(0, rowsPerPage)
        }
    }

    const handleSort = (column, sortDirection) => {
        setSort(sortDirection)
        setSortColumn(column.sortField)
        // dispatch(
        //     getDataSubAgent(getDataParameter())
        // )
    }

    return (
        <Fragment>
            <Card hidden>
                <CardHeader>
                    <CardTitle tag='h4'>Filtreler</CardTitle>
                </CardHeader>
                <CardBody>
                    <Row>
                        <Col md='3'>
                            <Label for='role-select'>Grup Adı</Label>
                            <Input name='text' id='username' rows='3' placeholder=''
                                   className='react-select'
                                   onChange={data => {
                                       setSearchTerm(data.target.value)
                                   }}/>
                        </Col>
                        <Col className='my-md-0 my-1' md='3'>
                            <Label for='plan-select'>Firma Adı</Label>
                            <Input name='text' id='username' rows='3' placeholder=''
                                   className='react-select'
                                   onChange={data => {
                                       setSearchTerm(data.target.value)
                                   }}/>
                        </Col>
                        <Col md='3'>
                            <Label for='status-select'>Sicil Numarası</Label>
                            <Input name='text' id='username' rows='3' placeholder=''
                                   className='react-select'
                                   onChange={data => {
                                       setSearchTerm(data.target.value)
                                   }}/>
                        </Col>
                        <Col md='3'>
                            <Label for='status-select'>Vergi Numarası</Label>
                            <Input name='text' id='username' rows='3' placeholder=''
                                   className='react-select'
                                   onChange={data => {
                                       setSearchTerm(data.target.value)
                                   }}/>
                        </Col>
                    </Row>
                </CardBody>
            </Card>
            <Card className='overflow-hidden'>
                <div className='react-dataTable'>
                    <DataTable
                        noHeader
                        subHeader
                        sortServer
                        pagination
                        responsive
                        paginationServer
                        columns={subAgentColumns}
                        onSort={handleSort}
                        sortIcon={<ChevronDown/>}
                        className={`react-dataTable ${isColumnsFixed ? 'work-accident-fixed-columns' : ''}`}
                        paginationComponent={CustomPagination}
                        data={dataToRender()}
                        subHeaderComponent={
                            <CustomHeader
                                store={store}
                                searchTerm={searchTerm}
                                rowsPerPage={rowsPerPage}
                                handleFilter={handleFilter}
                                handlePerPage={handlePerPage}
                                toggleSidebar={toggleSidebar}
                                dispatch={dispatch}
                                hasFilters={hasFilters}
                                getDataParameter={getDataParameter}
                                isColumnsFixed={isColumnsFixed}
                                toggleColumnsFixed={toggleColumnsFixed}
                                columns={subAgentColumns}
                                handleColumnChange={handleColumnChange}
                                resetColumns={resetColumns}
                            />
                        }
                    />
                </div>
            </Card>
            <CreatePopup open={sidebarOpen} toggleSidebar={toggleSidebar}/>
            <HtmlPopup open={store.toggleHtmlPopup?.show} toggleSidebar={toggleHtmlPopup}/>
            <VisitHtmlImagePopup open={store.toggleVisitHtmlImagePopup?.show}
                                 toggleSidebar={toggleVisitHtmlImagePopup}/>
        </Fragment>
    )
}

export default UsersList
